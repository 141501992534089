import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OnBoardMain from "../../Components/onBoard/";
import AssesmentHeader from "./AssesmentHeader";
import { Button } from "@carbon/react";
import AssesmentCard from "./AssesmentCard";
import Qcard from "./Qcard";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../api/apiList";
import AssesmentForPc from "./AssesmentForPc";
import AssesmentForMobile from "./AssesmentForMobile";
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
export default function Assesment() {
  const size = useWindowSize();
  return (
    <>
{size.width>1000?<AssesmentForPc/>:<AssesmentForMobile/>}
    </>

  );
}
