import React from 'react'

export default function SafetyLargeIcon() {
  return (
<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1521_13)">
<path d="M40 10C47.7861 16.8886 57.9482 20.4752 68.3333 20C69.8453 25.1434 70.308 30.5382 69.6938 35.8639C69.0796 41.1897 67.4011 46.3376 64.758 51.0018C62.115 55.666 58.5613 59.7513 54.3082 63.0149C50.0551 66.2786 45.1893 68.654 40 70C34.8107 68.654 29.9449 66.2786 25.6918 63.0149C21.4387 59.7513 17.885 55.666 15.242 51.0018C12.5989 46.3376 10.9205 41.1897 10.3063 35.8639C9.69206 30.5382 10.1547 25.1434 11.6667 20C22.0518 20.4752 32.2139 16.8886 40 10Z" stroke="#2461FF" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.6667 36.6667C36.6667 37.5508 37.0178 38.3986 37.643 39.0237C38.2681 39.6489 39.1159 40 40 40C40.884 40 41.7319 39.6489 42.357 39.0237C42.9821 38.3986 43.3333 37.5508 43.3333 36.6667C43.3333 35.7827 42.9821 34.9348 42.357 34.3097C41.7319 33.6846 40.884 33.3334 40 33.3334C39.1159 33.3334 38.2681 33.6846 37.643 34.3097C37.0178 34.9348 36.6667 35.7827 36.6667 36.6667Z" stroke="#2461FF" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40 40V48.3333" stroke="#2461FF" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1521_13">
<rect width="80" height="80" fill="white"/>
</clipPath>
</defs>
</svg>


  )
}
