export const ReportingData = {
    "Reports and KPIs":{
        "20":"Your business's reporting practices are inadequate, and there is a lack of key performance indicators (KPIs) to track and evaluate your performance. Inadequate reporting can hinder your ability to monitor progress, identify areas for improvement, and make data-driven decisions. It is crucial to establish a robust reporting framework, define meaningful KPIs, and invest in the necessary tools or systems to capture and analyze relevant data. Consider seeking external expertise or consulting services to develop a comprehensive reporting and KPI framework that aligns with your business objectives.",
        "40":"Your business's reporting practices are inadequate, and there is a lack of key performance indicators (KPIs) to track and evaluate your performance. Inadequate reporting can hinder your ability to monitor progress, identify areas for improvement, and make data-driven decisions. It is crucial to establish a robust reporting framework, define meaningful KPIs, and invest in the necessary tools or systems to capture and analyze relevant data. Consider seeking external expertise or consulting services to develop a comprehensive reporting and KPI framework that aligns with your business objectives.",
        "60":"Your business has adequate reporting practices and relevant KPIs, but there are noticeable gaps that require attention. While you have established reporting mechanisms and identified key performance indicators, there may be room for improvement in terms of data accuracy, timeliness, and actionable insights. Invest in improving data quality, implementing more advanced reporting tools or software, and refining your KPIs to ensure they align with your strategic goals. Regularly review and analyze your reports to identify areas for improvement and make informed decisions.",
        "80":"Well done! Your business has strong reporting practices and meaningful KPIs, with a few areas that could benefit from further attention. Your reporting systems provide relevant information to stakeholders and assist in assessing your business's performance. To further enhance your reporting practices, consider streamlining data collection and analysis processes, improving data visualization techniques, and refining your KPIs to align with specific business objectives. Regularly review and communicate the insights gained from your reports to drive continuous improvement.",
        "100":"Congratulations! Your business has robust reporting practices and effectively utilizes key performance indicators (KPIs). You have implemented comprehensive reporting systems that capture relevant data and provide valuable insights into your business's performance. Your KPIs are well-defined, aligned with strategic objectives, and regularly monitored. Your reports enable informed decision-making, performance tracking, and proactive adjustments to drive business growth and success.",
    },
    "Meetings":{
        "20":"Your business conducts disorganized and ineffective meetings, which can hinder productivity and collaboration. Meetings may lack structure, purpose, and fail to generate meaningful outcomes. It is crucial to address the fundamental issues in meeting management. Establish clear meeting protocols, define meeting objectives and expectations, and provide training on effective meeting facilitation and participation. Foster a culture that values effective communication and continuous improvement in meeting practices.",
        "40":"Your business conducts inefficient meetings, indicating a need for significant improvements in meeting management. Meetings may lack clear agendas, focus, and fail to achieve desired outcomes. It is important to reevaluate your meeting practices, streamline agendas, set clear objectives, and establish ground rules for participation and time management. Consider implementing meeting facilitation techniques, providing necessary training to participants, and promoting a culture of accountability for action items and follow-ups.",
        "60":"Your business conducts meetings that are adequate, but there are noticeable areas that require attention to improve their effectiveness. While you have established some meeting practices, there may be room for improvement in terms of preparation, structure, and follow-up actions. Enhance your meetings by setting clear objectives, providing necessary information in advance, ensuring active participation, and implementing effective decision-making processes. Regularly evaluate and refine your meeting practices to drive better engagement and outcomes.",
        "80":"Well done! Your business conducts productive and engaging meetings, with a few areas that could benefit from further attention. Your meetings generally have clear agendas, active participation, and promote constructive discussions. To further enhance your meetings, consider providing pre-meeting materials, setting clear expectations for participation, and improving time management. Foster a collaborative environment where diverse perspectives are valued, and decisions are made collectively to drive better outcomes.",
        "100":"Congratulations! Your business conducts highly effective meetings. Your meetings are well-structured, purposeful, and yield productive outcomes. You demonstrate excellent meeting management skills, ensuring clear objectives, active participation, and efficient use of time. Meetings serve as valuable platforms for collaboration, decision-making, and effective communication within your organization. Keep up the good work and continue leveraging meetings as a catalyst for progress and success.",
    },
    "Action Items":{
        "20":"Your business has poor action item management practices, indicating significant deficiencies in tracking and completing assigned tasks. Lack of accountability and ineffective processes may hinder progress and result in missed opportunities. It is crucial to address the root causes of poor action item management. Implement a comprehensive task management system, improve communication and collaboration among team members, and establish a culture of accountability and timely action. Provide training and support to improve action item management practices across the organization.",
        "40":"Your business has limited action item management practices, indicating challenges in tracking and following up on assigned tasks. Action items may not be adequately captured, assigned, or monitored, resulting in delays or incomplete actions. It is important to establish a systematic approach to manage action items. Implement tools or software to track tasks, assign responsibilities, and establish clear deadlines. Provide training and support to team members to ensure they understand the importance of action item management and adhere to established processes.",
        "60":"Your business demonstrates adequate action item management practices, but there are noticeable gaps that require attention. While you have established some processes for capturing and tracking action items, there may be room for improvement in terms of clarity, follow-up, and accountability. Enhance your action item management by implementing a standardized system for assigning, tracking, and reporting on tasks. Clearly communicate expectations, deadlines, and responsibilities to ensure timely and effective completion of action items.",
        "80":"Well done! Your business exhibits strong action item management practices, with a few areas that could benefit from further attention. You have established processes for documenting and assigning action items, and there is a moderate level of accountability in completing tasks. To further enhance your action item management, consider streamlining your tracking systems, establishing clear deadlines, and promoting effective communication and collaboration among team members. Regularly review and update action item lists to ensure timely completion.",
        "100":"Congratulations! Your business demonstrates excellent action item management. You have established effective processes for capturing, tracking, and following up on action items resulting from meetings or feedback. Your team takes ownership of assigned tasks, and there is a high level of accountability and timeliness in completing action items. Your proactive approach ensures that actions are executed efficiently, contributing to the overall progress and success of your business.",
    },
   
}


