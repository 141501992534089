export const Reportdata = {
    "Overview":{
        "20":"Your overall business management is in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in various areas of your business, significantly impacting your management effectiveness. Focus on seeking professional guidance, conducting a thorough assessment of your business practices, and developing a comprehensive improvement plan. By implementing strategic changes and addressing these deficiencies, you can work towards revitalizing your overall business management.",
        "40":"Your overall business management needs significant improvement. The assessment indicates challenges in multiple areas of your business, including mission and purpose, customer understanding, financial control and planning, operations and technology, reporting and feedback, and people management. It is crucial to address these issues promptly by conducting a comprehensive review of your business practices, seeking expert guidance, and implementing strategic changes to enhance performance. By addressing these challenges, you can work towards improving your overall business management.",
        "60":"Your overall business management demonstrates fair performance, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in certain areas of your business, impacting your overall management effectiveness. Focus on identifying the specific areas where improvement is needed, developing action plans to address those areas, and implementing changes to enhance performance. By addressing these gaps, you can strengthen your overall business management and achieve better results.",
        "80":"Well done! Your overall business management is generally good, with a few areas that could benefit from further attention. While you have a decent understanding and management of key business aspects, there may be occasional gaps or opportunities for improvement. Consider focusing on the specific areas where scores may be lower and implementing targeted strategies to enhance performance. By addressing these areas, you can further strengthen your overall business management and optimize your business's potential.",
        "100":"Congratulations! Your overall business management is excellent. You demonstrate a high level of competence in various aspects of your business, including mission and purpose, customer understanding, financial control and planning, operations and technology, reporting and feedback, and people management. Your exceptional performance across these areas reflects a well-rounded and strategically managed business. Your commitment to continuous improvement and excellence positions you for sustained success in a competitive marketplace.",
    },
    "PURPOSE":{
        "20":"Your mission and purpose are in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in defining and communicating your mission and purpose. Focus on seeking professional guidance, conducting strategic workshops, engaging stakeholders to define your purpose, and developing a clear and inspiring mission statement. By addressing these issues, you can significantly improve the clarity and impact of your purpose within your organization.",
        "40":"Your mission and purpose need significant improvement. The assessment indicates challenges in clearly defining and communicating your mission and purpose. It is crucial to address these issues promptly by revisiting your organizational values, engaging in purpose-driven conversations, and developing a compelling purpose statement. Clarifying and communicating your purpose will help create a stronger sense of direction and alignment within your organization.",
        "60":"Your mission and purpose demonstrate adequacy, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in effectively communicating and living your purpose throughout your organization. Focus on clearly defining your mission, revisiting your purpose statement, and fostering a purpose-driven culture that aligns with your values. Strengthening these areas will enhance the impact of your purpose on your stakeholders.",
        "80":"Well done! Your mission and purpose are generally well-defined, with a few areas that could benefit from further attention. While you have a decent understanding of your purpose, there may be occasional gaps or opportunities for improvement. Consider refining your mission statement, articulating your purpose in a more compelling manner, and ensuring alignment across all aspects of your business. Strengthening these areas will enhance the clarity and impact of your purpose.",
        "100":"Congratulations! Your mission and purpose are clear and inspiring. You have a well-defined purpose that serves as a guiding principle for your business. Your purpose resonates with your stakeholders, including employees, customers, and partners, and inspires them to engage with your brand. Your commitment to your purpose creates a strong sense of direction, unity, and meaning within your organization.",
    },
    "PEOPLE":{
        "20":"Your people management practices are in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in attracting, developing, and retaining talent. Focus on seeking professional guidance, conducting a comprehensive review of your HR practices, implementing robust talent management strategies, and fostering a supportive and inclusive work culture. By addressing these issues, you can significantly improve your people management practices and create a more thriving and engaged workforce.",
        "40":"Your people management practices need significant improvement. The assessment indicates challenges in attracting, developing, and retaining talent effectively. It is crucial to address these issues promptly by refining your recruitment processes, investing in training and development programs, and implementing employee retention strategies. Enhancing these areas will help create a more engaged and motivated workforce.",
        "60":"Your people management practices demonstrate fair performance, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in certain aspects of managing and developing employees. Focus on improving communication channels, enhancing employee development programs, and implementing strategies to foster a positive work environment. Addressing these areas will strengthen your overall people management practices.",
        "80":"Well done! Your people management practices are generally good, with a few areas that could benefit from further attention. While you have a decent understanding of attracting and developing talent, there may be occasional gaps or opportunities for improvement. Consider enhancing your employee feedback mechanisms, providing more growth opportunities, and strengthening employee recognition and rewards to further improve your people management practices.",
        "100":"Congratulations! Your people management practices are excellent. You demonstrate a high level of competence in attracting, developing, and retaining top talent. Your focus on employee engagement, professional growth, and a positive work culture is evident. Your commitment to empowering and supporting your employees contributes to their productivity, satisfaction, and overall success of the organization.",
    },
    "CUSTOMER":{
        "20":"Your understanding of customers and customer acquisition is in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in identifying customer needs, understanding target markets, and executing successful customer acquisition strategies. Focus on seeking professional guidance, conducting comprehensive market analysis, refining your customer personas, and developing effective customer acquisition plans. By addressing these issues, you can significantly improve your customer understanding and acquisition practices.",
        "40":"Your understanding of customers and customer acquisition needs significant improvement. The assessment indicates challenges in effectively identifying customer needs, understanding target markets, and executing successful customer acquisition strategies. It is crucial to address these issues promptly by investing in market research, enhancing customer profiling, and implementing targeted marketing campaigns. Improving these areas will help you better understand and acquire your ideal customers.",
        "60":"Your understanding of customers and customer acquisition demonstrates fair performance, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in identifying customer needs and executing targeted acquisition strategies. Focus on conducting in-depth market research, analyzing customer data, and developing comprehensive customer profiles to improve your overall customer understanding and acquisition efforts.",
        "80":"Well done! Your understanding of customers and customer acquisition is generally good, with a few areas that could benefit from further attention. While you have a decent understanding of customer needs and have implemented effective acquisition strategies, there may be occasional gaps or opportunities for improvement. Consider refining your customer segmentation, personalizing your marketing efforts, and enhancing your customer acquisition channels to further improve your customer understanding and acquisition practices.",
        "100":"Congratulations! Your understanding of customers and customer acquisition is excellent. You demonstrate a high level of competence in identifying customer needs, developing targeted marketing strategies, and delivering exceptional customer experiences. Your customer-centric approach, combined with effective acquisition techniques, sets you apart from competitors and contributes to your business's success.",
    },
    "MARKETING":{
        "20":"Your marketing strategies and execution are in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in understanding your target audience, developing effective marketing campaigns, and achieving measurable results. Focus on seeking professional guidance, conducting in-depth market analysis, revamping your marketing strategies, and implementing comprehensive marketing plans. By addressing these issues, you can significantly improve your marketing effectiveness and drive better business outcomes.",
        "40":"Your marketing strategies and execution need significant improvement. The assessment indicates challenges in understanding your target audience, developing effective marketing campaigns, and achieving desired results. It is crucial to address these issues promptly by investing in market research, improving your messaging and creative content, and adopting data-driven marketing techniques. Enhancing these areas will help you achieve better marketing outcomes.",
        "60":"Your marketing strategies and execution demonstrate fair performance, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in understanding your target audience and executing impactful marketing campaigns. Focus on conducting thorough market research, refining your value proposition, and implementing data-driven marketing strategies to improve your overall marketing effectiveness.",
        "80":"Well done! Your marketing strategies and execution are generally good, with a few areas that could benefit from further attention. While you have a decent understanding of your target audience and have implemented effective marketing campaigns, there may be occasional gaps or opportunities for improvement. Consider refining your targeting, messaging, and measurement techniques to further enhance the effectiveness of your marketing efforts",
        "100":"Congratulations! Your marketing strategies and execution are excellent. You demonstrate a high level of competence in understanding your target audience, developing impactful marketing campaigns, and achieving measurable results. Your innovative approaches, strong brand positioning, and effective communication channels set you apart from competitors and contribute to your business's growth and success.",
    },
    "CASH FLOW":{
        "20":"Your cashflow management is in a critical state, requiring immediate attention. The assessment highlights serious deficiencies in understanding and managing your cash inflows and outflows. Focus on seeking professional guidance, implementing robust cashflow management systems, and exploring strategies to stabilize and improve your cashflow position.",
        "40":"Your cashflow management needs significant improvement. The assessment indicates substantial challenges in effectively managing your cash inflows and outflows. It is crucial to address these issues promptly by implementing cashflow forecasting, improving cashflow monitoring, and exploring strategies to optimize cashflow generation and management.",
        "60":"Your cashflow management demonstrates fair performance, but there are noticeable gaps that require attention. It appears that there are inconsistencies or uncertainties in managing your cash inflows and outflows. Devote effort to closely monitoring and analyzing your cashflow, identifying areas of improvement, and implementing strategies to enhance stability and predictability.",
        "80":"Well done! Your cashflow management is generally good, with some areas that could benefit from further attention. While you have a decent understanding of cash inflows and outflows, there may be occasional uncertainties or room for improvement in forecasting and optimizing cashflow. Focusing on these areas will help enhance your overall cashflow management.",
        "100":"Congratulations! Your cashflow management is excellent. You have a strong grasp of your cash inflows and outflows, ensuring a healthy and consistent cashflow. Your ability to effectively manage and forecast cashflow contributes to financial stability and the ability to seize opportunities for growth.",
    },
    "REPORTING & FEEDBACK":{
        "20":"Your reporting and feedback practices are in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in meeting management, KPI tracking and utilization, feedback processes, task management, reporting, and action item implementation. Focus on seeking professional guidance, conducting a comprehensive review of your reporting and feedback processes, establishing effective meeting structures, developing robust KPI measurement and analysis systems, implementing structured feedback frameworks, optimizing task management solutions, improving report generation methods, and establishing accountability mechanisms for action item execution. By addressing these issues, you can significantly improve your reporting and feedback practices.",
        "40":"Your reporting and feedback practices need significant improvement. The assessment indicates challenges in meeting management, tracking and utilizing KPIs effectively, providing regular and constructive feedback, managing tasks efficiently, generating meaningful reports, and implementing action items. It is crucial to address these issues promptly by establishing clear meeting protocols, refining KPI measurement and analysis methods, enhancing feedback channels, implementing task management systems, improving report generation processes, and establishing a structured approach to action item tracking and execution. Improving these areas will enhance your organization's reporting and feedback capabilities.",
        "60":"Your reporting and feedback practices demonstrate fair performance, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in meeting management, tracking and analyzing KPIs, providing effective feedback, managing tasks, generating reports, and implementing action items. Focus on improving meeting organization and participation, refining KPI measurement frameworks, enhancing feedback delivery mechanisms, implementing task tracking tools, streamlining reporting processes, and ensuring prompt and effective action item execution. Addressing these areas will strengthen your overall reporting and feedback practices.",
        "80":"Well done! Your reporting and feedback practices are generally good, with a few areas that could benefit from further attention. While you have a decent understanding of meeting management, KPI tracking, feedback processes, task management, reporting, and action items, there may be occasional gaps or opportunities for improvement. Consider streamlining meeting procedures, enhancing KPI measurement and analysis, implementing structured feedback channels, optimizing task management systems, refining report formats, and ensuring effective follow-up on action items to further enhance your reporting and feedback practices.",
        "100":"Congratulations! Your reporting and feedback practices are excellent. You demonstrate a high level of competence in effectively managing meetings, tracking key performance indicators (KPIs), providing timely and constructive feedback, managing tasks efficiently, generating comprehensive reports, and implementing action items. Your commitment to clear communication, accountability, and continuous improvement sets you apart in managing the reporting and feedback aspects of your business.",
    },
    "FINANCIAL CONTROL & PLANNING":{
        "20":"Your financial control and planning practices are in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in your financial control mechanisms and planning processes. Focus on seeking professional guidance, implementing robust financial controls, enhancing budgeting and forecasting practices, and exploring strategies to improve your financial management capabilities.",
        "40":"Your financial control and planning practices need significant improvement. The assessment indicates significant challenges in effectively managing and planning your finances. It is crucial to address these issues promptly by implementing stronger financial controls, enhancing budgeting and forecasting processes, and seeking professional assistance, if necessary.",
        "60":"Your financial control and planning practices demonstrate fair performance, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in your financial control mechanisms and planning processes. Focus on strengthening your financial reporting, internal controls, and budgeting processes to enhance your overall financial management capabilities.",
        "80":"Well done! Your financial control and planning practices are generally good, with a few areas that could benefit from further attention. While you have a decent understanding of financial control and planning, there may be occasional gaps or opportunities for improvement. Consider refining your financial reporting, budgeting, and forecasting processes to enhance your overall financial management practices.",
        "100":"Congratulations! Your financial control and planning practices are excellent. You demonstrate a high level of competence in managing and planning your finances, ensuring optimal control over your financial resources. Your ability to effectively analyze, strategize, and monitor your financial activities enables sound decision-making and contributes to the long-term success of your business.",
    },
    "OPERATION & TECHNOLOGY":{
        "20":"Your operations and technology management practices are in a critical state, requiring immediate attention. The assessment highlights severe deficiencies in leveraging technology and managing operations effectively. Focus on seeking professional guidance, conducting a thorough assessment of your operational processes and technology infrastructure, and implementing strategic improvements to enhance your overall operations and technology management capabilities.",
        "40":"Your operations and technology management practices need significant improvement. The assessment indicates challenges in effectively leveraging technology and optimizing operations. It is crucial to address these issues promptly by investing in suitable technology solutions, improving processes, and providing training or resources to support your team in adopting new technologies.",
        "60":"Your operations and technology management practices demonstrate fair performance, but there are noticeable gaps that require attention. It appears that there may be inconsistencies or uncertainties in leveraging technology effectively to drive operational efficiencies. Focus on identifying areas for technology implementation and process optimization to enhance productivity, streamline operations, and achieve better overall results.",
        "80":"Well done! Your operations and technology management practices are generally good, with some areas that could benefit from further attention. While you have a decent understanding of leveraging technology in your operations, there may be occasional gaps or opportunities for improvement. Consider exploring advanced technological solutions, streamlining processes further, and optimizing resource utilization to enhance your overall operations.",
        "100":"Congratulations! Your operations and technology management practices are excellent. You exhibit a high level of competence in leveraging technology to optimize your business operations. Your efficient use of technology, streamlined processes, and effective resource allocation contribute to improved productivity, cost-effectiveness, and overall operational excellence.",
    },
    "Clarity":{
        "20":"It appears that your business lacks significant clarity across multiple aspects. There is a critical need for improvement in understanding and knowledge of your business. Immediate attention and comprehensive efforts are required to gain clarity and align your business objectives effectively.",
        "40":"Your business struggles with clarity in several areas. It's crucial to invest time and effort in gaining a better understanding of your business's fundamental elements. By addressing these knowledge gaps, you can enhance your business operations and make more informed strategic decisions.",
        "60":"Your business exhibits fair clarity, but there are notable gaps in certain areas. Identify these areas of uncertainty and allocate resources to gain better clarity and comprehension, as this will lead to more informed decision-making.",
        "80":"Well done! Your business demonstrates good clarity in most areas, although there are a few aspects where some uncertainty persists. It's important to focus on those specific areas to enhance your understanding and ensure consistency in your business operations. Addressing these areas will further strengthen your overall business clarity.",
        "100":"Congratulations! Your business exhibits exceptional clarity across various aspects. Your understanding and knowledge of your business are evident. You have a firm grasp on your goals, strategies, and operations, which contributes to effective decision-making and overall success.",
    },
    "STRATEGY, PLANNING AND SUCCESSION":{
        "20":"It appears that your business lacks significant clarity across multiple aspects. There is a critical need for improvement in understanding and knowledge of your business. Immediate attention and comprehensive efforts are required to gain clarity and align your business objectives effectively.",
        "40":"Your business struggles with clarity in several areas. It's crucial to invest time and effort in gaining a better understanding of your business's fundamental elements. By addressing these knowledge gaps, you can enhance your business operations and make more informed strategic decisions.",
        "60":"Your business exhibits fair clarity, but there are notable gaps in certain areas. Identify these areas of uncertainty and allocate resources to gain better clarity and comprehension, as this will lead to more informed decision-making.",
        "80":"Well done! Your business demonstrates good clarity in most areas, although there are a few aspects where some uncertainty persists. It's important to focus on those specific areas to enhance your understanding and ensure consistency in your business operations. Addressing these areas will further strengthen your overall business clarity.",
        "100":"Congratulations! Your business exhibits exceptional clarity across various aspects. Your understanding and knowledge of your business are evident. You have a firm grasp on your goals, strategies, and operations, which contributes to effective decision-making and overall success.",
    },
}


