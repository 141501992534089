import React, { useContext } from "react";
import { Link } from "@carbon/react";
import { UserContext } from "../../../userContext";
import apiL from "../../../api/apiList";
export default function SinglePLan({ Planname, description, planID ,invoice,price,currency}) {
  const { user, setUser } = useContext(UserContext);
  async function planUpdate(n,amount) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({    "plan_id": n,
    "transaction_id": 123456,
    "amount": 5000,
    "status": "validated"});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    // fetch("https://api.panoramamas.com/payment/init", {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     credentials: "include",
    //   },
    //   body: JSON.stringify({
    //     "amount":amount,
    //     "id":n
    // })
    // }).then(async(res)=>{
    //   let curl =await res.json()
    //   window.open(curl.url)
    // })
    fetch(apiL.makeInvoice, requestOptions)
    .then(async (response) => {
      let dsa = await response.json();
      if (dsa.result.invoices) {
        setUser({ ...user, pid: n });
        localStorage.setItem("pid",n)
        window.open("https://agsmcs.info/my/invoices/" + dsa.result.invoices);
        invoice()
      }
    })
    .catch((error) => console.log("error", error));
  }
  let pid = localStorage.getItem("pid")
console.log(pid)
  return (
    <div className="single-plan">
      <h3 className="plan-heading">{Planname}</h3>
      <h3 className="plan-heading">{price==0?<span style={{color:'#0065FF'}}>Free</span>:<span style={{color:'#0065FF'}}><span style={{fontSize:'36px'}}>{price}</span> {currency}</span>}</h3>
      <p>{description}</p>
      <br />
      {/* <p>Keep chugging your coffee.</p> */}
      <div className="single-plan-price">
        {pid == planID ? <h3>Your Plan</h3> : ""}
      </div>

      {pid == planID ? (
        ""
      ) : parseInt(pid)<parseInt(planID) || pid==="null"?(
        planID=="1"  ?        <Link className="single-plan-btn" onClick={()=>planUpdate(planID,price)}>
          Get Started
        </Link>:    planID=="2"  ?        <Link className="single-plan-btn" onClick={()=>planUpdate(planID,price)}>
          Get Started
        </Link> :    <Link className="single-plan-btn" style={{cursor:'not-allowed'}}>
         Coming Soon
        </Link>
      ):""}

              
    </div>
  );
}
