import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import OnBoardHead from "./header";
import UserProfile from "./userProfile";
import BusinessProfile from "./businessProfile";
import QuestionOnboard from "./Questionaire";
import OnBoardComplete from "./complete";
import { useSearchParams } from "react-router-dom";
export default function OnBoardMain() {
  const [ProfileData, setProfileData] = useState({});
  const [profileID, setprofileID] = useState("");
  let [searchParams] = useSearchParams();
  const [count, setcount] = useState(
    parseInt(searchParams.get("phase")));
  console.log(count);
  return (

        <div className="onboards">
          {count !== 3 ? <OnBoardHead count={count} /> : ""}
          {count === 0 ? (
            <UserProfile
              setcount={setcount}
              setProfile={setProfileData}
              setprofileID={setprofileID}
              profileData={ProfileData}
            />
          ) : (
            <div></div>
          )}
          {count === 1 ? (
            <BusinessProfile
              setcount={setcount}
              setProfile={setProfileData}
              setprofileID={setprofileID}
              profileData={ProfileData}
            />
          ) : (
            <div></div>
          )}
          {count === 2 ? (
            <QuestionOnboard
              setcount={setcount}
              setProfile={setProfileData}
              setprofileID={setprofileID}
              profileData={ProfileData}
            />
          ) : (
            <div></div>
          )}
          {/* {count === 3 ? <OnBoardComplete setcount={setcount} /> : <div></div>} */}
        </div>
  );
}
