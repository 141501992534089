import React, { useState } from 'react'
import DashboardIndex from '../../Components/Portal/dashboard'
import PortalHeader from '../../Components/Portal/header'
import Layout from '../../Components/Portal/layout'
import UserInfo from '../../Components/Portal/UserInfo'
import apiL from '../../api/apiList'
import { useNavigate } from "react-router-dom";
export default function PortalIndex() {
  const [count, setcount] = useState(0)
  const navigate = useNavigate();
  let onb =localStorage.getItem("onboard")
  console.log(typeof onb)
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        localStorage.clear();
      })
      .catch((error) => console.log("error", error));
  };
  if(onb==="false"){
    Logout()
navigate("/signin");
}
  return (
    <><Layout/>
        <div className='ptl'>
        {count === 0? <DashboardIndex/> : ""}
    </div></>

  )
}    
