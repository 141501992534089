import React, { useEffect, useState } from 'react'
import PortalHeader from '../../Components/Portal/header'
import Layout from '../../Components/Portal/layout'
import UserInfo from '../../Components/Portal/UserInfo'
import apiL from '../../api/apiList'
export default function User_info({notify}) {
  const [data, setdata] = useState({})
  async function getProfile(){
    let id =localStorage.getItem("id")
     var myHeaders = new Headers();
     myHeaders.append("Content-Type", "application/json");
     var raw = JSON.stringify({});
     var requestOptions = {
       method: "POST",
       credentials: "include",
       headers: {
         "Content-Type": "application/json",
       },
       body: raw,
       redirect: "follow",
     };
     fetch(apiL.getProfile+id, requestOptions)
       .then(async (response) => {
         let dsa = await response.json();
         if(dsa.result.personal_info.user_code==id){
           setdata(dsa.result)
         }
       })
       .catch((error) => console.log("error", error));
   }
   useEffect(() => {
getProfile()
   }, [])
   console.log(data)
  return (
    <div className='ptl'>
        <Layout/>
        <PortalHeader/>
        <UserInfo data={data} notify={notify} />
    </div>
  )
}
