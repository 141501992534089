import Typography from "@mui/material/Typography";
import { Button, Modal } from "@carbon/react";
import Grid from "@mui/material/Grid";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../api/apiList";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import Layout from "../../Components/Portal/layout";
export default function Users() {
  let pid = localStorage.getItem("pid")
  const [rows, setrows] = useState([]);
  const [Plan, setPlan] = useState([]);
  const headers = ["Email", "Name", "Action"];
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [emailInput, setemailInput] = useState("");
  const [nameInput, setnameInput] = useState("");
  const [user_remain, setuser_remain] = useState(0);
  const [modalAddUser, setmodalAddUser] = useState(false);
  const [errors, seterrors] = useState(null);
  async function getPlan() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    
    fetch(apiL.getPlan + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.plan) {
          setPlan(dsa.result.plan);
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function addUser( name,email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let password = Math.random().toString(36).slice(2, 10);
    var raw = JSON.stringify({ email: email, password: password, name: name });
    var raws = JSON.stringify({
      subject: "Invitation To Join Panorama MAS",
      body: `<div>Dear ${name} ,<br/>
      Here is your Login Info to join Panorama
      <br/>
      Email:${email},
      <br/>
      Password: ${password} 
      </div>`,
      recipient_emails: email,
    });
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    var requestOptionss = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raws,
      redirect: "follow",
    };
    fetch(apiL.addUser + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.user_id) {
          console.log("Hello");
          // fetch(apiL.sendMail, requestOptionss)
          // .then(async (response) => {
          //   let dsa = await response.json();
          //  if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          //     navigate("/signin");
          //   }
          // })
          // .catch((error) => console.log("error", error));
          getUserList();
          setmodalAddUser(false);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        } else {
          seterrors(dsa.result)
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getUserList() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    let id = localStorage.getItem("id")
    fetch(apiL.userList + user.uid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result==="User not found"
        ) {
          setrows([])
      
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
        else{
          setrows(dsa?.result||[]);
          console.log(dsa.result)
        }
        // setrows(dsa?.result||[]);
      })
      .catch((error) => console.log("error", error));
    fetch(apiL.remainUser + user.uid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == "This user has no consultant."
        ) {
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
        setuser_remain(dsa.result.user_remain);
      })
      .catch((error) => console.log("error", error));
  }
  console.log(rows)
  useEffect(() => {
    getPlan()
    getUserList();
  }, []);
  return (
    <>
          <Layout/>
          <div className="innerPortal bg-grey-plan">
      <Modal
        open={modalAddUser}
        className='userAddModal'
        modalHeading="Add User"
        primaryButtonText="Send"
        secondaryButtonText="Cancel"
        onRequestClose={() => setmodalAddUser(false)}
        onRequestSubmit={() => addUser(nameInput, emailInput)}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Lorem ipsum dolor sit lorem a amet, consectetur adipiscing elit, sed
          do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam.
        </p>
        <TextInput
          data-modal-primary-focus
          id="text-input-1"
          labelText="Name"
          placeholder="John Doe"
          style={{ marginBottom: "1rem" }}
          value={nameInput}
          onChange={(e) => setnameInput(e.target.value)}
        />
        <TextInput
          data-modal-primary-focus
          id="text-input-1"
          labelText="Email"
          placeholder="yourname@domain.com"
          style={{ marginBottom: "1rem" }}
          value={emailInput}
          onChange={(e) => setemailInput(e.target.value)}
        />
        {errors == null ? (
          <div></div>
        ) : (
          <p style={{ marginBottom: "1rem", color: "red" }}>{errors}</p>
        )}
      </Modal>
      <div className="cardFullDashboard">
        <Grid container spacing={0}>
          <Grid sm={12} md={12} lg={6} xl={6}>
            <div className="txtCardFullDash">
              <div>
                <Typography variant="h4B" color="neutral.b800">
                  Users
                </Typography>
              </div>
              <div>
                <Typography variant="small" color="neutral.b800">
                  Add users on your Company Assessment and get a comprehensive
                  report of your Company.
                </Typography>
              </div>
              <Button
                kind="primary"
                size="lg"
                onClick={() => setmodalAddUser(true)}
                disabled={pid==="null"?true:false}
              >
                Add User
              </Button>
            </div>
          </Grid>
          <Grid sm={12} md={12} lg={6} xl={6}>
            <div className="plan-txt2">
              <div>
                <Typography variant="small" color="neutral.b80">
                  Your Package
                </Typography>
                <br />
                <Typography variant="largeB" color="primary.b300">
                {pid == "null"
                    ? "No Active plan"
                    : Plan.map((e) => {
                        return e.id == user.pid ? e.name : "";
                      })}
                </Typography>
              </div>
              <div className="plancta">
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    User Remaining
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    {user_remain}
                  </Typography>
                </div>
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Users Added
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    {rows.length}
                  </Typography>
                </div>
                {/* <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Total Users
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingBottom: "80px" }}>
      <Table size="lg" useZebraStyles={false}>
          <TableHead>
            <TableRow>
              {headers?.map((header) => (
                <TableHeader id={header.key} key={header}>
                  {header}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length>0 ? rows?.map((row) => (
              <TableRow key={row.id}>
                <TableCell key={row.login}>{row.login}</TableCell>
                <TableCell key={row.name}>{row.name}</TableCell>
                <TableCell>
                  <div style={{ display: "flex" }}>
                    {/* <div className="tablebtnspace">
                    <Button
                      kind="secondary"
                      size="sm"
                      className="btnss"
                      // onClick={() => handleNext()}
                    >
                      View Details
                    </Button>
                  </div> */}
                    <div className="tablebtnspace">
                      <Button
                        kind="primary"
                        size="sm"
                        className="btnss"
                        // onClick={() => handleNext()}
                      >
                        Deactivate
                      </Button>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )) :""}
          </TableBody>
        </Table>
      </div>
    </div>
    </>

  );
}
