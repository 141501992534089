import "./App.scss";
import { createContext, useEffect, useState, useMemo } from "react";
import SignUp from "./Pages/Auth/SignUp";
import { Routes, Route } from "react-router-dom";
import OnBoardMain from "./Pages/OnBoard/index";
import PortalIndex from "./Pages/Dashboard";
import User_info from "./Pages/Dashboard/userInfo";
import Layout from "./Components/Portal/layout";
import PortalHeader from "./Components/Portal/header";
import Plans from "./Pages/Dashboard/plans";
import Consultation from "./Pages/Dashboard/consultation";
import Users from "./Pages/Dashboard/users";
import SignIn from "./Pages/Auth/SignIn";
import Assesment from "./Pages/Assesment/Assesment";
import { UserContext } from "./userContext";
import Report from "./Components/Portal/dashboard/Report";
import AssesmentForMobile from "./Pages/Assesment/AssesmentForMobile";
import Cashflow from "./Components/Portal/dashboard/ReportsAll/cashflow";
import Customer from "./Components/Portal/dashboard/ReportsAll/customer";
import Financials from "./Components/Portal/dashboard/ReportsAll/financials";
import Marketing from "./Components/Portal/dashboard/ReportsAll/marketing";
import Operation from "./Components/Portal/dashboard/ReportsAll/operation";
import People from "./Components/Portal/dashboard/ReportsAll/poeple";
import Purpose from "./Components/Portal/dashboard/ReportsAll/purpose";
import Reports from "./Components/Portal/dashboard/ReportsAll/reports";
import Strategy from "./Components/Portal/dashboard/ReportsAll/strategy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tst from "./Pages/test";
import CompareMain from "./Components/Portal/dashboard/Compare/Report";
import CompareMainTest from "./Pages/ctest";
import CompareMaintestR from "./Pages/rtest";
import ReportsTab from "./Components/Portal/UserInfo/ReportsTab";
import Reset_Password from "./Pages/Auth/Reset_Password";
function App() {
	const [user, setUser] = useState({
		id: localStorage.getItem("id"),
		uid: localStorage.getItem("uid"),
		pid: localStorage.getItem("pid"),
	});
	const value = useMemo(() => ({ user, setUser }), [user, setUser]);
	console.log(value, "Test");
	const notify = (e) => toast(e);
	return (
		<UserContext.Provider value={value}>
			<div className="App">
				{/* Authentication Routes */}

				<>
					<ToastContainer
						position="bottom-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="light"
					/>
					<Routes>
						<Route path="/reset_password" element={<Reset_Password />} />
						<Route path="/signup" element={<SignUp />} />
						<Route path="/signin" element={<SignIn />} />
						<Route path="/test" element={<Tst />} />
						<Route path="/ctest" element={<CompareMainTest />} />
						<Route path="/rtest" element={<CompareMaintestR />} />
						<Route path="/" element={<SignIn />} />
					</Routes>
				</>
				<>
					<Routes>
						<Route path="/assesment" element={<Assesment />} />
						<Route
							path="/assesmentformobile"
							element={<AssesmentForMobile />}
						/>
						<Route path="/onBoard" element={<OnBoardMain />} />
					</Routes>
				</>

				{/* Authentication Routes End Here */}
				{/* Dashboard Routes */}
				<>
					<Routes>
						<Route path="/portal/index" element={<PortalIndex />} />
						<Route
							path="/portal/userInfo"
							element={<User_info notify={notify} />}
						/>
						<Route path="/portal/plans" element={<Plans />} />

						<Route path="/portal/assessmentreports" element={<ReportsTab />} />
						<Route path="/portal/consultation" element={<Consultation />} />
						<Route path="/portal/users" element={<Users />} />
						<Route path="/portal/compare/" element={<CompareMain />} />
						<Route path="/portal/report/overview" element={<Report />} />
						<Route path="/portal/report/cashflow" element={<Cashflow />} />
						<Route path="/portal/report/customer" element={<Customer />} />
						<Route path="/portal/report/financials" element={<Financials />} />
						<Route path="/portal/report/marketing" element={<Marketing />} />
						<Route path="/portal/report/operation" element={<Operation />} />
						<Route path="/portal/report/people" element={<People />} />
						<Route path="/portal/report/purpose" element={<Purpose />} />
						<Route path="/portal/report/reports" element={<Reports />} />
						<Route path="/portal/report/strategy" element={<Strategy />} />
					</Routes>
				</>
				{/* Dashboard Routes End Here */}
				{/* Assesment Routes  */}

				{/* Assesment Routes End Here */}
			</div>
		</UserContext.Provider>
	);
}

export default App;
