import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import ConsultCardIcon from "../../reUse/ConsultCardIcon";
import SafetyLargeIcon from "../../reUse/SafetyLargeIcon";
import { Button } from "@carbon/react";
import { useNavigate } from "react-router-dom";
export default function Consult({ data, consultData }) {
	const navigate = useNavigate();
	const invited = localStorage.getItem("invited");
	return (
		<div className="cardQuarterDashboard">
			<div className="consultCard" style={{ opacity: "1" }}>
				<div className="headerPlan">
					<ConsultCardIcon />
					<Typography variant="small" color="neutral.b800">
						Consultation
					</Typography>
				</div>
				{data === 0 ? (
					<div className="contentCard">
						<SafetyLargeIcon />
						<div>
							<Typography variant="small" color="neutral.b200">
								Select a plan that suits your business needs.
							</Typography>
						</div>
					</div>
				) : (
					<div className="contentCard2">
						<p>
							Schedule consultation for your business based on the package you
							have purchased.
						</p>
						<div className="schedule">
							<div className="schedule-top">
								<div className="single-schedule">
									<p>Consultations Left</p>
									<h5>
										{consultData.remaining_number}/{consultData.total_number}
									</h5>
								</div>
								<div className="single-schedule">
									<p>Duration</p>
									<h5>45 Mins</h5>
								</div>
							</div>
							{/* <div className="upcoming-consultation">
                <img src="/chat.svg" />
                No Upcoming Consultations
                </div> */}
						</div>
					</div>
				)}
				{invited === "true" ? (
					""
				) : (
					<Button
						onClick={() => navigate("/portal/consultation")}
						className="btnss"
						style={{ maxWidth: "100%", width: "100%" }}
						kind="primary"
						size="lg"
						disabled={data === 0 ? true : false}
					>
						Manage
					</Button>
				)}
			</div>
		</div>
	);
}
