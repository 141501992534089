import Typography from "@mui/material/Typography";
import { Button } from "@carbon/react";
import Lottie from "react-lottie";
import animationData from "../../../lottieAnimate.json";
import PlanIcon from "../../reUse/PlanIcon";
import UserCardIcon from "../../reUse/UserCardIcon";
import ConsultCardIcon from "../../reUse/ConsultCardIcon";
import PlanIconLarge from "../../reUse/PlanIconLarge";
import SafetyLargeIcon from "../../reUse/SafetyLargeIcon";
import moment from 'moment'
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../../api/apiList";
import { UserContext } from "../../../userContext";
import { useNavigate } from "react-router-dom";
import AssesmentCard from "./AssesmentCard";
import PlanCard from "./PlanCard";
import UserCard from "./UserCard";
import Consult from "./Consult";
import HelpCard from "./HelpCard";
export default function DashboardIndex() {
  const { user, setUser } = useContext(UserContext);
  let pid = localStorage.getItem("pid")
  const [Plan, setPlan] = useState([]);
  const [assid, setassid] = useState("")
  const [planCardState, setPlanCardState] = useState(0);
  const [userCardState, setuserCardState] = useState(0);
  const [consultCardState, setconsultCardState] = useState(0);
  const [assesmentState, setassesmentState] = useState(0);
  const [data, setdata] = useState({})
  const [rows, setrows] = useState([]);
  const [planData, setplanData] = useState([])
  const [user_remain, setuser_remain] = useState(0);
  let vd= localStorage.getItem("vd")
  let onb =localStorage.getItem("onboard")
  console.log(vd)
  const navigate = useNavigate();
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        localStorage.clear();
      })
      .catch((error) => console.log("error", error));
  };
  async function getPlan() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getPlan + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.plan) {
          setPlan(dsa.result.plan);
          let pid = localStorage.getItem("pid")
         if(pid==="null"){
          setPlanCardState(0)
          setassesmentState(1)
         }else{
          setPlanCardState(dsa.result.plan[parseInt(pid)-1])
         }
          
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getUserList() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.userList + user.uid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == " User not found."
        ) {
          setrows(dsa.result);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
        setrows(dsa.result);
      })
      .catch((error) => console.log("error", error));
    fetch(apiL.remainUser + user.uid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == "This user has no consultant."
        ) {
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
        setuser_remain(dsa.result.user_remain);
        setuserCardState(dsa.result.user_remain)
      })
      .catch((error) => console.log("error", error));
  }

  async function getConsultant() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getConsultant + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == "This user has no consultant."
        ) {
          setdata({
            profile_code: "JD-1000-BD-0005",
            profile_id: 0,
            remaining_number: 0,
            total_number: 0,
          });
          setconsultCardState(0)
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }else{
          setconsultCardState(1)
        }
        
        setdata(dsa.result.consultants[0]);
      })
      .catch((error) => console.log("error", error));
  }
  async function getAssesment() {
    let asslength=0
    let assLstate=""
    let assLdate=""
    async function getAssesmentList(){
      let id =localStorage.getItem("id")
       var myHeaders = new Headers();
       myHeaders.append("Content-Type", "application/json");
       var raw = JSON.stringify({});
       var requestOptions = {
         method: "POST",
         credentials: "include",
         headers: {
           "Content-Type": "application/json",
         },
         body: raw,
         redirect: "follow",
       };
       fetch(apiL.asseslist+id, requestOptions)
         .then(async (response) => {
           let dsa = await response.json();
           if(dsa.result){
           let data= dsa?.result?.assessments.length
           let x=dsa?.result?.assessments.reverse()
           asslength=data
           assLstate=x[0].state
           assLdate=x[0].create_date
           setassid(x[0].id)
           }
           else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
            navigate("/signin");
          }
         })
         .catch((error) => console.log("error", error));
     }
    await getAssesmentList()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getAssesment + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        let pid = localStorage.getItem("pid")
        let vd = localStorage.getItem("vd")
        let onb=localStorage.getItem("onboard")
        const dyta= localStorage.getItem("assesData")
        if(dyta !==null){
          setassesmentState(3)
        }
        else if(onb=="false"){
          Logout()
navigate("/signin");

}
        else if(vd=="false"){
                  Logout()
        navigate("/signin");
      
        }
       else if (pid=="null"){
        setassesmentState(1)
        // Logout()
        // navigate("/signin");
        }
       else if (dsa.result) {
          console.log(dsa.result)
          if(dsa.result.message==="This profile doesn't have any Question Template!"){
            setassesmentState(2)
          }
          if(dsa.result.length>0){

            if(asslength===0 & pid !=="null"){
              setassesmentState(2)
              
            }
            else if(asslength!==0 & pid !=="null"){
              var date = moment();
              var currentDate = date.format('YYYY-MM-DD')
              let odate= new Date(assLdate).getMonth()
              let cdate= new Date(currentDate).getMonth()
              if(cdate>odate){
                setassesmentState(2)
              }
              if(assLstate==="done"){
                setassesmentState(4)
              }
              else if(assLstate==="submit"){
                setassesmentState(4)
              }
              else if(assLstate==="draft"){
                setassesmentState(3)
              }
              else{
                setassesmentState(3)
              }
              
            }
          }
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {

    if(vd==="true"){
      getPlan()
      getConsultant();
      getUserList();
      getAssesment()
    }

  }, []);
  useEffect(() => {
    if(vd==="false"){
      setassesmentState(0)
      setPlanCardState(0)
      setuserCardState(0)
      setconsultCardState(0)
    }
  }, [vd])
  useEffect(() => {
    getAssesment()
  }, [])
  
  console.log("Tanvir",planCardState)
  return (
    <div className="innerPortal">
      {/* <HelpCard/> */}

      <AssesmentCard data={assesmentState}id={assid}/>
      <div className="quarterArea">
        {/* plan card */}
        <PlanCard data={planCardState} />
        {/* user card */}
        {/* <UserCard data={userCardState} rows={rows} total={rows==="User not found"?0:rows.length+user_remain} added={rows==="User not found"?0:rows.length} /> */}
        {/* consult card */}
        <Consult data={consultCardState} consultData={data} />
      </div>
    </div>
  );
}
