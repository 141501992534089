export const peopleData = {
    "Right People, Right Seat":{
        "20":"Your approach to having the right people in the right seats is currently undefined or severely lacking. The assessment indicates significant deficiencies in your talent acquisition and management practices. It is crucial to prioritize the development of a structured approach to ensure job-person fit within your organization. Engage in talent planning, design effective recruitment and selection processes, and establish performance management systems that align individual capabilities with job requirements. Provide training and resources to support talent development and create a culture that attracts and retains the right people in the right roles.",
        "40":"Your approach to having the right people in the right seats is still in the developing stage and requires further attention. There may be significant gaps in your talent acquisition and management strategies, resulting in mismatches between individuals and roles. Take this opportunity to revisit your recruitment, selection, and performance management processes. Strengthen your job analysis, competency mapping, and performance evaluation practices to better align individuals with suitable roles. Provide training and development opportunities to bridge any skill gaps and foster a culture of continuous improvement.",
        "60":"Your approach to having the right people in the right seats is adequate, but there are noticeable gaps that require attention. You have some processes in place to evaluate skills and qualifications during recruitment, but there may be inconsistencies or challenges in matching individuals to the most suitable roles. Enhance your talent acquisition and management practices by conducting thorough job analyses, implementing competency-based assessments, and providing training and support to individuals in their roles. Streamline your processes to ensure a better fit between people and positions.",
        "80":"Well done! Your approach to having the right people in the right seats is effective, with a few areas that could benefit from further attention. You have established practices to assess skills, experience, and cultural fit during the recruitment and selection process. However, there may be opportunities to enhance your talent management strategies, such as performance management, professional development, and succession planning. Focus on optimizing job-person fit, providing opportunities for growth, and fostering a supportive work environment to maximize individual and organizational performance.",
        "100":"Congratulations! Your approach to having the right people in the right seats is exceptional. You have a well-defined talent acquisition and management strategy that ensures individuals with the right skills, capabilities, and values are placed in positions where they can thrive. Your commitment to aligning individual strengths with job requirements maximizes productivity, engagement, and organizational success. Your emphasis on talent development, continuous learning, and career progression fosters a high-performing culture that attracts and retains top talent.",
    },
    "Org Structure & HR Process":{
        "20":"Your organization structure and HR processes are currently undefined or severely lacking. The assessment indicates significant deficiencies in both areas. It is crucial to prioritize the development of a clear and effective organizational structure and establish fundamental HR processes to support your workforce. Engage in strategic discussions to define reporting lines, decision-making authority, and communication channels. Develop HR policies, procedures, and guidelines to ensure fairness, consistency, and employee well-being. Regularly assess and adapt your structure and processes to drive organizational effectiveness.",
        "40":"Your organization structure and HR processes are still in the developing stage and require further attention. There may be significant deficiencies or inconsistencies in your structure, resulting in confusion, silos, or inefficiencies. Similarly, your HR processes may lack standardization, documentation, or clear guidelines, hindering their effectiveness. Take this opportunity to engage in organizational design discussions, implement best practices, and establish HR policies and procedures that support employee engagement, development, and performance.",
        "60":"Your organization structure and HR processes are adequate, but there are noticeable gaps that require attention. Your structure may lack clarity, resulting in challenges related to role clarity, decision-making, or communication. Similarly, your HR processes may be partially effective, but inconsistencies or gaps may hinder optimal performance and employee satisfaction. Consider conducting a thorough review of your structure, identifying areas for improvement, and enhancing your HR processes to align them with organizational goals and employee needs.",
        "80":"Well done! Your organization structure and HR processes are effective, with a few areas that could benefit from further attention. Your structure provides a reasonable framework for coordination and decision-making, although there may be some room for optimization or clarification. Similarly, your HR processes demonstrate basic effectiveness, but there may be opportunities to enhance consistency, transparency, and employee engagement. Regularly review and refine your structure and processes to align them with evolving business needs and industry best practices.",
        "100":"Congratulations! Your organization structure and HR processes are well-designed and streamlined, supporting efficient and effective operations. Your organizational hierarchy is clearly defined, promoting accountability, communication, and collaboration. HR processes, such as recruitment, onboarding, performance management, and employee development, are robust, standardized, and aligned with business objectives. Your focus on continuous improvement and adaptability ensures that your structure and processes remain agile and responsive to changing needs.",
    },
    "Role, Responsibilities & Accountabilities":{
        "20":"Your roles, responsibilities, and accountabilities are currently undefined or severely lacking. The assessment indicates significant deficiencies in clarifying and communicating roles within the organization. It is crucial to prioritize the development of clear job descriptions, aligned with organizational objectives and individual contributions. Engage in discussions with key stakeholders to define roles, responsibilities, and expected outcomes. Establish accountability frameworks and provide training or resources to support individuals in understanding and fulfilling their roles effectively.",
        "40":"Your roles, responsibilities, and accountabilities are still in the developing stage and require further attention. There may be significant deficiencies or inconsistencies in defining roles and responsibilities within the organization. Take this opportunity to conduct a thorough review of job descriptions, ensuring they align with strategic objectives and provide clarity. Define clear accountabilities and establish feedback mechanisms to track performance and alignment with role expectations. Regularly evaluate and refine roles and responsibilities as the organization evolves.",
        "60":"Your roles, responsibilities, and accountabilities are adequate, but there are noticeable gaps that require attention. Some positions may have loosely defined or outdated job descriptions, leading to confusion or inefficiencies. Evaluate and update job descriptions to clearly articulate roles, responsibilities, and expected outcomes. Enhance communication channels and feedback mechanisms to ensure individuals have a clear understanding of their accountabilities and how they contribute to organizational goals.",
        "80":"Well done! Your roles, responsibilities, and accountabilities are clear and effective, with a few areas that could benefit from further attention. Most positions have defined roles and responsibilities, although there may be minor gaps or overlaps that need clarification. Ensure that job descriptions are up-to-date, comprehensive, and aligned with the strategic objectives of the organization. Regularly communicate expectations and accountabilities to individuals, fostering a sense of ownership and accountability for their roles.",
        "100":"Congratulations! Your roles, responsibilities, and accountabilities are well-defined, aligned, and effectively communicated throughout the organization. Each position has clear job descriptions and well-defined responsibilities, ensuring that everyone understands their roles and contributions to the overall success of the organization. Accountabilities are assigned appropriately, promoting ownership and accountability for results. Your focus on role clarity enhances teamwork, reduces conflicts, and increases overall productivity.",
    },
   
}

