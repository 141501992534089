import AssesmentHeader from "./AssesmentHeader";
import { Typography } from "@mui/material";
import { Button } from "@carbon/react";
import Qcard from "./Qcard";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../api/apiList";
export default function AssesmentForMobile(){
    const { user, setUser } = useContext(UserContext);
    const [assesMentData, setassesMentData] = useState([])

    const navigate = useNavigate();
    const [selected, setselected] = useState("1")
    const percents = selected==="11"?100: (parseInt(selected)/11)*100
    const [assesmentSend, setassesmentSend] = useState({ "others_template_lines": []})
    const [assesmentSave, setassesmentSave] = useState([])
    const [error, seterror] = useState("")
    async function getAssesment() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({});
    
        var requestOptions = {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
    
          redirect: "follow",
        };
        fetch(apiL.getAssesment + user.id, requestOptions)
          .then(async (response) => {
            let dsa = await response.json();
            if (dsa.result) {
                setassesMentData(dsa.result)
                let data=[]
                dsa.result[selected-1]?.questions?.map((e)=>{
                data.push([e])
               })
               setassesmentSave(data)
              //No user handle
            } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
              navigate("/signin");
            }
          })
          .catch((error) => console.log("error", error));
      }
    async function ases(){
        let data=[]
       assesMentData[selected-1]?.questions?.map((e)=>{
       data.push([e])
      })
      setassesmentSave(data)
      }
    async function handleNext(){
      let data= assesmentSend
      if(assesMentData[selected-1].name==="VISION"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"vision_question_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="Mission"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"mission_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="Values"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }      
              else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"value_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="People"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"people_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="Customer"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"customer_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="Marketing"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"marketing_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="OPERATIONS / PRODUCTIONS / SUPPLY CHAIN"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"operation_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="Reporting and Feedback"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"reporting_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="IT and Financial Control"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"finance_template_lines":assesmentSave}
        setassesmentSend(data)
        setselected((parseInt(selected)+1).toString())
        }
      }
      else if(assesMentData[selected-1].name==="Strategy"){
        let err=""
        for (let index = 0; index < assesmentSave.length; index++) {
          if(assesmentSave[index][1]===undefined){
            err="Please answer all of the questions to continue."
            seterror(err)
            return
          }
          else{
            seterror("")
          }
        }
        if(err===""){
        data={...data,"planning_template_lines":assesmentSave}
        setassesmentSend(data)
        }
      }
      if (selected==="10"){
        console.log(assesmentSend)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify(data);
    
        var requestOptions = {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
    
          redirect: "follow",
        };
        fetch(apiL.createAssesment, requestOptions)
          .then(async (response) => {
            let dsa = await response.json();
            var raws = JSON.stringify({ "subject":"Assesment Completed",
            "body":`<div>Dear Admin,<br/> Assesment ID -, ${dsa.result.assessment} has been submitted please approve. </div>`,
            "recipient_emails":"tanvir@panoramamas.com,alif@panoramamas.com,bonnhy@panoramamas.com"});
            var requestOptionss = {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: raws,
        
              redirect: "follow",
            };
            if (dsa.result.assessment) {
              fetch(apiL.sendMail, requestOptionss)
              .then(async (response) => {
                let dsa = await response.json();
              })
              .catch((error) => console.log("error", error));
              navigate("/portal/index");
            } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
              navigate("/signin");
            }
          })
          .catch((error) => console.log("error", error));
      }
      window.scrollTo(0, 0)
    }
    console.log(assesMentData)
      useEffect(() => {
        getAssesment()
      }, [])

      useEffect(() => {
        ases()
      }, [selected])

    return (
        <div className="assesment">
            <AssesmentHeader/>
            <div className="assesmentCard" style={{width:"100%",zIndex:"1"}}>
                <div className="assesmentCardimg" style={{width:"100%"}}>
                    <img src="/assesmentCard.png" style={{width:"100%",height:"280px"}} />
                    <div className="asscardText" style={{width:"330px"}}>
                    <div style={{marginTop:"15px"}}>
                        <Typography variant="small" color="neutral.b50">
                        People
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h4B" color="neutral.b0">
                        {assesMentData[parseInt(selected)-1]?.name}
                        </Typography>
                    </div>
                    <div>
            {error===""?                        <Typography variant="small" color="neutral.b50" style={{marginTop:"15px",display:"block"}}>
                        A mission statement is concise explanation of the organization's
                        reason for existence. It describes tge organization's purpose.
                        </Typography>:                        <Typography variant="smallB" color="error.main" style={{marginTop:"15px",display:"block"}}>
                     {error}
                        </Typography>}
                    </div>
                    <div style={{ paddingTop: "12px",marginTop:"15px" }}>
                        <Button
                        kind="primary"
                        size="sm"
                        className="btnca"
                        // onClick={() => handleNext()}
                        >
                        Learn More
                        </Button>
                    </div>
                    </div>
                </div>
            </div>
            <div className="assesmentqsection" style={{paddingTop:"360px"}}>
            {assesmentSave?.map((e,i)=>{
              return   <Qcard data={e} key={e[0]} totalq={assesmentSave} stotalq={setassesmentSave} i={i}/>
            })}
            </div>
            <div className="assesmentBottom">
              <span style={{width:`${percents}%`}}></span>
              <div className="assesmentbottomleft">
                <h4>   {assesMentData[parseInt(selected)-1]?.name}</h4>
                <p>*Please finish the questions in this page to save and move on to the next section.</p>
              </div>
{selected!=="10"?              <div className="assesmentbottomright">
                {/* <button className="saveandclose">
                  Previous
                </button> */}
                <button className="save" onClick={()=>handleNext()}>
                  Next
                </button>
              </div>:""}
              {selected=="10"?              <div className="assesmentbottomright">
                <button className="save" onClick={()=>handleNext()}>
                  Submit
                </button>
              </div>:""}
            </div>
        </div>
    )
}