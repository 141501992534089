export const MarketingData = {
    "Branding":{
        "20":"Your branding efforts are currently undefined or severely lacking. The assessment indicates significant deficiencies in establishing a clear and effective brand identity. It is crucial to prioritize efforts to define your brand values, personality, and target audience. Conduct market research and competitor analysis to understand your customers' preferences and perceptions. Develop a comprehensive brand strategy that encompasses messaging, visual elements, and brand guidelines. Invest in professional design and marketing expertise to create a strong and differentiated brand identity.",
        "40":"Your branding efforts are still in the developing stage and require further attention. There may be significant deficiencies or inconsistencies in establishing a strong and cohesive brand identity. Invest time in defining your brand values, personality, and target audience. Develop a brand strategy that outlines key messaging themes, brand positioning, and visual guidelines. Collaborate with design and marketing professionals to create a compelling brand identity that aligns with your business objectives.",
        "60":"Your branding efforts are adequate, but there are noticeable gaps that require attention. While you have established some elements of your brand identity, there may be inconsistencies or lack of clarity in your messaging. Conduct a comprehensive brand audit to identify areas for improvement, such as visual consistency, messaging alignment, or brand positioning. Develop a brand style guide that provides clear guidelines for using brand elements consistently across all communication channels.",
        "80":"Well done! Your branding efforts are effective and aligned with your business goals, with a few areas that could benefit from further attention. You have developed a clear brand identity and messaging that effectively communicates your value to customers. To strengthen your branding, ensure consistency across all touchpoints, including your website, social media, packaging, and advertising materials. Regularly review and refine your brand guidelines to maintain alignment with your evolving business objectives.",
        "100":"Congratulations! Your branding efforts are strong and cohesive, resulting in a well-defined and recognizable brand identity. Your brand messaging is consistent across different channels and resonates with your target audience. You have effectively communicated your unique value proposition and differentiated yourself from competitors. Your brand elements, such as logo, colors, typography, and tone of voice, align seamlessly, creating a cohesive brand experience. Your brand equity is high, fostering customer trust, loyalty, and brand advocacy.",
    },
    "Ideal Customer":{
        "20":"Your understanding of your ideal customer is currently undefined or severely lacking. The assessment indicates significant deficiencies in identifying and defining your target audience. It is crucial to prioritize efforts to conduct thorough market research, customer surveys, and competitor analysis. Identify key demographics, psychographics, needs, and preferences of your target audience to develop a comprehensive ideal customer persona. Use this knowledge to customize your marketing strategies, resonate with your audience, and drive better results.",
        "40":"Your understanding of your ideal customer is still in the developing stage and requires further attention. There may be significant deficiencies or inconsistencies in defining their demographics, needs, and preferences. Take this opportunity to conduct market research, customer interviews, and competitor analysis to gain deeper insights into your target audience. Develop a more detailed ideal customer persona that encompasses their motivations, pain points, and buying behaviors to inform your marketing strategies.",
        "60":"Your understanding of your ideal customer is adequate, but there are noticeable gaps that require attention. While you have identified some characteristics of your target audience, there may be room for improvement in defining their needs and preferences. Invest in market research and customer feedback to gather more comprehensive insights. Expand your ideal customer persona by including additional psychographic and behavioral traits, allowing you to tailor your marketing efforts more effectively.",
        "80":"Well done! You have a clear understanding of your ideal customer, with a few areas that could benefit from further attention. You have identified key characteristics and preferences of your target audience, enabling you to align your marketing efforts accordingly. To strengthen your understanding, consider conducting additional market research and customer surveys to gather more insights. Refine your ideal customer persona by incorporating their pain points, aspirations, and behaviors to enhance your marketing effectiveness.",
        "100":"Congratulations! Your understanding of your ideal customer is well-defined and focused. You have conducted thorough research and analysis to identify a specific niche within your target market. Your ideal customer persona is detailed and encompasses their demographics, psychographics, needs, and preferences. This deep understanding allows you to tailor your marketing strategies and messaging effectively, resulting in higher engagement, conversion rates, and customer satisfaction.",
    },
    "Target Market":{
        "20":"Your understanding of your target market is currently undefined or severely lacking. The assessment indicates significant deficiencies in identifying and defining your overall market segment. It is crucial to prioritize efforts to conduct thorough market research, competitor analysis, and customer surveys. Identify key demographics, psychographics, needs, and preferences of your target market to develop a comprehensive target market definition. Use this knowledge to align your marketing strategies, reach your intended audience, and drive better business outcomes.",
        "40":"Your understanding of your target market is still in the developing stage and requires further attention. There may be significant deficiencies or inconsistencies in defining the specific characteristics and preferences of your audience. Take this opportunity to conduct market research, customer interviews, and competitor analysis to gain deeper insights into your target market. Refine your target market definition by incorporating demographic, psychographic, and behavioral traits that resonate with your offerings.",
        "60":"Your understanding of your target market is adequate, but there are noticeable gaps that require attention. While you have identified some characteristics of your target audience, there may be room for improvement in defining their needs, motivations, or preferences. Invest in market research, customer feedback, and trend analysis to gather more comprehensive insights. Expand your target market definition by incorporating additional segments or exploring adjacent market opportunities.",
        "80":"Well done! You have a clear understanding of your target market, with a few areas that could benefit from further attention. You have identified key characteristics and preferences of your target audience, allowing you to align your marketing efforts accordingly. To strengthen your understanding, consider conducting additional market research, competitor analysis, and customer surveys to gather more insights. Refine your target market definition by incorporating additional demographic, psychographic, or behavioral traits.",
        "100":"Congratulations! Your target market is well-defined and focused, allowing you to effectively tailor your marketing strategies. You have conducted thorough market research and analysis to identify a specific segment with distinct characteristics, needs, and preferences. Your understanding of the target market enables you to develop highly targeted marketing campaigns, resonate with your audience, and drive better engagement and conversion rates.",
    },
    "Competition":{
        "20":"Your understanding of the competition is currently undefined or severely lacking. The assessment indicates significant deficiencies in identifying and analyzing your competitors. It is crucial to prioritize efforts to conduct thorough competitor research, market analysis, and customer feedback. Identify key competitors in your market and analyze their offerings, target audience, and marketing strategies. Develop a systematic approach to competitive analysis, allowing you to position yourself effectively and respond to market dynamics.",
        "40":"Your understanding of the competition is still in the developing stage and requires further attention. There may be significant deficiencies or inconsistencies in identifying and analyzing your competitors. Take this opportunity to conduct competitor research, SWOT analysis, and customer surveys to gain deeper insights. Define a structured framework for competitive analysis, including key competitors, their offerings, pricing, and marketing strategies. Use this knowledge to refine your value proposition and differentiate yourself from the competition.",
        "60":"Your understanding of the competition is adequate, but there are noticeable gaps that require attention. While you have identified some competitors and their basic attributes, there may be room for improvement in analyzing their strategies and market positioning. Invest in competitive intelligence tools, customer feedback, and industry reports to gather more comprehensive insights. Expand your competitive analysis by evaluating their strengths, weaknesses, and potential threats they pose to your business.",
        "80":"Well done! You have a comprehensive understanding of your competitors, with a few areas that could benefit from further attention. You have identified key competitors and analyzed their offerings, target audience, and marketing approaches. To strengthen your competitive analysis, consider expanding your research to include emerging competitors, industry trends, and customer perceptions. Continuously monitor the competitive landscape to stay informed and agile in your marketing strategies.",
        "100":"Congratulations! Your understanding of the competition is thorough and strategic, providing you with a competitive advantage. You have conducted comprehensive research and analysis of your competitors, including their strengths, weaknesses, positioning, and marketing strategies. Your insights allow you to differentiate yourself, identify market gaps, and develop unique value propositions. By staying ahead of the competition, you are well-positioned to capture market share and drive growth.",
    },
}

