import React,{useState} from 'react'
import { Button } from "@carbon/react";
import Typography from "@mui/material/Typography";
export default function Qcard({data,totalq,stotalq,i}) {
  const [answer, setanswer] = useState(data[1])
  async function handleAnswer(ans){
    let dsa= totalq
    dsa[i][1]=ans
    // console.log(dsa)
    stotalq(dsa)
    setanswer(ans)
  }
  return (

    <div className={answer==null?"assMentQcard Qcards":"assMentQcard"}>
      <div className="ques">
        <Typography variant="large" color="neutral.b300">
   {(i+1)+". "}{data[0]}
        </Typography>
      </div>
      <div className="bdr">

      </div>
      <div className="qsbtn">
        <div className="qsbt">
        <Button kind="primary" size="md" className={"btnss"+(answer==="yes"?"selected":"")}
        onClick={()=>handleAnswer("yes")}
        >
          Yes
        </Button>
        </div>
        <div className="qsbt">
        <Button kind="primary" size="md" className={"btnss"+(answer==="no"?"selected":"")}
        onClick={()=>handleAnswer("no")}
        >
          No
        </Button>
        </div>
        <div className="qsbt"> 
        <Button kind="primary" size="md" className={"btnss"+(answer==="unsure"?"selected":"")}
        onClick={()=>handleAnswer("unsure")}
        >
          Unsure
        </Button>
        </div>
      </div>
    </div>

  )
}
