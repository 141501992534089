import React, { useEffect, useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonGL from "../../Components/reUse/buttonGl";
import ButtonFB from "../../Components/reUse/buttonFb";
import { useForm } from "react-hook-form";
import { signIn_schema } from "../../Components/signup/signschema";
import apiL from "../../api/apiList";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../userContext";
export default function Reset_Password() {
	const navigate = useNavigate();
	const [StateF, setStateF] = useState(false);
	const [btnSub, setbtnSub] = useState(false);
	const [error, seterror] = useState("");
	async function sendOTP(email) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			jsonrpc: "2.0",
			params: { email: email },
		});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
			redirect: "follow",
		};
		fetch(apiL.otp, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				if (dsa.result === "Mail send successfully") {
					setStateF(true);
					seterror(dsa.result);
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function reset(data) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				login: data.email,
				password: data.password,
				otp: data.otp,
			},
		});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
			redirect: "follow",
		};
		fetch(apiL.reset, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				if (dsa.result.message === "Password Changed Successfully!") {
					seterror(dsa.result.message);
				} else {
					seterror(dsa.result.error);
				}
			})
			.catch((error) => console.log("error", error));
	}
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({});
	const onSubmit = async (data) => {
		setbtnSub(true);
		if (StateF) {
			await reset(data);
		} else {
			await sendOTP(data.email);
		}
		setbtnSub(false);
	};
	return (
		<div style={{ minHeight: "100vh", minWidth: "100%" }}>
			<Grid container>
				<Grid item xl={5} lg={5} md={12} className="bg">
					<div className="pd">
						<div className="pdt">
							<img src="/pdt.png" />
						</div>
					</div>
				</Grid>
				<Grid item xl={7} lg={7} md={12}>
					<div className="cont">
						<img src="/logoFull.svg" alt="image" width={180} height={120} />
						<div className="form">
							{/* <Typography variant="base" color="initial">
                        We are here to help you
                    </Typography> */}
							<Typography variant="h4" color="neutral.b600">
								Reset Password
							</Typography>
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="cont1">
								<div className="txtc">
									<TextInput
										size="lg"
										className="txtF"
										id="text-input-1"
										type="text"
										labelText="Email*"
										placeholder="Email"
										invalid={errors.email ? true : false}
										invalidText={errors.email?.message}
										{...register("email", { required: true })}
									/>
								</div>
								{StateF ? (
									<div className="txtc">
										<TextInput
											size="lg"
											className="txtF"
											id="text-input-1"
											type="text"
											labelText="OTP*"
											placeholder="OTP"
											invalid={errors.email ? true : false}
											invalidText={errors.email?.message}
											{...register("otp", { required: true })}
										/>
									</div>
								) : (
									""
								)}
								{StateF ? (
									<div className="txtc">
										<TextInput
											size="lg"
											className="txtF"
											id="text-input-1"
											type="password"
											labelText="Password*"
											placeholder="Password"
											invalid={errors.email ? true : false}
											invalidText={errors.email?.message}
											{...register("password", { required: true })}
										/>
									</div>
								) : (
									""
								)}
								<div style={{ display: "flex", paddingTop: "12px" }}>
									{StateF ? (
										<div style={{ paddingRight: "8px" }}>
											<Button type="submit" disabled={btnSub}>
												Change Password
											</Button>
										</div>
									) : (
										<div style={{ paddingRight: "8px" }}>
											<Button type="submit" disabled={btnSub}>
												Send OTP
											</Button>
										</div>
									)}

									{/* <div style={{ paddingRight: "8px" }}>
            <ButtonGL />
          </div>
          <div style={{ paddingRight: "8px" }}>
            <ButtonFB />
          </div> */}
								</div>
								<div style={{ paddingTop: "8px" }}>
									<Typography variant="small" color="error.main">
										{error}
									</Typography>
								</div>
								<div className="txtccc">
									<Typography
										variant="base"
										color="initial"
										style={{ marginTop: "10px", display: "block" }}
									>
										Already have an account?{" "}
										<span
											style={{ color: "#0065FF", cursor: "pointer" }}
											onClick={() => navigate("/signin")}
										>
											Signin to panorama
										</span>{" "}
										.
									</Typography>
									<Typography
										variant="base"
										color="initial"
										style={{ marginTop: "10px", display: "block" }}
									>
										New to Panorama?{" "}
										<span
											style={{ color: "#0065FF", cursor: "pointer" }}
											onClick={() => navigate("/signup")}
										>
											Create a new account
										</span>{" "}
										.
									</Typography>
									<div className="ghg">
										<Typography variant="small" color="initial">
											By continuing, you agree to the Panorama{" "}
											<a href="https://panoramamas.com/privacy_policy">
												Privacy Policy
											</a>{" "}
											and{" "}
											<a href="https://panoramamas.com/terms&conditions">
												Terms & Conditions
											</a>{" "}
											.
										</Typography>
									</div>
								</div>
							</div>
						</form>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
