import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
  Tag,
} from "@carbon/react";
import Grid from "@mui/material/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { userSchema } from "../../onBoard/profileSchema";
import apiL from "../../../api/apiList";
export default function UserInfoTab({ data ,notify}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...data.personal_info } ,
    resolver: yupResolver(userSchema),
  });


  useEffect(() => {
    setTimeout(() => {
      reset({ ...data.personal_info,...data.business_info });
    }, 2000);
  }, [data.personal_info]);
  const onErrors = async (dsa)=>console.log(dsa)
  const onSubmit = async (datas) => {
    console.log(datas);
    delete datas["user_code"]
    delete datas["photo"]
    delete datas["company_code"]
    let pid = localStorage.getItem("id")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(datas);
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.updateProfile+pid, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        notify("Succesfully updated")
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <TabPanel>
        <form className="responsive-form" onSubmit={handleSubmit(onSubmit,onErrors)}>
        <Grid container spacing={2}>
   
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="onbUserProfileBtn">
              <div>
                <Typography variant="h4B" color="neutral.b800">
                  User Info
                </Typography>
              </div>
              <div style={{ width: "80%" }}>
                <Typography variant="small" color="neutral.b800">
                  Take our Free Assessment and understand your problems better
                  to accelerate your business growth or solve your business
                  prpblems
                </Typography>
              </div>

              <div className="onbBtn">
                <Button
                  kind="primary"
                  size="md"
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
  
            <div className="onPScroll">
              <div className="onpInput">
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="First Name"
                    placeholder="First Name"
                    invalid={errors.first_name ? true : false}
                    invalidText={errors.first_name?.message}
                    {...register("first_name", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Last Name"
                    placeholder="Last Name"
                    invalid={errors.last_name ? true : false}
                    invalidText={errors.last_name?.message}
                    {...register("last_name", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Title"
                    placeholder="Title"
                    invalid={errors.tittle ? true : false}
                    invalidText={errors.tittle?.message}
                    {...register("title", { required: true })}
                  />
                </div>
                <div className="onin">
                <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Responsibilities*"
                    placeholder="Responsibilities"
                    helperText="Press add seperating by comma"
                    invalid={errors.responsibilities ? true : false}
                    invalidText={errors.responsibilities?.message}
                    {...register("responsibilities", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    disabled
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Email"
                    placeholder="Email"
                    invalid={errors.email ? true : false}
                    invalidText={errors.email?.message}
                    {...register("email", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Phone"
                    placeholder="Phone"
                    invalid={errors.phone ? true : false}
                    invalidText={errors.phone?.message}
                    {...register("phone", { required: true })}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        </form>
    </TabPanel>
  );
}
