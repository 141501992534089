import React from "react";
import Typography from "@mui/material/Typography";
import PortalHeader from "./header";
import { Routes, Route } from "react-router-dom";
import PortalIndex from "../../Pages/Dashboard/index";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import apiL from "../../api/apiList";
import { useSearchParams } from "react-router-dom";
export default function Layout({ children, assesID ,modal}) {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const pid = localStorage.getItem("pid");
  const invited = localStorage.getItem("invited");
  let vd = localStorage.getItem("vd");
  const [openMenu, setOpenmenu] = useState();

  const getData = (data) => {
    setOpenmenu(data);
  };

  const { pathname } = useLocation();
  console.log(pathname);
  let id = searchParams.get("code")
	const Logout = () => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.logout, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				localStorage.clear();
				navigate("/signin");
			})
			.catch((error) => console.log("error", error));
	};
  return (
    <div>
      <div
        className={openMenu ? "PortalLayout PortalLayoutOpen" : "PortalLayout"}
      >
        <div className="Playout">
          <div className="lgSec">
            <div className="lgo">
              <img src="/lgborder.svg" alt="" srcset="" />
            </div>
            <div className="liness"></div>
          </div>
          <div className="services">
            {/* {openMenu || pathname.includes("/portal/report") ? "" : <div className="serviceText">
              <Typography variant="smallB" color="b800">
                Services
              </Typography>
            </div>} */}
            {pathname.includes("/portal/report") ? (
              <div className="serve">
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/overview/")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/overview/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Overview
                    </Typography>
                  </div>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/purpose")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/purpose/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Purpose
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/people")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/people/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      People
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/operation")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/operation/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Operation
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/customer")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/customer/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Customer
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/marketing")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/marketing/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Marketing
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/cashflow")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/cashflow/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      CashFlow
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/reports")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/reports/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Reports
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/financials")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" || pid =="1" || pid =="1"
                      ? modal(true)
                      : navigate("/portal/report/financials/?id=" + assesID+"&code="+id)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Financials
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div>
                {/* <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname.includes("/portal/report/strategy")
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/report/strategy")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Strategy
                    </Typography>
                  </div>
{pid==="1"?<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z" fill="black"/>
</svg>
:<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z" fill="black"/>
</svg>
}
                </div> */}
              </div>
            ) : (
              <div className="serve">
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname === "/portal/index"
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/index")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Dashboard
                    </Typography>
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.338 5.52991C9.444 7.46191 14.549 7.46191 19.655 5.52991C19.8058 5.47448 19.9677 5.4562 20.127 5.47661C20.2864 5.49701 20.4384 5.5555 20.5704 5.64713C20.7023 5.73876 20.8103 5.86082 20.8851 6.003C20.9598 6.14517 20.9993 6.30327 21 6.46391V17.4639C21 18.1559 20.308 18.6639 19.66 18.4259C14.553 16.4939 9.446 16.4939 4.339 18.4259C3.691 18.6719 3 18.1839 3 17.4909V6.46391C3.00098 6.30403 3.04028 6.14671 3.11461 6.00516C3.18894 5.86361 3.29612 5.74194 3.42718 5.65035C3.55823 5.55877 3.70933 5.49995 3.86781 5.47882C4.02629 5.45769 4.18753 5.47486 4.338 5.52891V5.52991Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
                {/* <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname === "/portal/users"
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/users")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Users
                    </Typography>
                  </div>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2882_36761)">
                      <path
                        d="M9 11.7173C11.2091 11.7173 13 9.92642 13 7.71729C13 5.50815 11.2091 3.71729 9 3.71729C6.79086 3.71729 5 5.50815 5 7.71729C5 9.92642 6.79086 11.7173 9 11.7173Z"
                        stroke="#BFBFBF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 21.7173V19.7173C3 18.6564 3.42143 17.639 4.17157 16.8889C4.92172 16.1387 5.93913 15.7173 7 15.7173H11C12.0609 15.7173 13.0783 16.1387 13.8284 16.8889C14.5786 17.639 15 18.6564 15 19.7173V21.7173"
                        stroke="#BFBFBF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 3.84729C16.8604 4.06759 17.623 4.56799 18.1676 5.2696C18.7122 5.97121 19.0078 6.83412 19.0078 7.72229C19.0078 8.61046 18.7122 9.47337 18.1676 10.175C17.623 10.8766 16.8604 11.377 16 11.5973"
                        stroke="#BFBFBF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 21.7173V19.7173C20.9949 18.8345 20.6979 17.9781 20.1553 17.2817C19.6126 16.5853 18.8548 16.088 18 15.8673"
                        stroke="#BFBFBF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2882_36761">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0 0.717285)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div> */}
{invited==="true"?"":                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname === "/portal/consultation"
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/consultation")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Consultation
                    </Typography>
                  </div>
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.795 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V9" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 16C12 17.0609 12.4214 18.0783 13.1716 18.8284C13.9217 19.5786 14.9391 20 16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16C20 14.9391 19.5786 13.9217 18.8284 13.1716C18.0783 12.4214 17.0609 12 16 12C14.9391 12 13.9217 12.4214 13.1716 13.1716C12.4214 13.9217 12 14.9391 12 16Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 1V5" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 1V5" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 9H17" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 14.4961V16.0001L17 17.0001" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                </div>}
{invited==="true"?"":                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname === "/portal/users"
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/users")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Users
                    </Typography>
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 7C5 8.06087 5.42143 9.07828 6.17157 9.82843C6.92172 10.5786 7.93913 11 9 11C10.0609 11 11.0783 10.5786 11.8284 9.82843C12.5786 9.07828 13 8.06087 13 7C13 5.93913 12.5786 4.92172 11.8284 4.17157C11.0783 3.42143 10.0609 3 9 3C7.93913 3 6.92172 3.42143 6.17157 4.17157C5.42143 4.92172 5 5.93913 5 7Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 21V19C3 17.9391 3.42143 16.9217 4.17157 16.1716C4.92172 15.4214 5.93913 15 7 15H11C12.0609 15 13.0783 15.4214 13.8284 16.1716C14.5786 16.9217 15 17.9391 15 19V21" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 20.9999V18.9999C20.9949 18.1171 20.6979 17.2607 20.1553 16.5643C19.6126 15.8679 18.8548 15.3706 18 15.1499" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>}
                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname === "/portal/assessmentreports"
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/assessmentreports")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Reports
                    </Typography>
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 11L16 8" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
                {invited==="true"?"":                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname === "/portal/plans"
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/plans")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Plans and Billings
                    </Typography>
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 8H11.5C11.1022 8 10.7206 8.15804 10.4393 8.43934C10.158 8.72064 10 9.10218 10 9.5C10 9.89782 10.158 10.2794 10.4393 10.5607C10.7206 10.842 11.1022 11 11.5 11H12.5C12.8978 11 13.2794 11.158 13.5607 11.4393C13.842 11.7206 14 12.1022 14 12.5C14 12.8978 13.842 13.2794 13.5607 13.5607C13.2794 13.842 12.8978 14 12.5 14H10M12 14V15.5M12 6.5V8" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>}


                <div
                  className={
                    vd == "false"
                      ? "ser notAllow"
                      : pathname === "/portal/userInfo"
                      ? "ser serActive"
                      : "ser"
                  }
                  onClick={() =>
                    vd == "false" ? "" : navigate("/portal/userInfo")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Profile
                    </Typography>
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 10C9 10.7956 9.31607 11.5587 9.87868 12.1213C10.4413 12.6839 11.2044 13 12 13C12.7956 13 13.5587 12.6839 14.1213 12.1213C14.6839 11.5587 15 10.7956 15 10C15 9.20435 14.6839 8.44129 14.1213 7.87868C13.5587 7.31607 12.7956 7 12 7C11.2044 7 10.4413 7.31607 9.87868 7.87868C9.31607 8.44129 9 9.20435 9 10Z" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.16797 18.849C6.41548 18.0252 6.92194 17.3032 7.61222 16.79C8.30249 16.2768 9.13982 15.9997 9.99997 16H14C14.8612 15.9997 15.6996 16.2774 16.3904 16.7918C17.0811 17.3062 17.5874 18.0298 17.834 18.855" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
                <div
                  className="ser"
                  onClick={() =>
                    Logout()
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="small" color="b800">
                      Logout
                    </Typography>
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 12H21L18 9" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 15L21 12" stroke="#0065FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </div>
              </div>
            )}

            {/* {pathname.includes("/portal/report") ? "" : <div className={vd == "false" ? "usa notAllow" : "usa"} onClick={() => vd == "false" ? "" : navigate("/portal/userInfo")}>
              <div className="userPro">
                <div className="usp">
                  <img src="/john_doe.png" className="userMargin" />
                  <div style={{ paddingLeft: "12px" }}>
                    <div>
                      <Typography variant="small" color="neutral.b800">
                        John Doe
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="small" color="neutral.b200">
                        Panorama
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>} */}
          </div>
        </div>
      </div>
      <PortalHeader getData={getData} />
      {children}
    </div>
  );
}
