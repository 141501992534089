export const purposeData = {
    "Vision":{
        "20":"Your vision statement and understanding of your business's purpose are undefined or severely lacking. The assessment indicates significant deficiencies in articulating a clear vision and connecting it with your purpose. It is crucial to prioritize the development of a well-defined vision that aligns with your purpose and inspires your stakeholders. Engage in strategic planning, seek input from key stakeholders, and invest time in crafting a compelling vision statement that guides your organization's strategic decisions and actions.",
        "40":"Your vision statement and understanding of your business's purpose are still in the developing stage. There are significant opportunities for improvement in terms of clarity, alignment, and inspiration. Your vision may not effectively communicate the direction and aspirations of your business or connect with your purpose. Focus on reevaluating your purpose, engaging key stakeholders in vision development, and crafting a compelling and actionable vision statement. Strengthening these areas will bring greater focus and direction to your organization.",
        "60":"Your vision statement and understanding of your business's purpose are adequate, but there are noticeable gaps that require attention. Your vision may lack some clarity and alignment with your purpose, which can lead to inconsistencies or limited inspiration among stakeholders. Focus on revisiting and strengthening your vision statement to ensure it reflects your purpose effectively. Communicate the vision clearly to all stakeholders and emphasize its importance in driving your business forward.",
        "80":"Well done! Your vision statement and understanding of your business's purpose are strong, with a few areas that could benefit from further attention. Your vision demonstrates clarity and provides a sense of direction for your organization. However, there may be minor gaps or opportunities to enhance the impact and alignment of your vision with your purpose. Consider refining your vision statement, reinforcing the connection between your vision and purpose, and ensuring consistent communication throughout the organization.",
        "100":"Congratulations! Your vision statement and understanding of your business's purpose are exceptional. Your vision is clear, inspiring, and aligns well with your purpose. It effectively communicates the direction and aspirations of your business to stakeholders, including employees, customers, and partners. Your strong vision serves as a guiding force, driving strategic decisions, and inspiring others to join your journey towards achieving your purpose.",
    },
    "10-Yr Moonshot":{
        "20":"Your 10-Year Moonshot goal is currently undefined or severely lacking. The assessment indicates significant deficiencies in articulating an ambitious and purpose-aligned moonshot goal. It is crucial to prioritize the development of a well-defined and inspiring moonshot goal that drives progress and motivates your team. Engage in strategic planning, involve key stakeholders in the goal-setting process, and establish a clear roadmap for its achievement. Fostering a culture of innovation and dedication will be essential in pursuing your moonshot goal.",
        "40":"Your 10-Year Moonshot goal is still in the developing stage. It lacks sufficient alignment with the purpose of your business and may require significant refinement to become truly ambitious and inspiring. Take this opportunity to revisit your purpose, engage key stakeholders in moonshot goal development, and aim for a more audacious target. Encourage a culture of innovation and provide resources to support the realization of your moonshot goal. Strengthening these areas will bring greater focus and determination to your organization's long-term vision.",
        "60":"Your 10-Year Moonshot goal is solid, but there are noticeable gaps that require attention. It shows some alignment with the purpose of your business, but there may be room for further clarity and ambition. Your moonshot goal has the potential to drive progress and impact, but it might require additional refinement and inspiration. Focus on revisiting your moonshot goal, aligning it more closely with your purpose, setting more challenging targets, and fostering an environment that encourages creativity and risk-taking.",
        "80":"Well done! Your 10-Year Moonshot goal is promising, with a few areas that could benefit from further attention. It reflects a strong alignment with the purpose of your business and demonstrates ambition and foresight. While there may be some areas to refine and clarify, your moonshot goal has the potential to drive significant progress and impact. Consider fine-tuning the specifics of your moonshot goal, ensuring it is well-communicated throughout the organization, and fostering a culture of innovation to support its realization.",
        "100":"Congratulations! Your 10-Year Moonshot goal is visionary and aligns perfectly with the purpose of your business. It is an ambitious and inspiring target that sets you apart as a forward-thinking organization. Your moonshot goal demonstrates a deep understanding of your purpose and a bold vision for the future. It has the potential to drive innovation, motivate your team, and create substantial impact. Your commitment to pursuing this audacious goal is commendable and positions you as a leader in your industry.",
    },
    "Mission":{
        "20":"Your mission statement is currently undefined or severely lacking. The assessment indicates significant deficiencies in articulating a clear and purpose-aligned mission statement. It is crucial to prioritize the development of a well-defined and inspiring mission statement that effectively communicates the purpose and values of your business. Engage in strategic discussions, involve key stakeholders in the process, and craft a mission statement that guides and unifies your organization.",
        "40":"Your mission statement is still in the developing stage and requires further attention. It may lack clarity, alignment, or inspiration. Take this opportunity to engage key stakeholders in the mission statement development process. Define the core purpose, values, and unique value proposition of your business more clearly. Craft a mission statement that inspires and guides your organization and resonates with your team and stakeholders.",
        "60":"Your mission statement is adequate and generally aligned with the purpose of your business, but there are noticeable gaps that require attention. Consider revisiting and refining your mission statement to make it more impactful, concise, and aligned with your purpose. Focus on ensuring that it clearly communicates the unique value your business offers and the impact it seeks to make. Regularly revisit and evaluate your mission statement to ensure its ongoing relevance.",
        "80":"Well done! Your mission statement is clear and meaningful, with a few areas that could benefit from further attention. It effectively communicates the purpose and values of your business, although there may be some room for refinement or specificity. Ensure that your mission statement is concise, memorable, and captures the essence of your organization's purpose. Continually communicate and reinforce your mission statement to ensure alignment and understanding throughout the organization.",
        "100":"Congratulations! Your mission statement is strong and impactful. It effectively communicates the core purpose, values, and objectives of your business. Your mission statement inspires and aligns your team, stakeholders, and customers around a common vision. It showcases your commitment to making a positive impact and drives your daily operations and decision-making. Your mission statement serves as a strong foundation for your organization's growth and success.",
    },
    "5-Year Target":{
        "20":"Your 5-Year Target is currently undefined or severely lacking. The assessment indicates significant deficiencies in articulating a clear and purpose-aligned target. It is crucial to prioritize the development of a well-defined and inspiring 5-Year Target that sets the direction and focus for your organization's growth. Engage in strategic planning, conduct market research, and involve key stakeholders in the process to develop a target that is specific, measurable, ambitious, and time-bound. Regularly monitor and adjust your target as necessary to stay on course.",
        "40":"Your 5-Year Target is still in the developing stage and requires further attention. It may lack clarity, focus, or alignment with your purpose and vision. Use this opportunity to engage key stakeholders, review industry trends, and conduct a thorough analysis of your business's strengths and opportunities. Develop a more specific and comprehensive target that encompasses key growth areas, innovation, market expansion, or other strategic objectives. Continuously refine and adapt your target as you gather more information and insights.",
        "60":"Your 5-Year Target is on track, but there are noticeable gaps that require attention. While it demonstrates a general sense of direction and purpose, there may be areas where further clarification and specificity are needed. Ensure that your target is well-aligned with your purpose and vision, and that it encompasses the key areas of growth and development for your organization. Regularly evaluate your progress, identify any gaps, and adjust your strategies and actions to stay on track.",
        "80":"Well done! Your 5-Year Target shows promise, with a few areas that could benefit from further attention. It reflects a strong alignment with your purpose and vision, setting realistic and challenging goals for your organization's future. While there may be some aspects that need refinement or additional specificity, your target provides a clear direction and focus for your strategic planning. Consider fine-tuning the specifics of your target, breaking it down into actionable milestones, and regularly reviewing and adjusting it as needed.",
        "100":"Congratulations! Your 5-Year Target is well-defined, ambitious, and aligned with your business's purpose and vision. It demonstrates a deep understanding of your industry, market opportunities, and strategic direction. Your target is specific, measurable, achievable, relevant, and time-bound (SMART), setting a clear roadmap for your organization's growth and success. It serves as a motivating force, inspiring your team and stakeholders to strive for excellence and achieve remarkable results.",
    },
    "Core Value":{
        "20":"Your core values are currently undefined or severely lacking. The assessment indicates significant deficiencies in articulating clear and purpose-aligned core values. It is crucial to prioritize the development of well-defined and meaningful core values that reflect the desired culture and behaviors within your organization. Engage in conversations with key stakeholders, involve your team in the process, and define a set of core values that will serve as guiding principles for your business. Communicate and reinforce these values throughout the organization to create a strong and aligned culture.",
        "40":"Your core values are still in the developing stage and require further attention. They may lack clarity, alignment, or consistency across the organization. Take this opportunity to engage key stakeholders in revisiting and refining your core values. Ensure they are meaningful, relevant, and specific to your business. Communicate your core values consistently and provide resources and training to support their integration into the organization's DNA. Continuously evaluate and reinforce your core values to drive a positive culture.",
        "60":"Your core values are adequate, but there are noticeable gaps that require attention. While they may generally align with your purpose, there is room for further clarity and reinforcement. Strengthen the definition and communication of your core values to ensure they are well-understood and embraced by your team. Focus on integrating your core values into your hiring, training, and recognition processes to build a strong organizational culture that embodies your desired behaviors.",
        "80":"Well done! Your core values are clear and meaningful, with a few areas that could benefit from further attention. They reflect a strong alignment with your purpose and provide a solid foundation for your organization's culture. While there may be minor gaps or room for additional specificity, your core values guide your team's behavior and contribute to a positive work environment. Consider reinforcing the importance of your core values, ensuring they are consistently communicated, and integrating them into your performance management processes.",
        "100":"Congratulations! Your core values are strong, well-defined, and deeply aligned with your business's purpose. They serve as the guiding principles that shape your organization's culture, behavior, and decision-making. Your core values are evident in your day-to-day operations and interactions, creating a positive and cohesive work environment. They are embraced by your team, stakeholders, and customers, reflecting your commitment to integrity, excellence, and the shared beliefs that define your business.",
    },
   
}


