const base_Url = 'https://agsmcs.info/'

const apiL={
    signup:`${base_Url}signup`,
    otp:`${base_Url}api/otp_mail_send`,
    reset:`${base_Url}forget_password`,
    getCurrency: `${base_Url}get_currencies`,
    signin: `${base_Url}web/session/authenticate`,
    getProfile: `${base_Url}get_profile/`,
    updateProfile: `${base_Url}update_profile/`,
    createProfile: `${base_Url}create_profile/`,
    onBoarding:  `${base_Url}onboarding_question/`,
    getUser:`${base_Url}get_user_status/`,
    getCountry:`${base_Url}get_country_list/`,
    getIndustry:`${base_Url}get_industry_list/`,
    getConsultant:`${base_Url}get_consultants/`,
    logout:`${base_Url}web/session/destroy`,
    userList:`${base_Url}get_user_list/`,
    remainUser:`${base_Url}remaining_user/`,
    addUser:`${base_Url}add_user/`,
    sendMail:`${base_Url}api/send_email`,
    getPlan:`${base_Url}get_plans/`,
    invoiceList:`${base_Url}get_invoice/`,
    makeInvoice:`${base_Url}make_invoice`,
    getAssesment : `${base_Url}get_assessment/`,
    asseslist:`${base_Url}get_assessment_list/`,
    updatePicture:`${base_Url}update_picture/`,
    createAssesment:`${base_Url}create_assessment/`,
    getDashboardBusiness:`${base_Url}get_dashboard/`,
    changePassword:`${base_Url}change_password`
}
export default apiL