export const FinanceData = {
    "Financial Statements":{
        "20":"Your business has inadequate financial statements, indicating significant deficiencies in your financial reporting practices. Your financial statements may lack accuracy, completeness, and adherence to accounting principles. It is crucial to address the gaps in financial statement preparation. Seek professional assistance to improve your accounting practices, enhance internal controls, and invest in appropriate financial management systems. Accurate and reliable financial statements are essential for informed decision-making and effective financial control.",
        "40":"Your business is still in the developing stage when it comes to financial statements. There may be deficiencies in the preparation, accuracy, and presentation of your financial information. It is essential to establish strong accounting practices, implement appropriate accounting software or tools, and seek professional assistance to ensure compliance with accounting standards. Focus on building a solid foundation for financial reporting to support effective financial control and planning.",
        "60":"Your business prepares adequate financial statements, but there are noticeable gaps that require attention. While your financial statements provide some insights into your financial position and performance, there may be room for improvement in terms of accuracy, consistency, and presentation. Enhance your financial statements by strengthening your accounting processes, ensuring proper classification and recording of transactions, and seeking professional guidance if necessary. Regularly review and validate the accuracy of your financial statements to ensure their reliability and usefulness.",
        "80":"Well done! Your business demonstrates strong financial statement practices, with a few areas that could benefit from further attention. Your financial statements are generally accurate and well-prepared, providing essential information about your financial position and performance. To further enhance your financial statements, consider implementing robust internal controls to ensure data integrity, enhancing financial statement analysis techniques, and conducting periodic reviews to identify areas for improvement. Continuously strive for accuracy, completeness, and clarity in your financial reporting.",
        "100":"Congratulations! Your business excels in preparing excellent financial statements. Your financial statements are accurate, comprehensive, and provide a clear representation of your financial position, performance, and cash flow. They adhere to the relevant accounting standards and best practices, enabling stakeholders to make informed decisions. Your commitment to financial transparency and accuracy sets a strong foundation for effective financial control and planning.",
    },
    "Budgeting & Forecasting":{
        "20":"Your business has inadequate budgeting and forecasting practices, indicating significant deficiencies in financial planning. Your budgeting process may be disorganized, inaccurate, or lacking in relevant financial insights. It is crucial to address the gaps in budgeting and forecasting practices. Invest in financial management expertise, implement standardized budgeting and forecasting procedures, and leverage technology tools to improve the accuracy and effectiveness of your financial planning processes.",
        "40":"Your business is still in the developing stage when it comes to budgeting and forecasting. There may be deficiencies in the budgeting process, forecasting accuracy, or alignment with strategic goals. It is essential to establish a robust budgeting framework, improve data collection and analysis capabilities, and refine your forecasting models. Seek guidance from financial professionals or implement budgeting and forecasting software to enhance your capabilities in this area.",
        "60":"Your business has adequate budgeting and forecasting practices, but there are noticeable gaps that require attention. While you have established some budgeting processes and forecasting techniques, there may be room for improvement in terms of accuracy, granularity, and responsiveness. Enhance your budgeting and forecasting by incorporating industry benchmarks, leveraging historical data, and strengthening collaboration between finance and other departments. Regularly review and adjust your budgets and forecasts to align with changing business conditions.",
        "80":"Well done! Your business demonstrates strong budgeting and forecasting practices, with a few areas that could benefit from further attention. Your budgeting process is well-structured, and your forecasts consider relevant financial and market factors. To further enhance your budgeting and forecasting, consider refining your assumptions, conducting sensitivity analyses, and regularly monitoring actual performance against the budget. Continuously evaluate and improve your budgeting and forecasting models to ensure accuracy and agility in financial planning.",
        "100":"Congratulations! Your business excels in budgeting and forecasting. Your budgeting process is comprehensive, accurate, and aligned with your strategic goals. You demonstrate a strong understanding of your financial drivers and effectively incorporate them into your forecasting models. Your budgeting and forecasting practices enable you to make informed financial decisions and adapt to changing market conditions. Continue leveraging your robust budgeting and forecasting process to drive financial control and planning excellence.",
    },
}

