import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OnBoardMain from "../../Components/onBoard/";
import AssesmentHeader from "./AssesmentHeader";
import { Button } from "@carbon/react";
import AssesmentCard from "./AssesmentCard";
import Qcard from "./Qcard";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../api/apiList";
export default function AssesmentForPc() {
  const { user, setUser } = useContext(UserContext);
  const [assesMentData, setassesMentData] = useState([])
  const [selected, setselected] = useState("1")
  const navigate = useNavigate();
  const [assesmentSend, setassesmentSend] = useState({ "others_template_lines": []})
  const [assesmentSave, setassesmentSave] = useState([])
  const [error, seterror] = useState("")
  const dyta= localStorage.getItem("assesData")
async function getAssesment() {
  // if (JSON.parse(localStorage.getItem("assesData"))!==null){
  //   let dsar =JSON.parse(localStorage.getItem("assesData"))
  //   if(dsar["vision_question_lines"]){
  //     setselected("2")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["mission_template_lines"]){
  //     setselected("3")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["value_template_lines"]){
  //     setselected("4")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["people_template_lines"]){
  //     setselected("5")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["customer_template_lines"]){
  //     setselected("6")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["marketing_template_lines"]){
  //     setselected("7")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["operation_template_lines"]){
  //     setselected("8")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["reporting_template_lines"]){
  //     setselected("8")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["finance_template_lines"]){
  //     setselected("9")
  //     setassesmentSend(dsar)
  //   }
  //   if(dsar["planning_template_lines"]){
  //     setselected("9")
  //     setassesmentSend(dsar)
  //   }
  // }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({});
  
      var requestOptions = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
  
        redirect: "follow",
      };
 
      fetch(apiL.getAssesment + user.id, requestOptions)
        .then(async (response) => {
          let dsa = await response.json();
          if (dsa.result) {
              let data=[]
              console.log(typeof(selected))
              setassesMentData(dsa.result)
              
              dsa.result[parseInt(selected)-1]?.questions?.map((e)=>{
              data.push([e])
 
             })
             
             if (dyta!==null){
              let dsar =JSON.parse(localStorage.getItem("assesData"))
              if(dsar["vision_question_lines"]){
                setselected("2")
                setassesmentSend(dsar)
              }
              if(dsar["mission_template_lines"]){
                setselected("3")
                setassesmentSend(dsar)
              }
              if(dsar["value_template_lines"]){
                setselected("4")
                setassesmentSend(dsar)
              }
              if(dsar["people_template_lines"]){
                setselected("5")
                setassesmentSend(dsar)
              }
              if(dsar["customer_template_lines"]){
                setselected("6")
                setassesmentSend(dsar)
              }
              if(dsar["marketing_template_lines"]){
                setselected("7")
                setassesmentSend(dsar)
              }
              if(dsar["operation_template_lines"]){
                setselected("8")
                setassesmentSend(dsar)
              }
              if(dsar["reporting_template_lines"]){
                setselected("9")
                setassesmentSend(dsar)
              }
              if(dsar["finance_template_lines"]){
                setselected("10")
                setassesmentSend(dsar)
              }
              if(dsar["planning_template_lines"]){
                setselected("11")
                setassesmentSend(dsar)
              }
              // else{
              //   setassesmentSave(data)
                
              // }
            }else{
              setassesmentSave(data)
              
            }
            //No user handle
          } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
            navigate("/signin");
          }
        })
        .catch((error) => console.log("error", error));
       

    }
  async function ases(){
      let data=[]
     assesMentData[selected-1]?.questions?.map((e)=>{
     data.push([e])
    })
    setassesmentSave(data)
    }
console.log(selected)
  async function handleNext(){
    let data= assesmentSend
    if(assesMentData[selected-1]?.name==="Vision"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"vision_question_lines":assesmentSave}
      setassesmentSend(data)

      localStorage.setItem("assesData",JSON.stringify(data))
      setselected((parseInt(selected)+1).toString())
      }
    }
    else if(assesMentData[selected-1]?.name==="Mission"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"mission_template_lines":assesmentSave}
      setassesmentSend(data)
      setselected((parseInt(selected)+1).toString())
      localStorage.setItem("assesData",JSON.stringify(data))
      }
    }
    else if(assesMentData[selected-1]?.name==="Values"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }      
            else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"value_template_lines":assesmentSave}
      setassesmentSend(data)
      setselected((parseInt(selected)+1).toString())
      localStorage.setItem("assesData",JSON.stringify(data))
      }
    }
    else if(assesMentData[selected-1]?.name==="People"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"people_template_lines":assesmentSave}
      setassesmentSend(data)
      localStorage.setItem("assesData",JSON.stringify(data))
      setselected((parseInt(selected)+1).toString())
      }
    }
    else if(assesMentData[selected-1]?.name==="Customer"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"customer_template_lines":assesmentSave}
      setassesmentSend(data)
      localStorage.setItem("assesData",JSON.stringify(data))
      setselected((parseInt(selected)+1).toString())
      }
    }
    else if(assesMentData[selected-1]?.name==="Marketing"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"marketing_template_lines":assesmentSave}
      setassesmentSend(data)
      localStorage.setItem("assesData",JSON.stringify(data))
      setselected((parseInt(selected)+1).toString())
      }
    }
    else if(assesMentData[selected-1]?.name==="Operations / Productions"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"operation_template_lines":assesmentSave}
      setassesmentSend(data)
      localStorage.setItem("assesData",JSON.stringify(data))
      setselected((parseInt(selected)+1).toString())
      }
    }
    else if(assesMentData[selected-1]?.name==="Reporting and Feedback"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"reporting_template_lines":assesmentSave}
      setassesmentSend(data)
      localStorage.setItem("assesData",JSON.stringify(data))
      setselected((parseInt(selected)+1).toString())
      }
    }
    else if(assesMentData[selected-1]?.name==="IT and Financial Control"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"finance_template_lines":assesmentSave}
      setassesmentSend(data)
      localStorage.setItem("assesData",JSON.stringify(data))
      setselected((parseInt(selected)+1).toString())
      }
    }
    else if(assesMentData[selected-1]?.name==="Strategy"){
      let err=""
      for (let index = 0; index < assesmentSave.length; index++) {
        if(assesmentSave[index][1]===undefined){
          err="Please answer all of the questions to continue."
          seterror(err)
          return
        }
        else{
          seterror("")
        }
      }
      if(err===""){
      data={...data,"planning_template_lines":assesmentSave}
      localStorage.setItem("assesData",JSON.stringify(data))
      setassesmentSend(data)
      }
    }
    if (selected==="10"){
      console.log(assesmentSend)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify(data);
  
      var requestOptions = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
  
        redirect: "follow",
      };

      fetch(apiL.createAssesment, requestOptions)
        .then(async (response) => {
          let dsa = await response.json();
          var raws = JSON.stringify({ "subject":"Assesment Completed",
          "body":`<div>Dear Admin,<br/> Assesment ID -, ${dsa.result.assessment} has been submitted please approve. </div>`,
          "recipient_emails":"tanvir@panoramamas.com,alif@panoramamas.com,bonnhy@panoramamas.com"});
          var requestOptionss = {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: raws,
      
            redirect: "follow",
          };
          var requestOptionsss = {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
      
            redirect: "follow",
          };
          if (dsa.result.assessment) {
            fetch("https://agsmcs.info/assessment_approved/"+dsa.result.assessment,requestOptionsss)
            fetch(apiL.sendMail, requestOptionss)
            .then(async (response) => {
              let dsa = await response.json();
            })
            .catch((error) => console.log("error", error));
            localStorage.removeItem("assesData")
            navigate("/portal/index");
          } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
            navigate("/signin");
          }
        })
        .catch((error) => console.log("error", error));
    }
    window.scrollTo(0, 0)
  }


    useEffect(() => {
      getAssesment()
    }, [dyta])

    useEffect(() => {
      ases()
    }, [selected])
  return (
        <div className="assesment">
      <AssesmentHeader />
  <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <AssesmentCard data={assesMentData} selected={selected} setselected={setselected} handleNext={handleNext} error={error} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <div className="assesmentqsection">
          {assesmentSave.length===0?"":assesmentSave.map((e,i)=>{
              return   <Qcard data={e} key={e[0]} totalq={assesmentSave} stotalq={setassesmentSave} i={i}/>
            })}
            {selected !== "10" ? (
							<button className="customBtnD" style={{marginTop:'24px'}} onClick={() => handleNext()}>
								Next
							</button>
						) : (
							<>
								{" "}
								{selected === "11" ? (
									""
								) : (
									<button className="customBtnD" style={{marginTop:'24px'}} onClick={() => handleNext()}>
										Submit
									</button>
								)}
							</>
						)}
            </div>

          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
