export const cashflowData = {
    "Results":{
        "20":"Your cash flow results are poor, indicating severe deficiencies in managing cash inflows and outflows. Poor cash flow can lead to financial distress, including the inability to meet operational expenses, pay suppliers, or honor financial obligations. It is crucial to address the underlying issues that contribute to the poor cash flow, such as ineffective pricing, high overhead costs, or inadequate cash management practices. Seek professional assistance, such as financial advisors or accountants, to develop a comprehensive cash flow improvement plan.",
        "40":"Your cash flow results are unstable, indicating significant challenges in managing cash inflows and outflows. Fluctuating cash flow can lead to cash shortages, difficulty meeting financial obligations, and limited room for business growth. It is essential to identify the underlying causes of the instability, such as delayed payments, irregular revenue patterns, or ineffective expense control. Develop strategies to improve cash flow predictability, implement stricter credit control measures, and explore opportunities to increase cash reserves.",
        "60":"Your cash flow results are moderately stable, but there are noticeable areas that require attention. While your cash flow shows some consistency, there may be fluctuations or occasional challenges in managing cash inflows and outflows. Take this opportunity to review your revenue streams, cost structures, and cash flow projections. Implement measures to diversify income sources, reduce unnecessary expenses, and strengthen your cash flow management practices.",
        "80":"Well done! Your cash flow results are stable and adequate, with a few areas that could benefit from further attention. You have maintained a relatively consistent cash flow position, ensuring that your business operations are adequately funded. To strengthen your cash flow, consider implementing strategies to optimize revenue generation, manage expenses more efficiently, and improve working capital management. Regularly monitor your cash flow and identify potential risks or opportunities for improvement.",
        "100":"Congratulations! Your cash flow results are strong and positive, indicating healthy financial management. You have effectively managed your cash inflows and outflows, resulting in consistent positive cash flow. This demonstrates your ability to generate sufficient revenue, control expenses, and optimize working capital. Your strong cash flow position provides financial stability, allowing you to invest in growth opportunities, handle unforeseen expenses, and repay debts promptly.",
    },
    "Access to Fund":{
        "20":"Your business has poor access to funds, indicating significant difficulties in obtaining financing. Limited access to funds can hinder your ability to grow, invest, or meet essential financial obligations. It is essential to address the root causes of the poor access to funds, such as poor credit history, high debt levels, or weak financial management practices. Seek professional advice, develop a robust financial improvement plan, and consider alternative funding options such as bootstrapping, cost-cutting measures, or partnerships to overcome the challenges.",
        "40":"Your business has limited access to funds, indicating challenges in obtaining financing when needed. Securing external financing may be difficult due to factors such as limited collateral, weak credit history, or uncertain business prospects. It is crucial to address the underlying issues and improve your financial standing. Take steps to improve your creditworthiness, build relationships with lenders or investors, and explore government programs or small business assistance initiatives to enhance your access to funds.",
        "60":"Your business has adequate access to funds, but there are noticeable gaps that require attention. While you can obtain financing to meet your basic needs, there may be limitations or challenges in securing larger amounts or favorable terms. Evaluate your current financing options and identify areas for improvement. Enhance your financial statements, develop a solid business plan, and consider exploring alternative financing options such as grants, crowdfunding, or strategic partnerships to enhance your access to funds.",
        "80":"Well done! Your business has good access to funds, with a few areas that could benefit from further attention. You have established relationships with lenders or investors, enabling you to secure financing for your business needs. To further enhance your access to funds, consider diversifying your financing options, expanding your network of potential investors or lenders, and improving your financial documentation and reporting. Strengthening your financial position and demonstrating consistent business performance will further improve your access to funds.",
        "100":"Congratulations! Your business has excellent access to funds, providing you with ample financial resources when needed. You have established strong relationships with lenders, investors, or financial institutions, allowing you to secure financing quickly and at favorable terms. Your strong financial position, solid credit history, or robust business performance have instilled confidence in external parties, making it easier for you to access funds for business operations, expansion, or capital investments.",
    },
}

