import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Typography from "@mui/material/Typography";
import {CircularProgress, LinearProgress} from '@mui/material';
import { Button } from '@carbon/react';
import apiL from '../../../../api/apiList';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Layout from '../../layout';
export default function Strategy(props) {
    let [searchParams] = useSearchParams();
    const aid = searchParams.get("id") || null;
    const [rep, setrep] = useState([])
    async function getreport() {
        let id = localStorage.getItem("id")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "dashboard":  "BUSINESS",
            "assessment_id": parseInt(aid)
        });
        var requestOptions = {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
    
          redirect: "follow",
        };
        fetch(apiL.getDashboardBusiness + id, requestOptions)
          .then(async (response) => {
            let dsa = await response.json();
            if(dsa.result){
                setrep(dsa.result)
            }
          })
          .catch((error) => console.log("error", error));
      }
      useEffect(() => {
        getreport()
      }, [])
      
    return (
        <>    <Layout/>
            <div className="innerPortal bg-grey-plan">
            <div className='report'>
                <div className='report-left'>
                    <Grid container spacing={0}>
                        <Grid sm={12} md={12} lg={5} xl={5}>
                            <div className='report-card reprot-left-top'>
                                <div className='heading'>
                                    <Typography variant="h4B" color="neutral.b800">
                                        Your Business
                                    </Typography>
                                </div>
                                <div className='report-progress'>
                                    <LinearProgress variant="determinate" color='progress' value={30} />
                                    <div className='report-progress-content'>
                                        <h1>72%</h1>
                                        <p>Average</p>
                                    </div>
                                </div>
                                <div className='result'>
                                    <h3>Your have a solid understanding of your Business's Purpose and why it exists</h3>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without</p>
                                </div>
                            </div>
                            <div className='report-card reprot-left-bottom'>
                                <div className='heading'>
                                    <Typography variant="h4B" color="neutral.b800">
                                        Solutions
                                    </Typography>
                                </div>
                                <ul className='solution-list'>
                                    <li className='single-solution'>
                                        <div className='single-solution-top' style={{background:"url(/solution-bg.svg)",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <h4>Business Consulting</h4>
                                            <p>Management consulting is the practice of providing consulting services to organizations to improve their performance or in any way...</p>
                                        </div>
                                        <div className='single-solution-bottom'>
                                            <img src="/logopanaroma.svg" />
                                            <div className='single-solution-text'>
                                                <p>Panorama</p>
                                                <p>Management Advisory Services</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='single-solution'>
                                        <div className='single-solution-top' style={{background:"url(/solution-bg.svg)",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <h4>Business Consulting</h4>
                                            <p>Management consulting is the practice of providing consulting services to organizations to improve their performance or in any way...</p>
                                        </div>
                                        <div className='single-solution-bottom'>
                                            <img src="/logopanaroma.svg" />
                                            <div className='single-solution-text'>
                                                <p>Panorama</p>
                                                <p>Management Advisory Services</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='single-solution'>
                                        <div className='single-solution-top' style={{background:"url(/solution-bg.svg)",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                            <h4>Business Consulting</h4>
                                            <p>Management consulting is the practice of providing consulting services to organizations to improve their performance or in any way...</p>
                                        </div>
                                        <div className='single-solution-bottom'>
                                            <img src="/logopanaroma.svg" />
                                            <div className='single-solution-text'>
                                                <p>Panorama</p>
                                                <p>Management Advisory Services</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid sm={12} md={12} lg={7} xl={7}>
                            <div className='report-card reprot-right-top'>
                                <div className='heading'>
                                    <Typography variant="h4B" color="neutral.b800">
                                        Understand Your Purpose
                                    </Typography>
                                </div>
                                <div className='report-purpose'>
                                    <h3>WHAT IS YOUR PURPOSE</h3>
                                    <p>Take a look at the following to understand how much you understand regarding the following things surrounding your own Business purpose. Take a look at the following to understand how much you understand regarding the following things surrounding your own Business purpose. </p>
                                    <h3>WHY IS IT IMPORTANT</h3>
                                    <p>Take a look at the following to understand how much you understand regarding the following things surrounding your own Business purpose. Take a look at the following to understand how much you understand regarding the following things surrounding your own Business purpose. </p>
                                </div>
                                <div className='btns'>
                                    <div className='report-card-btn'>
                                        <img src="/idea.svg" />
                                        <p>Knowledge Center</p>
                                    </div>
                                    <div className='report-card-btn'>
                                        <img src="/download.svg" />
                                        <p>Download Section Report</p>
                                    </div>
                                </div>
                            </div>
                            <div className='all-report-cards'>
                            {rep.map((e)=>{
                                return     <div className='report-card reprot-progress-item'>
                                <div className='report-progress-item-top'>
                                    <p>{e.block}</p>
                                    <p>{e.yes}%</p>
                                </div>
                                <LinearProgress variant="determinate" color='progress3' value={e.yes} />
                                <div className='report-progress-item-bottom'>
                                    <p>Your have a great understanding!</p>
                                    <span>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without</span>
                                </div>
                            </div>
                            })}
                                <div className='report-card-premium'>
                                    <div className="assesmentbottomleft">
                                        <h4>Don't miss out on the full experience</h4>
                                        <p>Enjoy access to all features including analytics, reporting, and priority support for your team.</p>
                                    </div>
                                    <div className="assesmentbottomright">
                                        {/* <button className="saveandclose">
                                            Explore all Packages
                                        </button> */}
                                        <Button kind="primary" className="btnss" size="md">Explore all Packages</Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>    
        </>
    )
}
