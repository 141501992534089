import React,{useState,useEffect} from 'react'
import Typography from "@mui/material/Typography";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import { useNavigate } from "react-router-dom";
import apiL from "../../../api/apiList";
import Grid from "@mui/material/Grid";
import Layout from '../layout';
export default function ReportsTab() {
    const [rows, setrows] = useState([])
    const navigate = useNavigate();
    const headers = ["Name", "Company", "Date", "Action"];
    async function getAssesment(){
      let id =localStorage.getItem("id")
       var myHeaders = new Headers();
       myHeaders.append("Content-Type", "application/json");
       var raw = JSON.stringify({});
       var requestOptions = {
         method: "POST",
         credentials: "include",
         headers: {
           "Content-Type": "application/json",
         },
         body: raw,
         redirect: "follow",
       };
       fetch(apiL.asseslist+id, requestOptions)
         .then(async (response) => {
           let dsa = await response.json();
           if(dsa.result){
           let data= dsa.result.assessments
           setrows(data)
           }
           else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
            navigate("/signin");
          }
         })
         .catch((error) => console.log("error", error));
     }
     useEffect(() => {
      getAssesment()
     }, [])
     console.log(rows)
  return (
    <div>  
    <Layout/>
    <div className="innerPortal bg-grey-plan">
     <div className="assTab">
    <Typography variant="h4B" color="neutral.b800">
      Assessment Reports
    </Typography>{" "}
  </div>{" "}
  <div className="asstabtable">
    {" "}
    <Table size="lg" useZebraStyles={false}>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableHeader id={header.key} key={header}>
              {header}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length ==0? <div style={{paddingTop:'25px',paddingLeft:'12px'}}><span style={{fontSize:'20px'}}>No Report Available</span></div>: rows.map((row) => (
          <TableRow key={row.id}>
            <TableCell key={row.name}>{row.name}</TableCell>
            <TableCell key={row.company}>{row.company}</TableCell>
            <TableCell key={row.create_date}>{row.create_date}</TableCell>
            <TableCell>
              {row.state=="done"?                  <Button
                kind="primary"
                size="sm"
                className="btnss"
                onClick={() => navigate("/portal/report/overview/?id=" + row.id+"&code="+row.profile_code)}
              >
               View
              </Button>:                  <Button
                kind="primary"
                size="sm"
                className="btnss"
                disabled
              >
               View
              </Button>}

            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div></div></div>
  )
}
