import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CircularProgress, LinearProgress } from "@mui/material";
import {
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Button,
	Modal,
	Select,
	SelectItem,
	TextInput,
} from "@carbon/react";
import apiL from "../api/apiList";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { Reportdata } from "./reportData";
export default function Tst(props) {
	let [searchParams] = useSearchParams();
	const aid = searchParams.get("id") || null;
	const id = searchParams.get("uid") || null;
	const [rep, setrep] = useState([]);
	const [avg, setavg] = useState(0);
	const [clarity, setclarity] = useState(0);
	const [reportA, setreportA] = useState({});
	async function getAssesmentList() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.asseslist + id, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				let dta = dsa.result.assessments;
				for (let index = 0; index < dta.length; index++) {
					const element = dta[index];
					console.log(element);
					if (element.id === parseInt(aid)) {
						setreportA(element);
						console.log(element);
					}
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getreport() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.signin, requestOptions).then(async (response) => {
			var raw = JSON.stringify({
				dashboard: "BUSINESS",
				assessment_id: parseInt(aid),
			});

			var requestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: raw,

				redirect: "follow",
			};
			getAssesmentList() 
			fetch(apiL.getDashboardBusiness + id, requestOptions)
				.then(async (response) => {
					let dsa = await response.json();
					if (dsa.result) {
						let newArray = dsa.result.filter(function (el) {
							return el.block != "STRATEGY, PLANNING AND SUCCESSION";
						});
						setrep(newArray);
						let totals = 0;
						let clar = 0;
						dsa.result.map((e) => {
							totals = e.yes + totals;
							clar = e.unsure + clar;
						});
						totals = totals / (dsa.result.length - 1);
						clar = 100 - clar / dsa.result.length;
						setavg(totals);
						setclarity(clar);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}
	const clarityDef = () => {
		{
			if (clarity <= 20) {
				return (
					<>
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progress"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["20"]}</span>
							</div>
						</div>
					</>
				);
			} else if (clarity <= 40) {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progorange"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["40"]}</span>
							</div>
						</div>
					</>
				);
			} else if (clarity <= 60) {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progyellow"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["60"]}</span>
							</div>
						</div>
					</>
				);
			} else if (clarity <= 80) {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="proglightgreen"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["80"]}</span>
							</div>
						</div>
					</>
				);
			} else {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progdarkgreen"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["100"]}</span>
							</div>
						</div>
					</>
				);
			}
		}
	};
	const business = () => {
		if (avg < 20) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading" style={{textAlign:'center'}}>
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
					<div style={{textAlign:'end'}}>
							<p style={{fontWeight:'300',fontSize:'1.5rem'}}>{parseInt(avg)}%</p>
						</div>
						<LinearProgress
							variant="determinate"
							color="progress"
							value={avg}
						/>

					</div>
					<div className="result">
						<p>
							Your overall business management is in a critical state, requiring
							immediate attention. The assessment highlights severe deficiencies
							in various areas of your business, significantly impacting your
							management effectiveness. Focus on seeking professional guidance,
							conducting a thorough assessment of your business practices, and
							developing a comprehensive improvement plan. By implementing
							strategic changes and addressing these deficiencies, you can work
							towards revitalizing your overall business management.
						</p>
					</div>
				</div>
			);
		} else if (avg < 40) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
					<div style={{textAlign:'end'}}>
							<p style={{fontWeight:'300',fontSize:'1.5rem'}}>{parseInt(avg)}%</p>
						</div>
						<LinearProgress
							variant="determinate"
							color="progorange"
							value={avg}
						/>

					</div>
					<div className="result">
						<p>
							Your overall business management needs significant improvement.
							The assessment indicates challenges in multiple areas of your
							business, including mission and purpose, customer understanding,
							financial control and planning, operations and technology,
							reporting and feedback, and people management. It is crucial to
							address these issues promptly by conducting a comprehensive review
							of your business practices, seeking expert guidance, and
							implementing strategic changes to enhance performance. By
							addressing these challenges, you can work towards improving your
							overall business management.
						</p>
					</div>
				</div>
			);
		} else if (avg < 60) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
					<div style={{textAlign:'end'}}>
							<p style={{fontWeight:'300',fontSize:'1.5rem'}}>{parseInt(avg)}%</p>
						</div>
						<LinearProgress
							variant="determinate"
							color="progyellow"
							value={avg}
						/>

					</div>
					<div className="result">
						<p>
							Your overall business management demonstrates fair performance,
							but there are noticeable gaps that require attention. It appears
							that there may be inconsistencies or uncertainties in certain
							areas of your business, impacting your overall management
							effectiveness. Focus on identifying the specific areas where
							improvement is needed, developing action plans to address those
							areas, and implementing changes to enhance performance. By
							addressing these gaps, you can strengthen your overall business
							management and achieve better results.
						</p>
					</div>
				</div>
			);
		} else if (avg < 80) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
					<div style={{textAlign:'end'}}>
							<p style={{fontWeight:'300',fontSize:'1.5rem'}}>{parseInt(avg)}%</p>
						</div>
						<LinearProgress
							variant="determinate"
							color="proglightgreen"
							value={avg}
						/>
					</div>
					<div className="result">
						{/* <h3></h3> */}
						<p>
							Well done! Your overall business management is generally good,
							with a few areas that could benefit from further attention. While
							you have a decent understanding and management of key business
							aspects, there may be occasional gaps or opportunities for
							improvement. Consider focusing on the specific areas where scores
							may be lower and implementing targeted strategies to enhance
							performance. By addressing these areas, you can further strengthen
							your overall business management and optimize your business's
							potential.
						</p>
					</div>
				</div>
			);
		} else if (avg < 100) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
					<div style={{textAlign:'end'}}>
							<p style={{fontWeight:'300',fontSize:'1.5rem'}}>{parseInt(avg)}%</p>
						</div>
						<LinearProgress
							variant="determinate"
							color="progdarkgreen"
							value={avg}
						/>

					</div>
					<div className="result">
						<p>
							Congratulations! Your overall business management is excellent.
							You demonstrate a high level of competence in various aspects of
							your business, including mission and purpose, customer
							understanding, financial control and planning, operations and
							technology, reporting and feedback, and people management. Your
							exceptional performance across these areas reflects a well-rounded
							and strategically managed business. Your commitment to continuous
							improvement and excellence positions you for sustained success in
							a competitive marketplace.
						</p>
					</div>
				</div>
			);
		}
	};

	const [purpose, setPurpose] = useState([]);

	async function fetchPurposeSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "PURPOSE",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setPurpose(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}

	const [people, setPeople] = useState([]);

	async function fetchPeopleSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "PEOPLE",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setPeople(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}

	const [cashflow, setCashflow] = useState([]);

	async function fetchCashflowSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "CASH FLOW",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setCashflow(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}

	const [customer, setCustomer] = useState([]);

	async function fetchCustomerSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "CUSTOMER & OFFERINGS",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setCustomer(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}

	const [financial, setFinancial] = useState([]);

	async function fetchFinancialSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "FINANCIAL CONTROL & PLANNING",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setFinancial(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}

	const [marketing, setMarketing] = useState([]);

	async function fetchMarketingSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "MARKETING & BRANDING",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setMarketing(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}

	const [operation, setOperation] = useState([]);

	async function fetchOperationSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "OPERATIONAL & TECHNOLOGY EFFICIENCY",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setOperation(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}

	const [report, setReport] = useState([]);

	async function fetchReportSegments() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var authenticationPayload = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});

		var authenticationOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: authenticationPayload,
			redirect: "follow",
		};

		fetch(apiL.signin, authenticationOptions).then(async (response) => {
			var segmentPayload = JSON.stringify({
				dashboard: "REPORTING & FEEDBACK",
				assessment_id: parseInt(aid),
			});

			var segmentRequestOptions = {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
				body: segmentPayload,
				redirect: "follow",
			};

			fetch(apiL.getDashboardBusiness + id, segmentRequestOptions)
				.then(async (response) => {
					let segmentResponse = await response.json();
					if (segmentResponse.result) {
						let filteredData = segmentResponse.result.filter(function (el) {
							// This filter excludes data related to "STRATEGY, PLANNING AND SUCCESSION"
							// You may need to adjust the condition to fit your actual requirements.
							return el.block !== "STRATEGY, PLANNING AND SUCCESSION";
						});
						setReport(filteredData);
					}
				})
				.catch((error) => console.log("error", error));
		});
	}
	const [rows, setrows] = useState([]);
	console.log(reportA)
	useEffect(() => {
		getreport();
		
		fetchPurposeSegments();
		fetchPeopleSegments();
		fetchCustomerSegments();
		fetchMarketingSegments();
		fetchOperationSegments();
		fetchReportSegments();
		fetchCashflowSegments();
		fetchFinancialSegments();
	}, []);

	return (
		<>
			{" "}
			<div>
				<div className="report">
					<div className="report-left">

						<Grid container spacing={0}>
							<Grid sm={12} md={12} lg={5} xl={5}>
							<div className="lgo" style={{ paddingLeft: "20px",display:'flex',justifyContent:'space-between' }}>
									<div>
									<img
										src="/lgborder.svg"
										alt=""
										srcset=""
										style={{ width: "180px" }}
									/>
									<p style={{fontSize:'12px',paddingLeft:'10px'}}>info@panoramamas.com</p>
									</div>
									<div style={{marginRight:'50px',textAlign:'end'}}>
									
									<p>Date - {reportA?.create_date}</p>
									<p>By - {reportA?.name}</p>
									<p>{reportA?.email}</p>
									<p>Ref. {aid+id}</p>
									</div>
								</div>
								<div>
								<div style={{textAlign:'center'}}>
								<p style={{fontSize:'28px',fontWeight:'500'}}>Assessment Detail Report</p>
								<p style={{fontSize:'24px',fontWeight:'500'}}>{reportA?.company}</p>
								
								</div>
									{business()}
									<div className="all-report-cards">
										{clarityDef()}

									</div>
									<div className="all-report-cards">
										<p style={{ paddingLeft: "24px" ,textAlign:'center',fontSize:'24px'}}>PURPOSE</p>
										{rep.map((n) => {
											if(n.block==="PURPOSE"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}

										{purpose.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.sub_block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									<div className="all-report-cards">
										<p style={{ paddingLeft: "24px",textAlign:'center',fontSize:'24px' }}>PEOPLE</p>
										{rep.map((n) => {
											if(n.block==="PEOPLE"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}
										{people.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.sub_block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									<div className="all-report-cards">
										<p style={{ paddingLeft: "24px",textAlign:'center',fontSize:'24px' }}>CUSTOMER</p>
										{rep.map((n) => {
											if(n.block==="CUSTOMER"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}
										{customer.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.sub_block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									<div className="all-report-cards">
										<p style={{ paddingLeft: "24px",textAlign:'center',fontSize:'24px' }}>Marketing</p>
										{rep.map((n) => {
											if(n.block==="Marketing"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}
										{marketing.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.sub_block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									<div className="all-report-cards">
										<p style={{ paddingLeft: "24px" ,textAlign:'center',fontSize:'24px'}}>
											OPERATION & TECHNOLOGY
										</p>
										{rep.map((n) => {
											if(n.block==="OPERATION & TECHNOLOGY"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}
										{operation.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.sub_block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									<div className="all-report-cards">
									<p style={{ paddingLeft: "24px" ,textAlign:'center',fontSize:'24px'}}>
									REPORTING & FEEDBACK
										</p>
										{rep.map((n) => {
											if(n.block==="REPORTING & FEEDBACK"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}
										{report.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
						
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									<div className="all-report-cards">
									<p style={{ paddingLeft: "24px" ,textAlign:'center',fontSize:'24px'}}>
									FINANCIAL CONTROL & PLANNING
										</p>
										{rep.map((n) => {
											if(n.block==="FINANCIAL CONTROL & PLANNING"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}
										{financial.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.sub_block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									<div className="all-report-cards">
									<p style={{ paddingLeft: "24px" ,textAlign:'center',fontSize:'24px'}}>
									CASH FLOW
										</p>
										{rep.map((n) => {
											if(n.block==="CASH FLOW"){
												if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.block]["100"]}</span>
														</div>
													</div>
												);
											}
											}
										})}
										{cashflow.map((n) => {
											if (n.yes >= 0 && n.yes <= 20) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progress"
															value={n.yes == 0 ? 1 : n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata?.[n.sub_block]["20"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 21 && n.yes <= 40) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progorange"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["40"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 41 && n.yes <= 60) {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progyellow"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["60"]}</span>
														</div>
													</div>
												);
											} else if (n.yes >= 61 && n.yes <= 80) {
												console.log(Reportdata[n.sub_block]["80"]);
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="proglightgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["80"]}</span>
														</div>
													</div>
												);
											} else {
												return (
													<div className="report-card reprot-progress-item">
														<div className="report-progress-item-top">
															<p>{n.sub_block}</p>
															<p>{Math.round(parseInt(n.yes))}%</p>
														</div>
														<LinearProgress
															variant="determinate"
															color="progdarkgreen"
															value={n.yes}
														/>
														<div className="report-progress-item-bottom">
															<span>{Reportdata[n.sub_block]["100"]}</span>
														</div>
													</div>
												);
											}
										})}
										{/* <div>
													<p>{aid+id}@Panorama</p>
													</div> */}
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</>
	);
}
