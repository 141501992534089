export const Reportdata = {
    "PURPOSE":{
        ">+51%":"Congratulations! The Purpose section of your new assessment report shows an outstanding increase of more than 51%. This remarkable growth signifies a significant evolution in your business's alignment with its purpose. Your commitment to refining and reinforcing your purpose has resulted in substantial positive changes. Keep up the excellent work as you continue to inspire and lead your organization towards a more purpose-driven future.",
        "+21% – +50%":"Well done! The Purpose section of your new assessment report indicates exceptional progress with a percentage increase of 21% to 50%. This substantial improvement reflects your dedication to enhancing your business's alignment with its purpose. Your efforts have made a noticeable impact, and you're on the right path to becoming more purpose-driven. Continue building on this momentum and channel your progress into even greater positive change.",
        "+11% – +20%":"Good job! The Purpose section of your new assessment report demonstrates good progress, showing an increase of 11% to 20%. This growth indicates positive strides in aligning your business with its purpose. Your commitment to purposeful actions is paying off, and you're moving in the right direction. Keep refining your strategies and initiatives to further strengthen your connection to your purpose.",
        "+3% – +10%":"Your business is moving in a positive direction. The Purpose section of your new assessment report shows a 3% to 10% increase, indicating that your efforts to align with your purpose are having a modest impact. While the progress might not be significant, it's a step in the right direction. Use this as motivation to continue refining your purpose-driven initiatives and fostering a stronger sense of purpose throughout your organization.",
        "-2% – +2%":"The Purpose section of your new assessment report reflects a relatively negligible change of 2%. While this change is minimal, it's important to acknowledge that even small shifts can influence your business's purpose alignment. Consider exploring ways to amplify your purpose-driven efforts and make more substantial changes that resonate with your values and goals.",
        "- 3% – -10%":"It's time for immediate action. The Purpose section of your new assessment report shows a decrease of 3% to 10%, suggesting that your alignment with your purpose needs revision. Your purpose-driven initiatives may have encountered challenges or setbacks. Address these issues promptly, revise your strategies, and ensure that your business is consistently connected to its purpose.",
        "-11% – -20%":"Urgent action is needed. The Purpose section of your new assessment report reveals a decrease of 11% to 20%, signaling that your connection to your purpose is significantly weakened. Your purpose-driven efforts may be faltering, and this requires immediate attention. Reevaluate your purpose strategies, engage your team, and make substantial changes to realign your business with its intended purpose.",
        "-21% – -50%":"A strategic pivot is imperative. The Purpose section of your new assessment report shows a decrease of 21% to 50%, indicating a notable decline in purpose alignment. This level of decrease suggests that your current strategies may no longer be effective. Swiftly pivot to a new approach that renews your commitment to your purpose and reinvigorates your purpose-driven initiatives.",
        "<-51%":"An extensive reassessment is warranted. The Purpose section of your new assessment report demonstrates an unexpected decrease of more than 51%. This substantial decline calls for immediate action. Initiate a thorough review of your purpose-driven strategies, engage your business team, and determine the root causes of this decline. Realign your efforts and consider revisiting your purpose to ensure a stronger and more resilient connection moving forward.",
    },
    "CUSTOMER":{
        ">+51%":"Congratulations! The Customer section of your new assessment report showcases outstanding progress with a remarkable increase of more than 51%. This exceptional growth signifies a significant improvement in understanding and serving your customers. Your dedication to enhancing customer relationships and acquisition strategies is clearly paying off. Keep up the excellent work as you continue to exceed customer expectations and drive business growth.",
        "+21% – +50%":"Well done! The Customer section of your new assessment report indicates exceptional progress, showing a percentage increase of 21% to 50%. This substantial improvement reflects your commitment to deepening customer understanding and refining acquisition methods. Your efforts have led to meaningful changes in how your business interacts with customers. Continue building on this progress to cultivate strong and lasting customer relationships.",
        "+11% – +20%":"Good job! The Customer section of your new assessment report demonstrates good progress, with an increase of 11% to 20%. This growth indicates positive strides in your approach to understanding and acquiring customers. Your efforts to better meet customer needs and enhance acquisition strategies are making a noticeable impact. Keep refining your customer-focused initiatives to strengthen your market presence.",
        "+3% – +10%":"Your business is moving in a positive direction. The Customer section of your new assessment report shows a 3% to 10% increase, suggesting that your efforts to understand and acquire customers are having a modest impact. While the progress might not be significant, it signifies that your strategies are yielding positive outcomes. Use this momentum to continue refining your approaches and delivering value to customers.",
        "-2% – +2%":"The Customer section of your new assessment report reflects a relatively negligible change of 2%. While this change is minor, it's important to recognize that even small shifts can influence customer interactions. Continue to invest in strategies that enhance customer understanding and acquisition, and explore opportunities to make more substantial improvements.",
        "- 3% – -10%":"It's time for immediate action. The Customer section of your new assessment report shows a decrease of 3% to 10%, suggesting that your understanding and acquisition of customers need revision. There may be challenges or gaps in your customer-focused strategies that require urgent attention. Address these issues, revise your approaches, and ensure that customer satisfaction remains a priority.",
        "-11% – -20%":"Urgent action is needed. The Customer section of your new assessment report reveals a decrease of 11% to 20%, indicating a significant decline in your approach to understanding and acquiring customers. This demands immediate attention. Reevaluate your customer strategies, engage your team, and implement changes to realign your efforts with customer needs.",
        "-21% – -50%":"A strategic pivot is imperative. The Customer section of your new assessment report shows a decrease of 21% to 50%, indicating a notable drop in your customer understanding and acquisition efforts. This level of decrease suggests that your current strategies may no longer be effective. Swiftly pivot to a new approach that revitalizes your customer-centric initiatives.",
        "<-51%":"An extensive reassessment is warranted. The Customer section of your new assessment report demonstrates a substantial decrease of more than 51%. This steep decline calls for immediate action. Initiate a thorough review of your customer strategies, engage your team, and determine the root causes of this decline. Realign your efforts and prioritize customer understanding and acquisition to rebuild your market presence.",
    },
    "MARKETING":{
        ">+51%":"Exceptional strides! The Marketing section of your new assessment report boasts a remarkable increase of over 51%. This outstanding growth reflects your dedication to enhancing your marketing strategies and connecting with your target audience. Your efforts are clearly resonating, driving exceptional results. Keep up the fantastic work as you continue to amplify your brand presence and engagement.",
        "+21% – +50%":"Bravo! The Marketing section of your new assessment report reveals exceptional progress, with a 21% to 50% increase. This substantial improvement underscores your commitment to refining marketing initiatives and expanding your market reach. Your investments in innovative marketing approaches are bearing fruit. Maintain this impressive momentum as you further elevate your brand's visibility and impact.",
        "+11% – +20%":"Well done! The Marketing section of your new assessment report shows good progress, marking an increase of 11% to 20%. This growth signifies positive steps in enhancing your marketing strategies and effectively engaging your target audience. Your dedication to refining your brand messaging and outreach efforts is evident. Keep evolving these strategies for sustained growth.",
        "+3% – +10%":"Encouraging news! The Marketing section of your new assessment report reflects a 3% to 10% increase, indicating your efforts to optimize marketing strategies are heading in a positive direction. Though not dramatic, this progress reflects your commitment to continuous improvement. Harness this momentum to make a more significant impact.",
        "-2% – +2%":"The Marketing section of your new assessment report reflects a minor 2% change. Even small adjustments can influence your marketing impact. Maintain your focus on enhancing brand visibility while exploring avenues for more substantial improvements.",
        "- 3% – -10%":"Immediate action required. The Marketing section of your new assessment report registers a decrease of 3% to 10%, signaling a need to revise your marketing strategies. Challenges might be affecting audience engagement and brand perception. Address these promptly, refine your strategies, and ensure a compelling market presence.",
        "-11% – -20%":"Urgent attention is needed. The Marketing section of your new assessment report indicates an 11% to 20% decrease, pointing to a significant decline in your marketing efforts. Promptly reassess your strategies, engage your team, and implement changes to reinvigorate your marketing initiatives and regain market traction.",
        "-21% – -50%":"A strategic shift is essential. The Marketing section of your new assessment report showcases a decrease of 21% to 50%, highlighting a notable drop in marketing effectiveness. Such a decline calls for a swift pivot to new approaches that re-energize your marketing campaigns and reconnect with your audience.",
        "<-51%":"Extensive review is critical. The Marketing section of your new assessment report presents a substantial decrease of over 51%. This demands immediate action. Initiate a thorough review of your marketing strategies, engage your team, and identify the root causes behind this decline. Revamp your marketing initiatives to recapture market momentum.",
    },
    "PEOPLE":{
        ">+51%":"Impressive work! The People section of your new assessment report displays remarkable growth with an increase exceeding 51%. This significant advancement demonstrates your commitment to developing a strong workforce and fostering a positive work environment. Your efforts in enhancing people-related strategies are yielding outstanding results. Keep up the excellent momentum as you continue to nurture your team's growth and well-being.",
        "+21% – +50%":"Kudos! The People section of your new assessment report highlights exceptional progress, showing an increase of 21% to 50%. This substantial improvement underscores your dedication to empowering your employees and enhancing HR practices. Your investments in people development and engagement are clearly paying off. Maintain this upward trajectory as you further strengthen your team's capabilities and job satisfaction.",
        "+11% – +20%":"Well done! The People section of your new assessment report indicates good progress, with an increase of 11% to 20%. This growth signifies positive strides in fostering a supportive work culture and refining HR processes. Your efforts to optimize talent management and engagement strategies are making a noticeable impact. Continue to fine-tune these initiatives for sustained growth.",
        "+3% – +10%":"Encouraging news! The People section of your new assessment report shows a 3% to 10% increase, suggesting your endeavors to enhance employee well-being and HR practices are heading in a positive direction. While the progress may not be dramatic, it reflects your commitment to continuous improvement. Harness this momentum to amplify the positive impact on your workforce.",
        "-2% – +2%":"The People section of your new assessment report reflects a relatively minor change of 2%. Small shifts can still influence your people-related efforts. Keep your focus on cultivating a positive work environment, even as you explore avenues for more noticeable improvements.",
        "- 3% – -10%":"Immediate attention required. The People section of your new assessment report displays a decrease of 3% to 10%, indicating a need to revise your people strategies. Challenges may be affecting employee satisfaction and HR effectiveness. Address these promptly, refine your strategies, and ensure a conducive work atmosphere.",
        "-11% – -20%":"Urgent action is needed. The People section of your new assessment report reveals a decrease of 11% to 20%, suggesting a significant decline in your efforts related to employee well-being and HR practices. Promptly reassess your strategies, engage your team, and implement changes to reinvigorate your people-centric initiatives.",
        "-21% – -50%":"A strategic shift is crucial. The People section of your new assessment report demonstrates a decrease of 21% to 50%, indicating a notable drop in your people-related endeavors. Such a decline calls for a swift pivot to new approaches that rejuvenate your HR initiatives and re-engage your workforce effectively.",
        "<-51%":"Extensive review is essential. The People section of your new assessment report presents a substantial decrease of over 51%. This demands immediate action. Initiate a thorough review of your people strategies, involve your team, and identify the root causes behind this decline. Realign your efforts to create a supportive and engaged workforce.",
    },
    "REPORTING & FEEDBACK":{
        ">+51%":"Remarkable strides! The Reporting & Feedback section of your new assessment report showcases an extraordinary increase of over 51%. This exceptional growth signifies your commitment to refining reporting processes and enhancing feedback loops. Your efforts in nurturing transparent communication are truly paying off. Keep up the outstanding work as you continue to drive actionable insights and foster a culture of continuous improvement.",
        "+21% – +50%":"Well done! The Reporting & Feedback section of your new assessment report demonstrates exceptional progress, with an increase of 21% to 50%. This substantial improvement underscores your dedication to enhancing reporting methodologies and optimizing feedback mechanisms. Your investments in transparent communication are yielding notable results. Maintain this impressive momentum as you elevate your decision-making processes and stakeholder engagement.",
        "+11% – +20%":"Kudos! The Reporting & Feedback section of your new assessment report reflects good progress, marking an increase of 11% to 20%. This growth signifies meaningful strides in enhancing reporting accuracy and feedback effectiveness. Your commitment to open communication and data-driven decisions is evident. Keep refining these initiatives for sustained progress and impact.",
        "+3% – +10%":"Encouraging development! The Reporting & Feedback section of your new assessment report shows a 3% to 10% increase, suggesting your endeavors to enhance reporting practices and feedback loops are moving in a positive direction. Though modest, this progress highlights your dedication to transparent communication. Harness this momentum to drive even more meaningful insights.",
        "-2% – +2%":"The Reporting & Feedback section of your new assessment report reflects a minor 2% change. Even small adjustments can influence the quality of communication. Maintain your focus on enhancing reporting accuracy and feedback mechanisms while exploring avenues for more significant improvements.",
        "- 3% – -10%":"Immediate action required. The Reporting & Feedback section of your new assessment report registers a decrease of 3% to 10%, signaling a need to revise your reporting and feedback strategies. Challenges might be impacting transparency and data-driven decisions. Address these promptly, refine your approaches, and ensure more effective communication.",
        "-11% – -20%":"Urgent attention is needed. The Reporting & Feedback section of your new assessment report indicates an 11% to 20% decrease, pointing to a significant decline in your reporting and feedback processes. Promptly reassess your strategies, engage your team, and implement changes to rejuvenate your communication practices and stakeholder engagement.",
        "-21% – -50%":"A strategic pivot is essential. The Reporting & Feedback section of your new assessment report showcases a decrease of 21% to 50%, highlighting a notable drop in reporting accuracy and feedback efficacy. Such a decline necessitates a swift pivot to new approaches that enhance transparency, drive actionable insights, and promote stronger engagement.",
        "<-51%":"Extensive review is critical. The Reporting & Feedback section of your new assessment report presents a substantial decrease of over 51%. This demands immediate action. Initiate a thorough review of your reporting and feedback strategies, engage your team, and identify the root causes behind this decline. Revamp your communication processes to foster more impactful insights and engagement.",
    },
    "OPERATION & TECHNOLOGY":{
        ">+51%":"Phenomenal advancements! The Operations & Technology section of your new assessment report demonstrates an extraordinary increase of over 51%. This remarkable growth underscores your commitment to streamlining operations and embracing innovative technologies. Your efforts in optimizing efficiency and embracing technological advancements are truly paying dividends. Keep up the outstanding work as you continue to revolutionize your operational landscape.",
        "+21% – +50%":"Well done! The Operations & Technology section of your new assessment report displays exceptional progress, with an increase of 21% to 50%. This substantial improvement reflects your dedication to refining operational processes and leveraging technology for efficiency gains. Your investments in operational excellence and technology integration are yielding remarkable results. Maintain this impressive momentum as you continue to elevate your competitive edge.",
        "+11% – +20%":"Great job! The Operations & Technology section of your new assessment report shows good progress, marking an increase of 11% to 20%. This growth signifies meaningful strides in optimizing operations and embracing technology to streamline processes. Your commitment to driving efficiency and technological innovation is evident. Keep refining these initiatives for sustained progress and success.",
        "+3% – +10%":"Encouraging development! The Operations & Technology section of your new assessment report registers a 3% to 10% increase, indicating your efforts to enhance operational processes and technology integration are moving in a positive direction. While the progress may be modest, it reflects your dedication to continuous improvement. Harness this momentum to drive even more impactful changes.",
        "-2% – +2%":"The Operations & Technology section of your new assessment report reflects a minor 2% change. Even subtle adjustments can impact operational efficiency. Keep your focus on optimizing processes and leveraging technology while exploring avenues for more significant improvements.",
        "- 3% – -10%":"Immediate action required. The Operations & Technology section of your new assessment report shows a decrease of 3% to 10%, suggesting a need to revise your operational and technology strategies. Challenges might be affecting efficiency and technological adoption. Address these promptly, refine your approaches, and ensure more effective operations.",
        "-11% – -20%":"Urgent attention is needed. The Operations & Technology section of your new assessment report indicates an 11% to 20% decrease, pointing to a significant decline in operational efficiency and technology integration. Promptly reassess your strategies, engage your team, and implement changes to reinvigorate your operational and technological initiatives.",
        "-21% – -50%":"A strategic shift is essential. The Operations & Technology section of your new assessment report showcases a decrease of 21% to 50%, highlighting a notable drop in operational effectiveness and technological advancements. Such a decline necessitates a swift pivot to new approaches that enhance efficiency, streamline processes, and embrace emerging technologies.",
        "<-51%":"Extensive review is critical. The Operations & Technology section of your new assessment report presents a substantial decrease of over 51%. This demands immediate action. Initiate a thorough review of your operational and technology strategies, engage your team, and identify the root causes behind this decline. Revamp your approaches to foster a more efficient and technologically advanced operational framework.",
    },
    "FINANCIAL CONTROL & PLANNING":{
        ">+51%":"Astounding advancements! The Financial Control & Planning section of your new assessment report demonstrates an exceptional increase of over 51%. This remarkable growth underscores your dedication to strengthening financial oversight and enhancing planning processes. Your efforts in optimizing financial control and strategic planning are yielding phenomenal outcomes. Keep up the remarkable work as you continue to elevate your financial stability and foresight.",
        "+21% – +50%":"Well done! The Financial Control & Planning section of your new assessment report displays exceptional progress, with an increase of 21% to 50%. This substantial improvement reflects your commitment to refining financial control measures and elevating planning effectiveness. Your investments in financial prudence and strategic foresight are yielding impressive results. Maintain this impressive momentum as you continue to navigate financial complexities.",
        "+11% – +20%":"Kudos! The Financial Control & Planning section of your new assessment report shows good progress, marking an increase of 11% to 20%. This growth signifies meaningful strides in enhancing financial control practices and elevating planning strategies. Your dedication to optimizing financial stability and strategic insight is evident. Keep refining these initiatives for sustained financial growth.",
        "+3% – +10%":"Encouraging developments! The Financial Control & Planning section of your new assessment report registers a 3% to 10% increase, suggesting your efforts to enhance financial control and planning strategies are moving in a positive direction. Though modest, this progress reflects your commitment to continuous financial improvement. Harness this momentum to make even greater financial strides.",
        "-2% – +2%":"The Financial Control & Planning section of your new assessment report reflects a minor 2% change. Even slight shifts can influence financial stability. Maintain your focus on optimizing financial controls and planning while exploring avenues for more substantial financial enhancements.",
        "- 3% – -10%":"Immediate action required. The Financial Control & Planning section of your new assessment report shows a decrease of 3% to 10%, suggesting that your financial control and planning strategies need revision. Challenges may be affecting financial stability and strategic foresight. Address these promptly, refine your financial approaches, and ensure a resilient financial foundation.",
        "-11% – -20%":"Urgent attention is needed. The Financial Control & Planning section of your new assessment report indicates an 11% to 20% decrease, pointing to a significant decline in financial control and planning effectiveness. Promptly reassess your strategies, engage your financial team, and implement changes to reinvigorate your financial prudence and strategic insight.",
        "-21% – -50%":"A strategic pivot is crucial. The Financial Control & Planning section of your new assessment report showcases a decrease of 21% to 50%, highlighting a notable drop in financial control and planning efficacy. Such a decline necessitates a swift pivot to new approaches that enhance financial oversight, fortify stability, and elevate your strategic planning.",
        "<-51%":"Extensive review is essential. The Financial Control & Planning section of your new assessment report presents a substantial decrease of over 51%. This demands immediate action. Initiate a thorough review of your financial control and planning strategies, engage your financial team, and identify the root causes behind this decline. Revamp your approaches to foster stronger financial stability and strategic acumen.",
    },
    "STRATEGY, PLANNING AND SUCCESSION":{
        ">+51%":"",
        "+21% – +50%":"",
        "+11% – +20%":"",
        "+3% – +10%":"",
        "-2% – +2%":"",
        "- 3% – -10%":"",
        "-11% – -20%":"",
        "-21% – -50%":"",
        "<-51%":"",
    },
    "CASH FLOW":{
        ">+51%":"Magnificent strides! The Cash Flow section of your new assessment report showcases an astounding increase of over 51%. This remarkable growth underscores your dedication to optimizing cash flow management and ensuring financial liquidity. Your efforts in enhancing cash flow processes are paying off exceptionally. Keep up the fantastic work as you continue to secure your financial stability and resilience.",
        "+21% – +50%":"Well done! The Cash Flow section of your new assessment report displays exceptional progress, with a 21% to 50% increase. This substantial improvement reflects your commitment to refining cash flow strategies and maintaining financial fluidity. Your investments in cash flow optimization are yielding impressive results. Maintain this remarkable momentum as you further enhance your financial health.",
        "+11% – +20%":"Kudos! The Cash Flow section of your new assessment report shows good progress, marking an increase of 11% to 20%. This growth signifies meaningful strides in enhancing cash flow management and fortifying financial stability. Your dedication to maintaining healthy cash flows and securing liquidity is evident. Keep refining these initiatives for sustained financial success.",
        "+3% – +10%":"Encouraging developments! The Cash Flow section of your new assessment report registers a 3% to 10% increase, suggesting your efforts to optimize cash flow management are moving in a positive direction. While the progress may be modest, it reflects your commitment to financial improvement. Harness this momentum to achieve even greater financial resilience.",
        "-2% – +2%":"The Cash Flow section of your new assessment report reflects a minor 2% change. Even small shifts can impact financial stability. Maintain your focus on optimizing cash flow management while exploring avenues for more substantial financial enhancements.",
        "- 3% – -10%":"Immediate action required. The Cash Flow section of your new assessment report shows a decrease of 3% to 10%, suggesting a need to revise your cash flow strategies. Challenges might be affecting financial liquidity. Address these promptly, refine your cash flow approaches, and ensure consistent financial fluidity.",
        "-11% – -20%":"Urgent attention is needed. The Cash Flow section of your new assessment report indicates an 11% to 20% decrease, pointing to a significant decline in cash flow optimization. Promptly reassess your strategies, engage your financial team, and implement changes to reinvigorate your cash flow management and secure financial stability.",
        "-21% – -50%":"A strategic pivot is crucial. The Cash Flow section of your new assessment report showcases a decrease of 21% to 50%, highlighting a notable drop in cash flow effectiveness. Such a decline necessitates a swift pivot to new approaches that bolster financial liquidity, optimize cash flows, and fortify financial health.",
        "<-51%":"Extensive review is essential. The Cash Flow section of your new assessment report presents a substantial decrease of over 51%. This demands immediate action. Initiate a thorough review of your cash flow strategies, engage your financial team, and identify the root causes behind this decline. Revamp your approaches to maintain financial fluidity and stability.",
    },
}

