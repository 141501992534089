import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CircularProgress, LinearProgress } from "@mui/material";
import { Button } from "@carbon/react";
import apiL from "../../../../api/apiList";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Layout from "../../layout";
import { peopleData } from "./peopleData";
import {Reportdata} from '../reportData'
export default function Report(props) {
  let [searchParams] = useSearchParams();
  const aid = searchParams.get("id") || null;
  const [rep, setrep] = useState([]);
  const [avg, setavg] = useState(0);
  const [clarity, setclarity] = useState(0);

  async function getreport() {
    let id = searchParams.get("code")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    var raws = JSON.stringify({
      dashboard: "BUSINESS",
      assessment_id: parseInt(aid),
    });
    var requestOptionss = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raws,

      redirect: "follow",
    };
    fetch(apiL.getDashboardBusiness + id, requestOptionss)
    .then(async (response) => {
      let dsa = await response.json();
   
      if (dsa.result) {
        let purpose = dsa.result.filter(function(el){
          return el.block=="PEOPLE"
        })
      
        setavg(purpose[0].yes);
      }
    })
    .catch((error) => console.log("error", error));
    var raw = JSON.stringify({
      dashboard: "PEOPLE",
      assessment_id: parseInt(aid),
    });
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getDashboardBusiness + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result) {
          setrep(dsa.result);
          let totals = 0;
          let clar = 0;
          dsa.result.map((e) => {
            totals = e.yes + totals;
            clar = e.no + clar;
          });
          totals = totals / dsa.result.length;
          clar = clar / dsa.result.length;
          setavg(totals);
          setclarity(clar);
        }
      })
      .catch((error) => console.log("error", error));
  }
  const business = () => {
    if (avg < 20) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              People Overview
            </Typography>
          </div>
          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progress"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
              <p>Average</p>
            </div>
          </div>
          <div className="result">
            <p>
{Reportdata["PEOPLE"]["20"]}
            </p>
          </div>
        </div>
      );
    } else if (avg < 40) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              People Overview
            </Typography>
          </div>

          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progorange"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
              <p>Average</p>
            </div>
          </div>
          <div className="result">
            <p>
            {Reportdata["PEOPLE"]["20"]}
            </p>
          </div>
        </div>
      );
    } else if (avg < 60) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              People Overview
            </Typography>
          </div>
          {}
          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progyellow"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
              <p>Average</p>
            </div>
          </div>
          <div className="result">
            <p>
            {Reportdata["PEOPLE"]["60"]}
            </p>
          </div>
        </div>
      );
    } else if (avg < 80) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              People Overview
            </Typography>
          </div>

          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="proglightgreen"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
              <p>Average</p>
            </div>
          </div>
          <div className="result">
            {/* <h3></h3> */}
            <p>
            {Reportdata["PEOPLE"]["80"]}
            </p>
          </div>
        </div>
      );
    } else if (avg <= 100) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              People Overview
            </Typography>
          </div>

          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progdarkgreen"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
              <p>Average</p>
            </div>
          </div>
          <div className="result">
            <p>
            {Reportdata["PEOPLE"]["100"]}
            </p>
          </div>
        </div>
      );
    }
  };
useEffect(() => {
    getreport();
  }, []);

  return (
    <>
      {" "}
      <Layout assesID={aid}/>
      <div className="innerPortal bg-grey-plan">
        <div className="report">
          <div className="report-left">
            <Grid container spacing={0}>
              <Grid sm={12} md={12} lg={5} xl={5}>
                {business()}
                {/* <div className="report-card reprot-left-bottom">
                  <div className="heading">
                    <Typography variant="h4B" color="neutral.b800">
                      Solutions
                    </Typography>
                  </div>
                  <ul className="solution-list">
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </Grid>
              <Grid sm={12} md={12} lg={7} xl={7}>
                {/* <div className="report-card reprot-right-top">
                  <div className="heading">
                    <Typography variant="h4B" color="neutral.b800">
                      Understand Your Purpose
                    </Typography>
                  </div>
                  <div className="report-purpose">
                    <h3>WHAT IS YOUR PEOPLE</h3>
                    <p>
                      Take a look at the following to understand how much you
                      understand regarding the following things surrounding your
                      own Business purpose. Take a look at the following to
                      understand how much you understand regarding the following
                      things surrounding your own Business purpose.{" "}
                    </p>
                    <h3>WHY IS IT IMPORTANT</h3>
                    <p>
                      Take a look at the following to understand how much you
                      understand regarding the following things surrounding your
                      own Business purpose. Take a look at the following to
                      understand how much you understand regarding the following
                      things surrounding your own Business purpose.{" "}
                    </p>
                  </div>
                  <div className="btns">
                    <div className="report-card-btn">
                      <img src="/download.svg" />
                      <p>Download Section Report</p>
                    </div>
                  </div>
                </div> */}
              
                <div className="all-report-cards">
                  {rep.map((n) => {
                    if (n.yes >= 0 && n.yes <= 20) {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.sub_block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progress"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{peopleData?.[n.sub_block]["20"]}</span>
                          </div>
                        </div>
                      );
                    } else if (n.yes >= 21 && n.yes <= 40) {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.sub_block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progorange"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{peopleData[n.sub_block]["40"]}</span>
                          </div>
                        </div>
                      );
                    } else if (n.yes >= 41 && n.yes <= 60) {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.sub_block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progyellow"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{peopleData[n.sub_block]["60"]}</span>
                          </div>
                        </div>
                      );
                    } else if (n.yes >= 61 && n.yes <= 80) {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.sub_block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="proglightgreen"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{peopleData[n.sub_block]["80"]}</span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.sub_block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progdarkgreen"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{peopleData[n.sub_block]["100"]}</span>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {/* <div className='report-card-premium'>
                                        <div className="assesmentbottomleft">
                                            <h4>Don't miss out on the full experience</h4>
                                            <p>Enjoy access to all features including analytics, reporting, and priority support for your team.</p>
                                        </div>
                                        <div className="assesmentbottomright">
                    
                                            <Button kind="primary" className="btnss" size="md">Explore all Packages</Button>
                                        </div>
                                    </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
