import Typography from "@mui/material/Typography";
import { Button } from "@carbon/react";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { InlineWidget } from "react-calendly";
import apiL from "../../api/apiList";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Portal/layout";
export default function Consultation() {
  const navigate = useNavigate();
  let pid= localStorage.getItem("pid")
  const { user, setUser } = useContext(UserContext);
  const [data, setdata] = useState({});
  const [Plan, setPlan] = useState([]);
  console.log(user);
  async function getPlan() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getPlan + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.plan) {
          setPlan(dsa.result.plan);
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getConsultant() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getConsultant + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == "This user has no consultant."
        ) {
          setdata({
            profile_code: "JD-1000-BD-0005",
            profile_id: 0,
            remaining_number: 0,
            total_number: 0,
          });
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
        setdata(dsa.result.consultants[0]);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getConsultant();
    getPlan()
  }, []);

  return (
    <>
      <Layout/>
      <div className="innerPortal bg-grey-plan">
      <div className="cardFullDashboard">
        <Grid container spacing={0}>
          <Grid sm={12} md={12} lg={6} xl={6}>
            <div className="txtCardFullDash">
              <div>
                <Typography variant="h4B" color="neutral.b800">
                  Consultation
                </Typography>
              </div>
              <div>
                <Typography variant="small" color="neutral.b800">
                  Add users on your Company Assessment and get a comprehensive
                  report of your Company.
                </Typography>
              </div>
              <Button
                kind="primary"
                size="lg"
                 onClick={() => navigate("/portal/plans")}
              >
                Upgrade Plan
              </Button>
            </div>
          </Grid>
          <Grid sm={12} md={12} lg={6} xl={6}>
            <div className="plan-txt2">
              <div>
                <Typography variant="small" color="neutral.b80">
                  Your Package
                </Typography>
                <br />
                <Typography variant="largeB" color="primary.b300">
                {pid == "null"
                    ? "No Active plan"
                    : Plan.map((e) => {
                        return e.id == user.pid ? e.name : "";
                      })}
                </Typography>
              </div>
              <div className="plancta">
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Total Consultancy
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    {data.total_number}
                  </Typography>
                </div>
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Remaining Consultancy
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    {data.remaining_number}
                  </Typography>
                </div>
                {/* <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        {data.remaining_number == 0 ? (
          <div className="consultError">
            <Typography variant="largeB" color="neutral.b400">
              Sorry, You don't have any consultancy left
            </Typography>
          </div>
        ) : (
          <InlineWidget
            url="https://calendly.com/panoramamas/30min?primary_color=0065ff"
            styles={{
              height: "900px",
              width: "100%",
            }}
          />
        )}
      </div>
    </div>
      </>

  );
}
