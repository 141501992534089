import Typography from "@mui/material/Typography";
import { Button, Link,Modal } from "@carbon/react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import Grid from "@mui/material/Grid";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import SinglePLan from "../../Components/Portal/plan/SinglePLan";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../api/apiList";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import SinglePlanDetails from "../../Components/Portal/plan/SinglePlanDetails";
import Layout from "../../Components/Portal/layout";
import { useSearchParams } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import CheckoutForm from "./checkoutForm";
export default function Plans() {
  const navigate = useNavigate();
  let pid = localStorage.getItem("pid")
  const { user, setUser } = useContext(UserContext);
  const [Plan, setPlan] = useState([]);
  const [terms, setterms] = useState(false);
  const [Invoice, setInvoice] = useState([]);
  const [modal, setmodal] = useState(false)
  const [payModal, setpayModal] = useState(false)
  let [searchParams] = useSearchParams();
  const stripePromise = loadStripe('pk_test_51N1EULLf01FMLtozGpTMmhdB5kdCvfBNhQDeQZRUaUMEaAsAWTuyPQAZhTrWQag78ciBmjXqg7oXPVw2R3NOA2ad00O0WaVFWD');
  let secret =process.env.SECRET
  const options = {
    // passing the client secret obtained from the server
    clientSecret: secret
  };
  const headers = ["Invoice Number", "Amount", "View"];
  async function sendMailCancel(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "subject":"Cancellation request from "+user.id,
    "body":`<div>Dear Admin,<br/> ${user.id} , ${user.uid} has requested cancellation for his package. Please contact him . </div>`,
    "recipient_emails":"tanvir@panoramamas.com"});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.sendMail, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if(dsa.result.status=="success"){
          setmodal(true)
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getPlan() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getPlan + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.plan) {
          setPlan(dsa.result.plan);
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getInvoice() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.invoiceList + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.invoices) {
          setInvoice(dsa.result.invoices);
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getPlan();
    getInvoice();
  }, []);
  return (
    <>
     <Elements stripe={stripePromise} options={options}>
        <Layout/>
        <div className="innerPortal bg-grey-plan">
        {/* <Modal
        open={payModal}
        className='userAddModal'
        modalHeading="Request Sent"
        primaryButtonText="OK"
        onRequestClose={() => setpayModal(false)}
        onRequestSubmit={() => setpayModal(false)}
        size="xs"
      >
      
   
 
      </Modal> */}
            <Modal
        open={modal}
        className='userAddModal'
        modalHeading="Request Sent"
        primaryButtonText="OK"
        onRequestClose={() => setmodal(false)}
        onRequestSubmit={() => setmodal(false)}
        size="xs"
      >
        <p style={{ marginBottom: "1rem" }}>
Cancellation request has been sent successfully. Someone from the Panorama team will contact with you regarding this within 2-3 working days. Please have patience.
        </p>
      </Modal>
      <div className="cardFullDashboard">
        <Grid container spacing={0}>
          <Grid sm={12} md={12} lg={6} xl={6}>
            <div className="txtCardFullDash">
              <div>
                <Typography variant="h4B" color="neutral.b800">
                  Your Plan
                </Typography>
              </div>
              <div>
                <Typography variant="small" color="neutral.b800">
                Congratulations! You're all set. Please continue to dashboard to start your assessment"
                </Typography>
              </div>
              <div>
              </div>
      {pid===null?"":              <Button
                kind="primary"
                size="lg"
                 onClick={() => navigate('/portal/index')}
              >
               Continue to Dashboard
              </Button>}
            </div>
          </Grid>
          <Grid sm={12} md={12} lg={6} xl={6}>
            <div className="plan-txt2">
              <div>
                <Typography variant="large" color="neutral.b80">
                  Your Plan
                </Typography>
                <br />
                <Typography variant="largeB" color="primary.b300">
                  {pid == "null"
                    ? "No Active plan"
                    : Plan.map((e) => {
                        return e.id == user.pid ? e.name : "";
                      })}
                </Typography>
              </div>
              {/* <div className="plancta">
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div>
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div>
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="featured-plan">
        <div className="featured-top">
          <Grid container spacing={0}>
            <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
              <div className="featured-header">
                <div>
                  <Typography variant="small" color="neutral.b800">
                    Features
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="h4B"
                    color="neutral.b800"
                    className="heading"
                  >
                    Features by Plan
                  </Typography>
                </div>
                <div>
                  <Typography variant="small" color="neutral.b800">
                    Our most advanced tools, unlimited contacts, and priority
                    support; built for teams.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
              <div className="featured-plans">
                {Plan.map((e) => {
                  return (
                    <SinglePLan
                      Planname={e.name}
                      description={e.description}
                      key={e.name}
                      planID={e.id}
                      price={e.price}
                      currency={e.currency}
                      invoice={getInvoice}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="featured-bottom">
          <Grid container spacing={0}>
            <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
              <ul className="features-list-item">
                {Plan[0]?.attributes.map((e) => {
                  return <li key={e.name}>{e.name}</li>;
                })}
              </ul>
            </Grid>
            <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
              <div className="featured-plans">
                {Plan.map((e) => {
                  return <SinglePlanDetails e={e?.attributes} />;
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="cancellation">
        <Grid container spacing={0}>
          <Grid sm={12} md={12} lg={12} xl={12}>
            <div style={{ marginBottom: "12px" }}>
              <Typography variant="h4B" color="neutral.b800">
                Invoices
              </Typography>
            </div>
            <div style={{ width: "100%" }}>
              {" "}
              <Table size="lg" useZebraStyles={false}>
                <TableHead>
                  {" "}
                  <TableRow>
                    {" "}
                    {headers.map((header) => (
                      <TableHeader id={header.key} key={header}>
                        {header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Invoice.map((row) => (
                    <TableRow key={row.invoice_link}>
                      <TableCell>{row.invoice_number}</TableCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>
                        {" "}
                        <span
                          onClick={() =>
                            window.open(
                              "https://agsmcs.info" + row.invoice_link
                            )
                          }
                          style={{ color: "#0065FF", cursor: "pointer" }}
                        >
                          View Invoice
                        </span>{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="cancellation">
        <Grid container spacing={0}>
          <Grid sm={12} md={12} lg={6} xl={6}>
            <div style={{ marginBottom: "12px" }}>
              <Typography variant="h4B" color="neutral.b800">
                Request Cancellation
              </Typography>
            </div>
            <div>
              <Typography variant="small" color="neutral.b800">
                To cancel the plan, you will be connected to our sales support
                team to process the successful cancellation of your assessment
                plan and assess if you are eligible for a refund. Please refer
                to our <a href="https://panoramamas.com/terms&conditions">Terms & Conditions</a> and <a href="https://panoramamas.com/refundPolicy">Refund Policy</a> before you request a cancellation.
              </Typography>
            </div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={terms} />}
                onClick={()=>terms==false?setterms(true):setterms(false)}
                label="I have read the Terms & Conditions and Refund Policy"
              />
            </FormGroup>
            <Button
              className="theme-button"
              style={{ background: "#0065ff", marginTop: "20px" }}
              disabled={terms==false? true:false}
              onClick={()=>sendMailCancel()}
            >
              Request Cancellation
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
    </Elements>
    </>


  );
}
