import React from "react";
import {
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
  ProgressIndicator,
  ProgressStep,
} from "@carbon/react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import OnBoardHead from "../../Components/onBoard/header";
import UserProfile from "../../Components/onBoard/userProfile";
import OnBoardMain from "../../Components/onBoard/";
export default function OnBoard() {

  async function getProfile(){
    
  }
  return (
    <div>
      <OnBoardMain />
    </div>
  );
}
