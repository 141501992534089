import * as yup from "yup";

const signUp_schema = yup.object().shape({
    email: yup.string().email().required("Please enter email"),
    password: yup.string().required("Please enter password").min(8).max(32),
    name: yup.string().required("Please enter name"),
    country_id: yup.string().required("Please enter country"),
    marketing_email: yup.boolean().required()
  });
  const signIn_schema = yup.object().shape({
    email: yup.string().email().required("Please enter email"),
    password:yup.string().required("Please enter password").min(8).max(32),
  });
  export {signUp_schema,signIn_schema}