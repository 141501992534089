import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CircularProgress, LinearProgress } from "@mui/material";
import {
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Button,
	Modal,
	Select,
	SelectItem,
	TextInput,
} from "@carbon/react";
import apiL from "../../../api/apiList";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Layout from "../layout";
import { Reportdata } from "./reportData";
export default function Report(props) {
	let [searchParams] = useSearchParams();
	const aid = searchParams.get("id") || null;
	const uid = searchParams.get("code");
	const [rep, setrep] = useState([]);
	const [avg, setavg] = useState(0);
	const [clarity, setclarity] = useState(0);

	async function getreport() {
		let id = searchParams.get("code")
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "BUSINESS",
			assessment_id: parseInt(aid),
		});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.getDashboardBusiness + id, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				if (dsa.result) {
					let newArray = dsa.result.filter(function (el) {
						return el.block != "STRATEGY, PLANNING AND SUCCESSION";
					});
					setrep(newArray);
					let totals = 0;
					let clar = 0;
					dsa.result.map((e) => {
						totals = e.yes + totals;
						clar = e.unsure + clar;
					});
					totals = totals / (dsa.result.length - 1);
					clar = 100 - clar / (dsa.result.length - 1);
					setavg(totals);
					setclarity(clar);
				}
			})
			.catch((error) => console.log("error", error));
	}
	const clarityDef = () => {
		{
			if (clarity <= 20) {
				return (
					<>
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progress"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["20"]}</span>
							</div>
						</div>
					</>
				);
			} else if (clarity <= 40) {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progorange"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["40"]}</span>
							</div>
						</div>
					</>
				);
			} else if (clarity <= 60) {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progyellow"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["60"]}</span>
							</div>
						</div>
					</>
				);
			} else if (clarity <= 80) {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="proglightgreen"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["80"]}</span>
							</div>
						</div>
					</>
				);
			} else {
				return (
					<>
						{" "}
						<div className="report-card reprot-progress-item">
							<div className="report-progress-item-top">
								<p>{"CLARITY"}</p>
								<p>{parseInt(clarity)}%</p>
							</div>
							<LinearProgress
								variant="determinate"
								color="progdarkgreen"
								value={clarity}
							/>
							<div className="report-progress-item-bottom">
								<span>{Reportdata?.["Clarity"]["100"]}</span>
							</div>
						</div>
					</>
				);
			}
		}
	};
	const business = () => {
		if (avg < 20) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>
					<div className="report-progress">
						<LinearProgress
							variant="determinate"
							color="progress"
							value={avg}
						/>
						<div className="report-progress-content">
							<h1>{parseInt(avg)}%</h1>
						</div>
					</div>
					<div className="result">
						<p>
							Your overall business management is in a critical state, requiring
							immediate attention. The assessment highlights severe deficiencies
							in various areas of your business, significantly impacting your
							management effectiveness. Focus on seeking professional guidance,
							conducting a thorough assessment of your business practices, and
							developing a comprehensive improvement plan. By implementing
							strategic changes and addressing these deficiencies, you can work
							towards revitalizing your overall business management.
						</p>
					</div>
				</div>
			);
		} else if (avg < 40) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
						<LinearProgress
							variant="determinate"
							color="progorange"
							value={avg}
						/>
						<div className="report-progress-content">
							<h1>{parseInt(avg)}%</h1>
						</div>
					</div>
					<div className="result">
						<p>
							Your overall business management needs significant improvement.
							The assessment indicates challenges in multiple areas of your
							business, including mission and purpose, customer understanding,
							financial control and planning, operations and technology,
							reporting and feedback, and people management. It is crucial to
							address these issues promptly by conducting a comprehensive review
							of your business practices, seeking expert guidance, and
							implementing strategic changes to enhance performance. By
							addressing these challenges, you can work towards improving your
							overall business management.
						</p>
					</div>
				</div>
			);
		} else if (avg < 60) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>
					{}
					<div className="report-progress">
						<LinearProgress
							variant="determinate"
							color="progyellow"
							value={avg}
						/>
						<div className="report-progress-content">
							<h1>{parseInt(avg)}%</h1>
						</div>
					</div>
					<div className="result">
						<p>
							Your overall business management demonstrates fair performance,
							but there are noticeable gaps that require attention. It appears
							that there may be inconsistencies or uncertainties in certain
							areas of your business, impacting your overall management
							effectiveness. Focus on identifying the specific areas where
							improvement is needed, developing action plans to address those
							areas, and implementing changes to enhance performance. By
							addressing these gaps, you can strengthen your overall business
							management and achieve better results.
						</p>
					</div>
				</div>
			);
		} else if (avg < 80) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
						<LinearProgress
							variant="determinate"
							color="proglightgreen"
							value={avg}
						/>
						<div className="report-progress-content">
							<h1>{parseInt(avg)}%</h1>
						</div>
					</div>
					<div className="result">
						{/* <h3></h3> */}
						<p>
							Well done! Your overall business management is generally good,
							with a few areas that could benefit from further attention. While
							you have a decent understanding and management of key business
							aspects, there may be occasional gaps or opportunities for
							improvement. Consider focusing on the specific areas where scores
							may be lower and implementing targeted strategies to enhance
							performance. By addressing these areas, you can further strengthen
							your overall business management and optimize your business's
							potential.
						</p>
					</div>
				</div>
			);
		} else if (avg <= 100) {
			return (
				<div className="report-card reprot-left-top">
					<div className="heading">
						<Typography variant="h4B" color="neutral.b800">
							Business Overview
						</Typography>
					</div>

					<div className="report-progress">
						<LinearProgress
							variant="determinate"
							color="progdarkgreen"
							value={avg}
						/>
						<div className="report-progress-content">
							<h1>{parseInt(avg)}%</h1>
						</div>
					</div>
					<div className="result">
						<p>
							Congratulations! Your overall business management is excellent.
							You demonstrate a high level of competence in various aspects of
							your business, including mission and purpose, customer
							understanding, financial control and planning, operations and
							technology, reporting and feedback, and people management. Your
							exceptional performance across these areas reflects a well-rounded
							and strategically managed business. Your commitment to continuous
							improvement and excellence positions you for sustained success in
							a competitive marketplace.
						</p>
					</div>
				</div>
			);
		}
	};
	const [rows, setrows] = useState([]);
	async function getAssesment() {
		let id = localStorage.getItem("id");
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
			redirect: "follow",
		};
		fetch(apiL.asseslist + id, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				if (dsa.result) {
					let data = dsa.result.assessments.reverse();
					data = data.filter(function (el) {
						return el.id != aid;
					});
					setrows(data);
				} else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
					navigate("/signin");
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function compareNow() {
		console.log(comp);
		if (comp == "") {
			setcompErr("Please select a version");
		} else {
			setcompErr(null);
			navigate(`/portal/compare/?id=${aid}&cid=${comp}&acode=${uid}&ccode=${ccode}`);
		}
	}
	const [comp, setcomp] = useState("");
	const [ccode, setccode] = useState("");
	const [modalAddUser, setmodalAddUser] = useState(false);
	const [compErr, setcompErr] = useState(null);
	const [modalAdd, setmodalAdd] = useState(false);
	const [modalAddReport, setmodalAddReport] = useState(false)
	const navigate = useNavigate();
	useEffect(() => {
		getreport();
		getAssesment();
	}, []);

	return (
		<>
			{" "}
			<Modal
				open={modalAddReport}
				className="userAddModal"
				modalHeading="Generating Report"
				primaryButtonText="Cancel"
				// secondaryButtonText="Cancel"
				onRequestClose={() => setmodalAddReport(false)}
				onRequestSubmit={() => setmodalAddReport(false)}
				size="sm"
			>
				<p style={{ marginBottom: "1rem" }}>
					We are generating a report for you. Please wait for the report to be made.
				</p>

			</Modal>
			<Modal
				open={modalAdd}
				className="userAddModal"
				modalHeading="Compare Report"
				primaryButtonText="Confirm"
				secondaryButtonText="Cancel"
				onRequestClose={() => setmodalAdd(false)}
				onRequestSubmit={() => compareNow()}
				size="sm"
			>
				<p style={{ marginBottom: "1rem" }}>
					Please select the report you want to compare it with.
				</p>
				<Select
					id="select-1"
					defaultValue="placeholder-item"
					labelText="Report"
					onChange={(e) => {
						let s = e.target.value.split(",")
						setcomp(s[0])
						setccode(s[1])
					}}
				>
					<SelectItem disabled hidden value="" text="Select Report" selected />
					{rows.map((e) => {
						return (
							<SelectItem value={e.id+","+e.profile_code} text={e.create_date + " (B Report)"+" "+e.name} />
						);
					})}
				</Select>
				{compErr == null ? (
					<div></div>
				) : (
					<p style={{ marginBottom: "1rem", color: "red" }}>{compErr}</p>
				)}
			</Modal>
			<Layout assesID={aid} modal={setmodalAddUser} />
			<Modal
				open={modalAddUser}
				className="userAddModal"
				modalHeading="Oops !! Your on Free Trial"
				primaryButtonText="Upgrade Plan"
				secondaryButtonText="Cancel"
				onRequestClose={() => setmodalAddUser(false)}
				onRequestSubmit={() => navigate("/portal/plans")}
				size="sm"
			>
				<p style={{ marginBottom: "1rem" }}>
					Purchase our paid plan to see and analyze the full result of your
					assesment and many others.
				</p>
			</Modal>
			<div className="innerPortal bg-grey-plan">
				<div className="report">
					<div className="report-left">
						<Grid container spacing={0}>
							<Grid sm={12} md={12} lg={5} xl={5}>
								<div>
									{business()}
									{/* <div className="report-card reprot-left-bottom">
                  <div className="heading">
                    <Typography variant="h4B" color="neutral.b800">
                      Solutions
                    </Typography>
                  </div>
                  <ul className="solution-list">
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> */}
									{/* <div className="report-card reprot-right-top">
                  <div className="heading">
                    <Typography variant="h4B" color="neutral.b800">
                      Assesment Metrics
                    </Typography>
                  </div>
                  <div className="report-purpose">
                    <h3>WHAT IS YOUR PURPOSE</h3>
                    <p>
                      Take a look at the following to understand how much you
                      understand regarding the following things surrounding your
                      own Business purpose. Take a look at the following to
                      understand how much you understand regarding the following
                      things surrounding your own Business purpose.{" "}
                    </p>
                    <h3>WHY IS IT IMPORTANT</h3>
                    <p>
                      Take a look at the following to understand how much you
                      understand regarding the following things surrounding your
                      own Business purpose. Take a look at the following to
                      understand how much you understand regarding the following
                      things surrounding your own Business purpose.{" "}
                    </p>
                  </div>
                  <div className="btns">
                    <div className="report-card-btn">
                      <img src="/download.svg" />
                      <p>Download Section Report</p>
                    </div>
                  </div>
                </div> */}
									<div className="report-card reprot-right-top">
										<div className="heading">
											<Typography variant="h4B" color="neutral.b800">
												Assesment Metrics
											</Typography>
										</div>
										<div className="report-purpose" style={{ display: "flex" }}>
											<div className="boxsa" style={{ display: "flex" }}>
												<div
													className="roundbox"
													style={{ backgroundColor: "#dc4d43" }}
												></div>
												<p>0-20%</p>
											</div>
											<div className="boxsa" style={{ display: "flex" }}>
												<div
													className="roundbox"
													style={{ backgroundColor: "#F85828" }}
												></div>
												<p>20-40%</p>
											</div>
											<div className="boxsa" style={{ display: "flex" }}>
												<div
													className="roundbox"
													style={{ backgroundColor: "#F3C318" }}
												></div>
												<p>40-60%</p>
											</div>
											<div className="boxsa" style={{ display: "flex" }}>
												<div
													className="roundbox"
													style={{ backgroundColor: "#179748" }}
												></div>
												<p>60-80%</p>
											</div>
											<div className="boxsa" style={{ display: "flex" }}>
												<div
													className="roundbox"
													style={{ backgroundColor: "#156935" }}
												></div>
												<p>80-100%</p>
											</div>
										</div>
										<div className="btns">
											{/* <div className='report-card-btn'>
                                        <img src="/idea.svg" />
                                        <p>Knowledge Center</p>
                                    </div> */}
											<div
												className="report-card-btn"
												onClick={async () => {
													setmodalAddReport(true)
													var requestOptions = {
														method: "GET",
														redirect: "follow",
													};

													fetch(
														`https://server.panoramamas.com/admin/pdf/?id=${aid}&uid=${uid}`,
														requestOptions
													)
														.then((response) => response.json())
														.then((result) => {
															window.open(result.url); 
															setmodalAddReport(false)
														
														})
														.catch((error) => console.log("error", error));
												}}
											>
												<img src="/download.svg" />
												<p>Download Section Report</p>
											</div>
											<div
												className="report-card-btn"
												onClick={() => setmodalAdd(true)}
											>
												{/* <img src="/download.svg" /> */}
												<p>Compare Report</p>
											</div>
										</div>
									</div>
								</div>
							</Grid>
							<Grid sm={12} md={12} lg={7} xl={7}>
								<div className="all-report-cards">
									{clarityDef()}
									{rep.map((n) => {
										if (n.yes >= 0 && n.yes <= 20) {
											return (
												<div className="report-card reprot-progress-item">
													<div className="report-progress-item-top">
														<p>{n.block}</p>
														<p>{Math.round(parseInt(n.yes))}%</p>
													</div>
													<LinearProgress
														variant="determinate"
														color="progress"
														value={n.yes == 0 ? 1 : n.yes}
													/>
													<div className="report-progress-item-bottom">
														<span>{Reportdata?.[n.block]["20"]}</span>
													</div>
												</div>
											);
										} else if (n.yes >= 21 && n.yes <= 40) {
											return (
												<div className="report-card reprot-progress-item">
													<div className="report-progress-item-top">
														<p>{n.block}</p>
														<p>{Math.round(parseInt(n.yes))}%</p>
													</div>
													<LinearProgress
														variant="determinate"
														color="progorange"
														value={n.yes}
													/>
													<div className="report-progress-item-bottom">
														<span>{Reportdata[n.block]["40"]}</span>
													</div>
												</div>
											);
										} else if (n.yes >= 41 && n.yes <= 60) {
											return (
												<div className="report-card reprot-progress-item">
													<div className="report-progress-item-top">
														<p>{n.block}</p>
														<p>{Math.round(parseInt(n.yes))}%</p>
													</div>
													<LinearProgress
														variant="determinate"
														color="progyellow"
														value={n.yes}
													/>
													<div className="report-progress-item-bottom">
														<span>{Reportdata[n.block]["60"]}</span>
													</div>
												</div>
											);
										} else if (n.yes >= 61 && n.yes <= 80) {
											console.log(Reportdata[n.block]["80"]);
											return (
												<div className="report-card reprot-progress-item">
													<div className="report-progress-item-top">
														<p>{n.block}</p>
														<p>{Math.round(parseInt(n.yes))}%</p>
													</div>
													<LinearProgress
														variant="determinate"
														color="proglightgreen"
														value={n.yes}
													/>
													<div className="report-progress-item-bottom">
														<span>{Reportdata[n.block]["80"]}</span>
													</div>
												</div>
											);
										} else {
											return (
												<div className="report-card reprot-progress-item">
													<div className="report-progress-item-top">
														<p>{n.block}</p>
														<p>{Math.round(parseInt(n.yes))}%</p>
													</div>
													<LinearProgress
														variant="determinate"
														color="progdarkgreen"
														value={n.yes}
													/>
													<div className="report-progress-item-bottom">
														<span>{Reportdata[n.block]["100"]}</span>
													</div>
												</div>
											);
										}
									})}
									{/* <div className='report-card-premium'>
                                        <div className="assesmentbottomleft">
                                            <h4>Don't miss out on the full experience</h4>
                                            <p>Enjoy access to all features including analytics, reporting, and priority support for your team.</p>
                                        </div>
                                        <div className="assesmentbottomright">
                    
                                            <Button kind="primary" className="btnss" size="md">Explore all Packages</Button>
                                        </div>
                                    </div> */}
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</>
	);
}
