import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, ProgressIndicator, ProgressStep } from "@carbon/react";
import Lottie from "react-lottie";
import animationData from "../../../lottieAnimate.json";
import { useNavigate } from "react-router-dom";
export default function AssesmentCard({ data, id }) {
  const [count, setcount] = useState(data - 1);
  console.log(data);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const navigate = useNavigate();
  return (
    <div>
      {data === 1 ? (
        <div className="cardFullDashboard">
          <div className="viesw">
            {" "}
            <div className="txtCardFullDashs">
              <div>
                <div>
                  <Typography variant="h4B" color="neutral.b800">
                    Please confirm your plan
                  </Typography>
                </div>
                <div>
                  {/* <Typography variant="small" color="neutral.b800">
                    Please allow us some time as we are going through your
                    response to personalize your questionnaire. Please be
                    patient as we are taking some time to generate your service.
                  </Typography> */}
                </div>
              </div>
            </div>
            <div className="progressOnb">
              <ProgressIndicator currentIndex={data-2} spaceEqually={true}>
                <ProgressStep
                  label="Pick a Plan"
                  complete={(data-2) === 1? true : false}
                />
                <ProgressStep
                  label="Take an Assessment"
                  complete={(data-2) === 2 ? true : false}
                />
                <ProgressStep
                  label="Finishing Assessment"
                  complete={(data-2) === 3 ? true : false}
                />
                <ProgressStep
                  label="View Result"
                  complete={(data-2) === 4 ? true : false}
                />
              </ProgressIndicator>
            </div>
            <Button
              kind="primary"
              size="lg"
              onClick={() => navigate("/portal/plans")}
            >
              Available Plans 
            </Button>
            {/* <div className="lty">
              <Lottie options={defaultOptions} height={200} width={400} />
            </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
      {data === 2 ? (
        <div className="cardFullDashboard">
          <div className="viesw">
            {" "}
            <div className="txtCardFullDashs">
              <div>
                <div>
                  <Typography variant="h4B" color="neutral.b800">
                    Your ready to get started
                  </Typography>
                </div>
                <div>
                  <Typography variant="small" color="neutral.b800">
                    Please allow us some time as we are going through your
                    response to personalize your questionnaire. Please be
                    patient as we are taking some time to generate your service.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="progressOnb">
            <ProgressIndicator currentIndex={data-1} spaceEqually={true}>
                <ProgressStep
                  label="Pick a Plan"
                  complete={(data-2) === 1? true : false}
                />
                <ProgressStep
                  label="Take an Assessment"
                  complete={(data-2) === 2 ? true : false}
                />
                <ProgressStep
                  label="Finishing Assessment"
                  complete={(data-2) === 3 ? true : false}
                />
                <ProgressStep
                  label="View Result"
                  complete={(data-2) === 4 ? true : false}
                />
              </ProgressIndicator>
            </div>
            <Button
              kind="primary"
              size="lg"
              onClick={() => navigate("/assesment")}
            >
              Start Assesment
            </Button>
            {/* <div className="lty">
              <Lottie options={defaultOptions} height={200} width={400} />
            </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
      {data === 3 ? (
        <div className="cardFullDashboard">
          <div className="viesw">
            {" "}
            <div className="txtCardFullDashs">
              <div>
                <div>
                  <Typography variant="h4B" color="neutral.b800">
                    Your ready to get started
                  </Typography>
                </div>
                <div>
                  <Typography variant="small" color="neutral.b800">
                    Please allow us some time as we are going through your
                    response to personalize your questionnaire. Please be
                    patient as we are taking some time to generate your service.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="progressOnb">
            <ProgressIndicator currentIndex={(data-1)} spaceEqually={true}>
                <ProgressStep
                  label="Pick a Plan"
                  complete={(data-1) === 0 ? true : false}
                />
                <ProgressStep
                  label="Take an Assessment"
                  complete={(data-1) === 1 ? true : false}
                />
                <ProgressStep
                  label="Finishing Assessment"
                  complete={(data-2) === 2 ? true : false}
                />
                <ProgressStep
                  label="View Result"
                  complete={(data-1) === 3 ? true : false}
                />
              </ProgressIndicator>
            </div>
            <Button
              kind="primary"
              size="lg"
              onClick={() => navigate("/assesment")}
            >
              Continue
            </Button>
            {/* <div className="lty">
              <Lottie options={defaultOptions} height={200} width={400} />
            </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
      {data === 4 ? (
        <div className="cardFullDashboard">
          <div className="viesw">
            {" "}
            <div className="txtCardFullDashs">
              <div>
                <div>
                  <Typography variant="h4B" color="neutral.b800">
                    Assesment Complete
                  </Typography>
                </div>
                <div>
                  <Typography variant="small" color="neutral.b800">
                    Please allow us some time as we are going through your
                    response to personalize your questionnaire. Please be
                    patient as we are taking some time to generate your service.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="progressOnb">
            <ProgressIndicator currentIndex={(data-1)} spaceEqually={true}>
                <ProgressStep
                  label="Pick a Plan"
                  complete={(data-1) === 0 ? true : false}
                />
                <ProgressStep
                  label="Take an Assessment"
                  complete={(data-1) === 1 ? true : false}
                />
                <ProgressStep
                  label="Finishing Assessment"
                  complete={(data-1) === 2 ? true : false}
                />
                <ProgressStep
                  label="View Result"
                  complete={(data-1) === 3 ? true : false}
                />
              </ProgressIndicator>
            </div>
            <Button
              kind="primary"
              size="lg"
              onClick={() => navigate("/portal/report/overview/?id=" + id)}
            >
              View Report
            </Button>
            {/* <div className="lty">
              <Lottie options={defaultOptions} height={200} width={400} />
            </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
