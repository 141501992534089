import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CircularProgress, LinearProgress } from "@mui/material";
import {
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Button,
	Modal,
	TextInput,
} from "@carbon/react";
import apiL from "../api/apiList";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Reportdata } from "./data";
export default function CompareMaintest(props) {
	let [searchParams] = useSearchParams();
	const aid = searchParams.get("id") || null;
	const cid = searchParams.get("cid") || null;
	const acode = searchParams.get("acode");
	const ccode = searchParams.get("ccode");
	const id = searchParams.get("pid") || null;
	const [rep, setrep] = useState([]);
	const [avg, setavg] = useState(0);
	const [clarity, setclarity] = useState(0);
	const [repC, setrepC] = useState([]);
	const [avgC, setavgC] = useState(0);
	const [clarityC, setclarityC] = useState(0);
	const [repf, setrepf] = useState(null);
	const [purpose, setpurpose] = useState(null);
	const [people, setpeople] = useState(null);
	const [operation, setoperation] = useState(null);
	const [customer, setcustomer] = useState(null);
	const [marketing, setmarketing] = useState(null);
	const [cashflow, setcashflow] = useState(null);
	const [reporting, setreporting] = useState(null);
	const [finance, setfinance] = useState(null);
	const [strategy, setstrategy] = useState(null);
	function mergeArrays(arr1, arr2) {
		const mergedArray = [];

		// Assuming arr1 and arr2 have the same length
		for (let i = 0; i < arr1.length; i++) {
			const blockName = arr1[i].block;
			const newObj = {
				value1: arr1[i],
				value2: arr2[i],
			};

			mergedArray.push({ [blockName]: newObj });
		}

		setrepf(mergedArray);
	}
	function mergeArraysb(arr1, arr2) {
		const mergedArray = [];

		// Assuming arr1 and arr2 have the same length
		for (let i = 0; i < arr1.length; i++) {
			const blockName = arr1[i].block;
			const newObj = {
				value1: arr1[i],
				value2: arr2[i],
			};

			mergedArray.push({ [blockName]: newObj });
		}

		return mergedArray;
	}
	async function getreport() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "BUSINESS",
			assessment_id: parseInt(aid),
		});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.getDashboardBusiness + acode, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				if (dsa.result) {
					setrep(dsa.result);
					let totals = 0;
					let clar = 0;
					dsa.result.map((e) => {
						totals = e.yes + totals;
						clar = e.unsure + clar;
					});
					totals = totals / dsa.result.length;
					clar = 100 - clar / dsa.result.length;
					setavg(totals);
					setclarity(clar);
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getreportC() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "BUSINESS",
			assessment_id: parseInt(cid),
		});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.getDashboardBusiness + ccode, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				if (dsa.result) {
					setrepC(dsa.result);
					let totals = 0;
					let clar = 0;
					dsa.result.map((e) => {
						totals = e.yes + totals;
						clar = e.unsure + clar;
					});
					totals = totals / dsa.result.length;
					clar = 100 - clar / dsa.result.length;
					setavgC(totals);
					setclarityC(clar);
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getPurpose() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "PURPOSE",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "PURPOSE",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setpurpose(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getPeople() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "PEOPLE",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "PEOPLE",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setpeople(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getoperation() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "OPERATIONAL & TECHNOLOGY EFFICIENCY",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "OPERATIONAL & TECHNOLOGY EFFICIENCY",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setoperation(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getcustomer() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "CUSTOMER & OFFERINGS",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "CUSTOMER & OFFERINGS",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setcustomer(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getmarketing() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "MARKETING & BRANDING",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "MARKETING & BRANDING",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setmarketing(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getcashflow() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "CASH FLOW",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "CASH FLOW",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setcashflow(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getreporting() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "REPORTING & FEEDBACK",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "REPORTING & FEEDBACK",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setreporting(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getfinance() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "FINANCIAL CONTROL & PLANNING",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "FINANCIAL CONTROL & PLANNING",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setfinance(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function getStrategy() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			dashboard: "STRATEGY, PLANNING AND SUCCESSION",
			assessment_id: parseInt(aid),
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		var raws = JSON.stringify({
			dashboard: "STRATEGY, PLANNING AND SUCCESSION",
			assessment_id: parseInt(cid),
		});
		var requestOptionss = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raws,

			redirect: "follow",
		};
		// let test1=[]
		// let test2=[]
		fetch(apiL.getDashboardBusiness + ccode, requestOptionss)
			.then(async (response) => {
				let dsa = await response.json();

				if (dsa.result) {
					let test1 = dsa.result;
					fetch(apiL.getDashboardBusiness +acode, requestOptions)
						.then(async (response) => {
							let dsa = await response.json();
							if (dsa.result) {
								let test2 = dsa.result;

								let dba = mergeArraysb(test1, test2);

								setstrategy(dba);
							}
						})
						.catch((error) => console.log("error", error));
				}
			})
			.catch((error) => console.log("error", error));
	}
	function barGenerate(n) {
		if (n <= 20) {
			return (
				<LinearProgress
					variant="determinate"
					color="progress"
					value={n == 0 ? 1 : n}
				/>
			);
		} else if (n <= 40) {
			return (
				<LinearProgress variant="determinate" color="progorange" value={n} />
			);
		} else if (n <= 60) {
			return (
				<LinearProgress variant="determinate" color="progyellow" value={n} />
			);
		} else if (n <= 80) {
			return (
				<LinearProgress
					variant="determinate"
					color="proglightgreen"
					value={n}
				/>
			);
		} else {
			return (
				<LinearProgress variant="determinate" color="progdarkgreen" value={n} />
			);
		}
	}
	function TextGenerate(k, n) {
		console.log(n);
		if (n > 50) {
			return <span>{Reportdata?.[k][">+51%"]}</span>;
		} else if (n > 20) {
			return <span>{Reportdata?.[k]["+21% – +50%"]}</span>;
		} else if (n > 10) {
			return <span>{Reportdata?.[k]["+11% – +20%"]}</span>;
		} else if (n > 2) {
			return <span>{Reportdata?.[k]["+3% – +10%"]}</span>;
		} else if (n >= -2 && n <= 2) {
			return <span>{Reportdata?.[k]["-2% – +2%"]}</span>;
		} else if (n >= -10 && n < -2) {
			return <span>{Reportdata?.[k]["- 3% – -10%"]}</span>;
		} else if (n >= -20 && n < -10) {
			return <span>{Reportdata?.[k]["-11% – -20%"]}</span>;
		} else if (n >= -50 && n < -20) {
			return <span>{Reportdata?.[k]["-21% – -50%"]}</span>;
		} else {
			return <span>{Reportdata?.[k]["<-51%"]}</span>;
		}
		//  else {
		//    return (
		//      <LinearProgress variant="determinate" color="progdarkgreen" value={n} />
		//    );
		//  }
	}
	// function generateBlocks(n){

	const [modalAddUser, setmodalAddUser] = useState(false);
	const [reportA, setreportA] = useState({});
	const [reportB, setreportB] = useState({});
	const navigate = useNavigate();
	async function getAssesmentList() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.asseslist + id, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				let dta = dsa.result.assessments;
				for (let index = 0; index < dta.length; index++) {
					const element = dta[index];
					console.log(element);
					if (element.id === parseInt(aid)) {
						setreportA(element);
						console.log(element);
					}
					if (element.id === parseInt(cid)) {
						setreportB(element);
					}
				}
			})
			.catch((error) => console.log("error", error));
	}
	async function login() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: "Panorama23082023",
				login: "tanvir@panoramamas.com",
				password: "Tanvir2020@",
			},
		});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.signin, requestOptions).then(async (response) => {
			let dsa = await response.json();
			if (dsa.result.uid == 183) {
				getreport();
				getreportC();

				getPurpose();
				getPeople();
				getoperation();
				getcustomer();
				getmarketing();
				getcashflow();
				getreporting();
				getfinance();
				getStrategy();
				getAssesmentList();
			}
		});
	}

	console.log(reportA);
	useEffect(() => {
		login();
	}, []);
	useEffect(() => {
		mergeArrays(rep, repC);
	}, [rep, repC]);

	return (
		<>
			<div>
				<div className="report">
					<div className="report-left">
						<Grid container spacing={0}>
							<Grid sm={12} md={12} lg={12} xl={12}>
								<div
									className="lgo"
									style={{
										paddingLeft: "20px",
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<div>
										<img
											src="/lgborder.svg"
											alt=""
											srcset=""
											style={{ width: "180px" }}
										/>
										<p style={{fontSize:'12px',paddingLeft:'10px'}}>info@panoramamas.com</p>
									</div>
								</div>
								<div className="all-report-cards">
									<div style={{ textAlign: "center" }}>
										<p style={{ fontSize: "28px", fontWeight: "500" }}>
											Assessment Comparison Report
										</p>
										<p style={{ fontSize: "24px", fontWeight: "500" }}>
											{reportA?.company}
										</p>
										
									</div>
									<div style={{display:'flex',justifyContent:'space-around'}}>
										<div style={{textAlign:'center'}}>
											<p>By {reportA.name}</p>
											<p>Date {reportA.create_date}</p>
											<p>Ref {aid  +acode}</p>
										</div>
										<div style={{textAlign:'center'}}>
										<p>By {reportB.name}</p>
											<p>Date {reportB.create_date}</p>
											<p>Ref {cid  +ccode}</p>
										</div>
									</div>
									{repf == null
										? ""
										: repf.map((n, i) => {
												let r = repf[i];
												let keys = Object.keys(r);
												let k = keys[0];

												let s = r[k];
												let m = parseInt(
													parseInt(s?.value1?.yes) - parseInt(s?.value2?.yes)
												);

												if (k != "STRATEGY, PLANNING AND SUCCESSION") {
													return (
														<div className="reprot-compare-item">
															<div className="report-progress-item-top">
																<p style={{fontWeight:'500',textDecoration:'underline',fontSize:'22px'}}>{k}</p>
															</div>
															<div className="bar">
																<div>
																	<p
																		style={{
																			fontWeight: "500",
																			fontSize: "20px",
																		}}
																	>
																		{`A Report `}
																	</p>{" "}
																	<div
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																		}}
																	>
																		<p>Overall </p>
																		<p>{parseInt(s?.value1?.yes)}%</p>
																	</div>
																	{barGenerate(parseInt(s?.value1?.yes))}
																</div>
																<div>
																	<p
																		style={{
																			fontWeight: "500",
																			fontSize: "20px",
																		}}
																	>
																		{`B Report `}
																	</p>
																	<div
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																		}}
																	>
																		<p>Overall </p>
																		<p>{parseInt(s?.value2?.yes)}%</p>
																	</div>
																	{barGenerate(parseInt(s?.value2?.yes))}
																</div>
															</div>
															{purpose != null && k == "PURPOSE"
																? purpose.map((n, i) => {
																		let r = purpose[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value2?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{people != null && k == "PEOPLE"
																? people.map((n, i) => {
																		let r = people[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{operation != null &&
															k == "OPERATION & TECHNOLOGY"
																? operation.map((n, i) => {
																		let r = operation[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{customer != null && k == "CUSTOMER"
																? customer.map((n, i) => {
																		let r = customer[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{marketing != null && k == "MARKETING"
																? marketing.map((n, i) => {
																		let r = marketing[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{cashflow != null && k == "CASH FLOW"
																? cashflow.map((n, i) => {
																		let r = cashflow[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{reporting != null && k == "REPORTING & FEEDBACK"
																? reporting.map((n, i) => {
																		let r = reporting[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{finance != null &&
															k == "FINANCIAL CONTROL & PLANNING"
																? finance.map((n, i) => {
																		let r = finance[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															{strategy != null &&
															k == "STRATEGY, PLANNING AND SUCCESSION"
																? strategy.map((n, i) => {
																		let r = strategy[i];
																		let keys = Object.keys(r);
																		let k = keys[0];

																		let f = r[k];
																		return (
																			<div className="bar">
																				<div>
																					{" "}
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value2?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value2?.yes)
																					)}
																				</div>
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent: "space-between",
																						}}
																					>
																						<p>
																							{"• " + f?.value1?.sub_block}{" "}
																						</p>
																						<p>{parseInt(f?.value1?.yes)}%</p>
																					</div>
																					{barGenerate(
																						parseInt(f?.value1?.yes)
																					)}
																				</div>
																			</div>
																		);
																  })
																: ""}
															<div className="report-progress-item-bottom">
																{TextGenerate(k, m)}
															</div>
														</div>
													);
												} else {
													return <div></div>;
												}
										  })}
									<div>
										{/* <p>{aid + cid + id}</p> */}
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</>
	);
}
