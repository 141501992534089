export const operationData = {
    "Document Core Process":{
        "20":"Your business has inadequate process documentation practices, indicating significant deficiencies in operational efficiency and scalability. The lack of documented processes can lead to confusion, errors, and inefficiencies. It is essential to establish a systematic approach to process documentation. Start by identifying critical processes and engaging cross-functional teams to document clear and detailed procedures. Allocate dedicated resources and provide training to ensure that process documentation becomes an integral part of your operational practices.",
        "40":"Your business is still in the developing stage when it comes to process documentation. You may have limited or incomplete documentation of core processes, leading to potential inefficiencies and inconsistencies. It is crucial to prioritize process documentation as a key operational initiative. Identify critical processes, engage process owners, and work collaboratively to document clear and standardized procedures. Allocate resources to support process documentation efforts and emphasize the importance of capturing knowledge and best practices.",
        "60":"Your business has adequate process documentation, but there are noticeable gaps that require attention. While you have started documenting some core processes, there may be inconsistencies or gaps in the level of detail. Enhance your process documentation by conducting process mapping exercises, involving relevant stakeholders, and ensuring clarity and standardization across all documented procedures. Regularly review and update your process documentation to maintain its accuracy and relevance.",
        "80":"Well done! Your business demonstrates strong process documentation practices, with a few areas that could benefit from further attention. You have documented key processes, but there may be room for improvement in terms of consistency and standardization. Ensure that your process documentation captures essential details, including inputs, outputs, roles, responsibilities, and any dependencies. Regularly review and update your process documentation to reflect any changes in your operations and to incorporate best practices.",
        "100":"Congratulations! Your business excels in documenting core processes. You have comprehensive and well-documented procedures for your key operational activities. Your process documentation is clear, accessible, and regularly updated to reflect any changes or improvements. This enables smooth workflow, enhances operational efficiency, and ensures consistency in the execution of critical tasks. Your commitment to process documentation sets a solid foundation for operational excellence and scalability.",
    },
    "Education & Training":{
        "20":"Your business has inadequate education and training practices, indicating significant deficiencies in developing the skills and knowledge of your employees. Lack of training opportunities can lead to skill gaps, decreased productivity, and limited adaptability to technological advancements. It is essential to prioritize education and training as a key investment. Establish a formal training program, identify skill development needs, provide access to relevant training resources, and support employees in their professional growth. Demonstrate a commitment to employee development to drive operational effectiveness.",
        "40":"Your business is still in the developing stage when it comes to education and training. You may have limited formal training programs in place, and employees may not have sufficient access to learning opportunities. It is crucial to prioritize education and training as a strategic initiative. Establish a learning and development framework, identify core competencies, and provide access to training resources, both internally and externally. Foster a culture of continuous learning and encourage employees to pursue professional development opportunities.",
        "60":"Your business has an adequate level of education and training initiatives, but there may be room for improvement. You offer some training opportunities to your employees, but it may be beneficial to expand and enhance your programs. Conduct a comprehensive assessment of your training needs, develop a training curriculum aligned with organizational goals, and provide ongoing support and resources for employee development. Regularly evaluate the impact and effectiveness of your training efforts to ensure continuous improvement.",
        "80":"Well done! Your business demonstrates strong education and training practices, with a few areas that could benefit from further attention. You have established training programs and initiatives that provide valuable learning opportunities for your employees. To further enhance education and training, consider conducting regular training needs assessments, incorporating a mix of learning modalities, and evaluating the effectiveness of your training programs. Continuously invest in the development of your employees' skills to drive operational excellence.",
        "100":"Congratulations! Your business excels in education and training initiatives. You have a comprehensive and well-structured approach to developing the skills and knowledge of your employees. Your training programs are tailored to individual and organizational needs, promoting continuous learning and professional growth. Your commitment to education and training contributes to a highly skilled workforce, improved operational efficiency, and the ability to adapt to technological advancements.",
    },
    "Technology &  Streamline":{
        "20":"Your business has limited technology adoption and opportunities for streamlining operations. The lack of technology integration may result in inefficiencies, manual processes, and missed opportunities for growth. It is crucial to prioritize technology investment and develop a technology roadmap aligned with your business goals. Identify key areas where technology can bring significant improvements, seek expert guidance if necessary, and allocate resources to implement essential systems and tools. Embrace technology as an enabler for operational efficiency and competitive advantage.",
        "40":"Your business is still in the developing stage when it comes to technology integration and streamlining. While you may have implemented some basic technologies, there is a need for further investment and strategic planning. Assess your current operational processes, identify pain points and bottlenecks, and explore technological solutions that can address these challenges. Develop a technology adoption strategy, allocate resources, and provide training and support to ensure successful implementation.",
        "60":"Your business has an adequate level of technology utilization, but there may be room for improvement. You have adopted some technologies to streamline operations, but there may be untapped potential for further optimization. Conduct a comprehensive technology assessment, identify areas where technology can bring significant improvements, and develop a roadmap for implementation. Regularly monitor and evaluate the impact of technology on operational efficiency and make necessary adjustments.",
        "80":"Well done! Your business demonstrates strong technology adoption practices, with a few areas that could benefit from further attention. You have implemented technology solutions that streamline various aspects of your operations. To further enhance technology and streamline processes, identify additional opportunities for automation, explore emerging technologies relevant to your industry, and regularly evaluate the effectiveness of your existing systems. Continuously invest in technology to stay ahead of the curve and drive operational excellence.",
        "100":"Congratulations! Your business excels in technology integration and streamlining operations. You have effectively leveraged technology to automate processes, enhance efficiency, and optimize workflow. Your business utilizes state-of-the-art systems and tools that support seamless operations and provide a competitive edge. Your commitment to technology and streamlining allows for increased productivity, improved customer experiences, and better resource allocation.",
    },
   
}

