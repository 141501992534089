import Typography from "@mui/material/Typography";
import { Button } from "@carbon/react";
import Lottie from "react-lottie";
import animationData from "../../../lottieAnimate.json";
import PlanIcon from "../../reUse/PlanIcon";
import UserCardIcon from "../../reUse/UserCardIcon";
import ConsultCardIcon from "../../reUse/ConsultCardIcon";
import PlanIconLarge from "../../reUse/PlanIconLarge";
import SafetyLargeIcon from "../../reUse/SafetyLargeIcon";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../../api/apiList";
import { UserContext } from "../../../userContext";
import { useNavigate } from "react-router-dom";
import AssesmentCard from "./AssesmentCard";
export default function PlanCard({data,planData}) {
  const navigate = useNavigate();
  const invited = localStorage.getItem("invited");
  console.log(planData)
  return (
    <div className="cardQuarterDashboard">

    <div className="planCard" style={{opacity:"1"}}>
    <div className="headerPlan">
      <PlanIcon />
      <Typography variant="small" color="neutral.b800">
        Plans
      </Typography>
    </div>
    {data === 0? (
      <div className="contentCard">
            <SafetyLargeIcon />
            <div>
                <Typography variant="small" color="neutral.b200">
                Select a plan that suits your business needs.
                </Typography>
            </div>
            </div>
    ) : (
      <div className="contentCard2">
        <p>Your plan consists of the services and offerings that you will be experiencing.</p>
        <div className="package">
          <div className="packageName">
            <span>Your Plan</span>
            <h5>{data?.name}</h5>
          </div>
          <ul className="packageInfo">
            <li>
              <img src="/tick.svg"/>
              <div className="details">
                <span>{data?.consultancy_number}</span>
                <span>Consultation</span>
              </div>
            </li>
            <li>
              <img src="/tick.svg"/>
              <div className="details">
                <span>{data?.package_duration} days</span>
                <span>Validity</span>
              </div>
            </li>
            <li>
              <img src="/tick.svg"/>
              <div className="details">
                <span>{data?.add_user_limit}</span>
                <span>Users</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )}
{invited==="true"?"":  <Button className="btnss" style={{maxWidth:"100%",width:"100%"}} kind="primary" size="lg" disabled={data===0?true:false} onClick={()=>data===0?"":navigate("/portal/plans")} >
    Upgrade
  </Button>}
</div>

</div>
  )
}
